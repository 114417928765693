import { CheckboxIcon, DownloadIcon, FileIcon } from 'assets';
import BriefHeader from 'atoms/BriefHeader';
import HorizontalLine from 'atoms/HorizontalLine';
import cx from 'classnames';
import { format } from 'date-fns';
import fileSize from 'filesize';
import useNumberParams from 'hooks/useNumberParams';
import { DesignStageFileRequest } from 'interfaces/API/draft';
import { ProductStageGroup } from 'interfaces/API/projects';
import { ActivityLogActionTypes } from 'modules/activityLog/actions';
import productSelectors from 'modules/products/selectors';
import { ProjectActionTypes } from 'modules/projects/actions';
import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { BriefHeaderWithLength } from './BriefHeaderWithLength';
import Modal from './modals/Modal';

import photoShootApi from 'API/photoShoots';
import photoRetouchApi from 'API/photoRetouch';
import Checkbox from 'atoms/Checkbox';
import { AxiosResponse } from 'axios';
import useToggler from 'hooks/useToggler';
import { acceptPhotoShoot, addPhotoShootFile, updateOpenPhotoShootFile, updatePhotoShootFile } from 'modules/photoShoot/actions';
import { ClipLoader } from 'react-spinners';
import CustomImage from './ProductBriefImage';
import ImagesFormModal from './modals/ImagesFormModal';
import { acceptPhotoRetouch, addPhotoRetouchFile, publishPhotoRetouch, rejectPhotoRetouch } from 'modules/photoRetouch/actions';

export enum ProductImagesStageTypes {
  INVALID,
  PHOTO_SHOOT,
  PHOTO_RETOUCH
}

type ProductImagesStageProps = {
  type: ProductImagesStageTypes;
  editable: boolean;
  acceptable: boolean;
};

type ContextValues = {
  type: ProductImagesStageTypes;
  editable: boolean;
  acceptable: boolean;
  className?: string;
  text?: string;
  group?: ProductStageGroup;
  addMethod?: (
    dispatch: Dispatch,
    id: number,
    data: DesignStageFileRequest
  ) => Promise<void>;
  updateOpen?: (
    dispatch: Dispatch,
    id: number,
    data: number[],
    fileId: number
  ) => void;
  updateFile?: (
    dispatch: Dispatch,
    id: number,
    fileId: number,
    data: any,
  ) => void;
  openFile?: (projectId: number, productId: number, fileId: number) => Promise<AxiosResponse | null>;
  acceptMethod?: (dispatch: Dispatch, id: number) => Promise<void>;
  //TODO TYPES
  createMethod?: (
    dispatch: Dispatch<ProjectActionTypes | ActivityLogActionTypes>,
    projectId: number,
    productId: number,
    file: DesignStageFileRequest
  ) => Promise<void>;
};

type ListItemProps = {
  file: FileItem;
  first: boolean;
};

type CompoundComponents = {
  Header: React.FC;
  List: React.FC;
  ListItem: React.FC<ListItemProps>;
};

type FileItem = {
  id: number;
  url: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  filename: string;
  size: number;
  description: string;
  version: string;
  openedBy: number[];
  downloadUrl?: string;
  requireRetouch?: boolean
};

const ProductImagesStageContext = React.createContext<ContextValues>({
  type: ProductImagesStageTypes.INVALID,
  className: '',
  text: '',
  editable: false,
  acceptable: false,
  group: ProductStageGroup.INVALID,
  addMethod: async (dispatch, id, data) => {
    return Promise.resolve();
  },
});

const useProductImagesStageContext = (): ContextValues => {
  const context = useContext(ProductImagesStageContext);
  if (!context) {
    throw new Error(`Toggle compound components cannot be rendered outside the Toggle component`);
  }
  return context;
};

export const Header: React.FC = () => {
  const { type, text } = useProductImagesStageContext();
  const isProjectLevelFile = false
  return (
    <div className="rows">
      <div className="row columns">
        <div className="column">
          <BriefHeaderWithLength briefType={text || ''} isProjectLevelFile={isProjectLevelFile} />
        </div>
      </div>
    </div>
  );
};

export const ListItem: React.FC<ListItemProps> = ({ file, first, ...props }) => {
  const [fileLoading, setFileLoading] = useState(false);
  const { projectId, productId } = useNumberParams();

  const dispatch = useDispatch();

  const {
    className,
    text,
    group,
    acceptMethod,
    addMethod,
    acceptable,
    editable,
    updateOpen,
    openFile,
    updateFile,
    type,
  } = useProductImagesStageContext();

  const isProjectLevelFile = false

  const [stage, uploadedBy, userRole, userId] = useSelector((state: AppState) => [
    productSelectors.productStage(productId)(state),
    userSelectors.getUserFullNameById(file.createdBy)(state),
    userSelectors.currentUserRole(state),
    userSelectors.userId(state),
  ]);

  const opened = file.openedBy && file.openedBy.some((id) => id === userId);

  const fileUpdate = (): void => {
    const openedBy = file.openedBy;
    if (!opened) {
      if (typeof updateOpen === 'function') {
        if (Array.isArray(openedBy)) {
          updateOpen(dispatch, isProjectLevelFile ? projectId : productId, [...openedBy, userId!], file.id);
        } else {
          updateOpen(dispatch, isProjectLevelFile ? projectId : productId, [userId!], file.id);
        }
      }
    }
  };

  const openFileByUser = async (file: FileItem): Promise<void> => {
    setFileLoading(true);
    if (typeof openFile === 'function') {
      const response = await openFile(projectId, productId, file.id);
      if (response) {
        fileUpdate();
        window.open(file.downloadUrl, '_blank');
      }
    }
    setFileLoading(false);
  };

  return (
    <>
      <div className="file-list-item">
        <div className="row columns has-items-centered">
          <div className="column has-items-centered is-2 is-dimmed">

            <CustomImage field={{ value: file }} disabled={true} alt={file.filename} toggleLightBox={() => window.open(file.url, "_blank")} />
          </div>
          <div className="rows column is-3 ml-1">
            <div className="row">
              <span>
                {file.filename} 
              </span>
            </div>
            <div className="row">
              <span>{fileSize(Number(file.size), { base: 10 })}</span>
            </div>
          </div>
          <div className="column is-1">
            <span>{file.version}</span>
          </div>
          <div className="column is-2">
            <span>{file.createdAt && format(file.createdAt, 'dd-MM-yyyy')}</span>
          </div>
          <div className="column is-1">
            <span>{file.createdAt && format(file.createdAt, 'HH:mm')}</span>
          </div>
          <div className="column is-2">
            <span>{uploadedBy}</span>
          </div>
          <div className="rows column has-items-centered is-1">
          <div className="row">Retouch</div>
            <div className="row ">
            <Checkbox disabled={!editable}
                name={`file-${file.id}-require-retouch`}
                checked={file.requireRetouch}
                onChange={() => {
                  if (typeof updateFile === 'function')
                    updateFile(dispatch, productId, file.id, { requireRetouch: !file.requireRetouch })
                }
                } />
                </div>
          </div>
        </div>

        <div className="column is-1"> {/* <Delete /> */}</div>

      </div>

      <div className="rows file-list-item__dialog">
        <div className="row wrap">
          <span className="font-small p-1">{file.description}</span>
        </div>
        <div className="row items-right">
          {file.downloadUrl &&
            <button
              type="button"
              className="button is-orange-lighten with-icon with-icon--right "
              disabled={fileLoading}
              onClick={(): void => {
                openFileByUser(file);
              }}
            >
              <span>{fileLoading ? <ClipLoader color="#fff" size={1.5} sizeUnit="rem" /> : `Download`}</span>
              <DownloadIcon />
            </button>
          }
        </div>
      </div>
    </>
  );
};

export const List: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { addMethod, acceptMethod, editable, acceptable, type } = useProductImagesStageContext();
  const [modalOpen, toggleModal] = useToggler(false);
  const [acceptModalOpen, toggleAcceptModal] = useToggler(false);
  const { projectId, productId } = useNumberParams();
  const [isSubmitting, setSubmitting] = useState(false);

  const isProjectLevelFile = false

  return (
    <>
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>PHOTOS</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine />
          </div>
        </div>
        <div className="row items-right">
          {editable &&           
            <button className="button is-orange-lighten with-icon with-icon--right mr-3 disabled"                 
            onClick={toggleAcceptModal}
            disabled={!acceptable}
            >
              <span>Retouch</span>
              <CheckboxIcon/>
            </button>
        }
          {editable && (
            <>
            <div className="button is-orange-lighten with-icon with-icon--left" onClick={toggleModal}>
              <FileIcon />
              <span>Add photos</span>
            </div>
            {addMethod && (
            <ImagesFormModal
              open={modalOpen}
              setOpen={toggleModal}
              addMethod={addMethod}
              dispatch={dispatch}
              projectId={projectId}
              productId={productId}
              headerText="Add photos"
              isProjectLevelFile={isProjectLevelFile}
            />
          )}
          </>
        )}
        </div>
      </div>
      <div className="rows">{children}</div>
      {acceptModalOpen && (
        <Modal open={acceptModalOpen} setOpen={toggleAcceptModal}>
          <Modal.Root>
            <Modal.Header>Accept photos</Modal.Header>
            <Modal.Content>
              Accept selected photos and send to retouch &nbsp;
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                disabled={isSubmitting}
                type="button"
                onClick={async (): Promise<void> => {
                  if (typeof acceptMethod === 'function') {
                    setSubmitting(true);
                    await acceptMethod(dispatch, isProjectLevelFile ? projectId : productId);
                    setSubmitting(false);
                  }
                  toggleAcceptModal();
                }}
              >
                <span>Accept</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={toggleAcceptModal}>
                <span>Cancel</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
    </>
  );
};

export const ProductImagesStage: React.FC<ProductImagesStageProps> & CompoundComponents = ({
  type,
  editable,
  acceptable,
  children,
}) => {
  const computedValues = useMemo(() => {
    if (type === ProductImagesStageTypes.PHOTO_SHOOT) {
      return {
        className: 'file-list-item__photo-shoot',
        text: 'PhotoShoot',
        group: ProductStageGroup.GROUP_9,
        acceptMethod: acceptPhotoShoot,
        addMethod: addPhotoShootFile,
        updateOpen: updateOpenPhotoShootFile,
        openFile: photoShootApi.getPhotoShootFileDetails,
        isRetouchSelection: true,
        updateFile: updatePhotoShootFile
      };
    } else  if (type === ProductImagesStageTypes.PHOTO_RETOUCH) {
      return {
        className: 'file-list-item__photo-retouch',
        text: 'PhotoRetouch',
        group: ProductStageGroup.GROUP_9,
        acceptMethod: acceptPhotoRetouch,
        publishMethod: publishPhotoRetouch,
        rejectMethod: rejectPhotoRetouch,
        addMethod: addPhotoRetouchFile,
        isRetouchSelection: false,
        openFile: photoRetouchApi.getPhotoRetouchFileDetails,
      };
    }
    return null;
  }, [type]);

  return (
    <ProductImagesStageContext.Provider value={{ type, editable, acceptable, ...computedValues }}>
      {children}
    </ProductImagesStageContext.Provider>
  );
};

ProductImagesStage.Header = Header;
ProductImagesStage.List = List;
ProductImagesStage.ListItem = ListItem;
