import API, {PACKAGING_PROJECTS_PREFIX} from './axiosConfig';
import { DesignStageResponse, DesignStageFileRequest, DesignStageFileResponse } from 'interfaces/API/draft';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { AxiosResponse } from 'axios';

const getMasterDraftByProjectId = async (projectId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.get<DesignStageResponse>(`/${PACKAGING_PROJECTS_PREFIX}/master-drafts/${projectId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const addMasterDraftFile = async (
  projectId: number,
  data: DesignStageFileRequest
): Promise<DesignStageFileResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<DesignStageFileResponse>(`/${PACKAGING_PROJECTS_PREFIX}/master-drafts/${projectId}/files/`, formData);
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptMasterDraft = async (projectId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PACKAGING_PROJECTS_PREFIX}/master-drafts/${projectId}/accept/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const openMasterDraft = async (projectId: number, productId: number, fileId: number) => {
  try {
    const response = await API.get<AxiosResponse>(`/${PACKAGING_PROJECTS_PREFIX}/master-drafts/${projectId}/files/${fileId}/open-file/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  getMasterDraftByProjectId,
  addMasterDraftFile,
  acceptMasterDraft,
  openMasterDraft,
};
