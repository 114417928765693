import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import { createSelector, Selector } from 'reselect';
import productSelectors from 'modules/products/selectors';
import { AnyProject, ProjectMember, MemberData } from 'interfaces/API/projects';
import max from 'lodash.max';
import { format } from 'date-fns';
import { BriefType } from 'API/projectData';

// TODO TYPES
const projects = (state: AppState): AnyProject[] => state.projects.projectsList;

const projectListWithProducts = createSelector(
  projects,
  (state: AppState) => state,
  productSelectors.productsByProjectId,
  (projects, state) =>
    projects.map((project) => ({ ...project, products: productSelectors.productsByProjectId(project.id)(state) }))
);

//TODO: Types
const projectById = (projectId: number): Selector<AppState, AnyProject | undefined> =>
  createSelector(projects, (list) => list.find((item) => item.id === projectId));

const shouldUpdateProjectById = (projectId: number): Selector<AppState, boolean> =>
  createSelector(productSelectors.productsByProjectId(projectId), projectById(projectId), (products, project) => {
    if (!project) {
      return true;
    }
    const maxUpdated = max(products.map((x) => x.updatedAt));
    if (maxUpdated && maxUpdated >= project.updatedAt) {
      return true;
    }
    return false;
  });

const projectCategory = (projectId: number): Selector<AppState, BriefType | null> =>
  createSelector(projects, (list) => list.find((item) => item.id === projectId)?.category.briefType || null);

const projectType = (projectId: number): Selector<AppState, string | null> =>
  createSelector(projects, (list) => list.find((item) => item.id === projectId)?.type || null);

const projectMembers = (projectId: number): Selector<AppState, ProjectMember[]> =>
  createSelector(
    projectById(projectId),
    (state: AppState) => state,
    (project, state) => {
      if (!project) {
        return [];
      }
      //TODO: INVESTIGATE RETURN
      const projectMembers = project.members.map((member: MemberData) => {
        const user = userSelectors.userById(member.user)(state);
        if (user !== undefined) {
          return { user, role: member.role } as ProjectMember;
        }
        return;
      });

      return projectMembers.filter((x) => x !== undefined) as ProjectMember[];
    }
  );

export interface File {
  id: number;
  name: string;
  type: string;
  uploadedDate: string;
  uploadedTime: string;
  uploaderName: string;
  size: string;
  description: string;
  url: string;
  deletable: boolean;
}

function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const projectFiles = (projectId: number): Selector<AppState, File[]> =>
  createSelector(
    projectById(projectId),
    (state: AppState) => state,
    (project, state) => {
      if (!project || !('files' in project)) {
        return [];
      }

      return project.files.map((f) => ({
        id: f.id,
        name: f.filename,
        type: f.filename.split('.').at(-1) || '',
        uploadedDate: format(f.updatedAt, 'dd-MM-yyyy'),
        uploadedTime: format(f.updatedAt, 'hh:mm'),
        uploaderName: userSelectors.userById(f.createdBy)(state)?.fullName || '',
        size: formatBytes(f.size),
        description: f.description,
        url: f.url,
        deletable: f.deletable,
      }));
    }
  );

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  projects,
  projectListWithProducts,
  projectById,
  shouldUpdateProjectById,
  projectCategory,
  projectType,
  projectMembers,
  projectFiles,
};
