import { DownloadIcon, FileIcon } from 'assets';
import BriefHeader from 'atoms/BriefHeader';
import HorizontalLine from 'atoms/HorizontalLine';
import cx from 'classnames';
import { format } from 'date-fns';
import fileSize from 'filesize';
import useNumberParams from 'hooks/useNumberParams';
import { DesignStageFileRequest } from 'interfaces/API/draft';
import { ProductStageGroup } from 'interfaces/API/projects';
import { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { ProjectActionTypes } from 'modules/projects/actions';
import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { BriefHeaderWithLength } from './BriefHeaderWithLength';
import Modal from './modals/Modal';

import photoRetouchApi from 'API/photoRetouch';
import { AxiosResponse } from 'axios';
import useToggler from 'hooks/useToggler';
import { acceptPhotoRetouch, addPhotoRetouchFile, deletePhotoRetouchFile, publishPhotoRetouch, rejectPhotoRetouch } from 'modules/photoRetouch/actions';
import { ClipLoader } from 'react-spinners';
import CustomImage from './ProductBriefImage';
import ImagesFormModal from './modals/ImagesFormModal';

type ContextValues = {
  editable: boolean;
  acceptable: boolean;
  publishable: boolean;
  addMethod?: (
    dispatch: Dispatch,
    id: number,
    data: DesignStageFileRequest
  ) => Promise<void>;
  updateOpen?: (
    dispatch: Dispatch,
    id: number,
    data: number[],
    fileId: number
  ) => void;
  updateFile?: (
    dispatch: Dispatch,
    id: number,
    fileId: number,
    data: any,
  ) => void;
  openFile?: (projectId: number, productId: number, fileId: number) => Promise<AxiosResponse | null>;
  acceptMethod?: (dispatch: Dispatch, id: number) => Promise<void>;
  rejectMethod?: (dispatch: Dispatch, id: number) => Promise<void>;
  publishMethod?: (dispatch: Dispatch, id: number) => Promise<void>;
  deleteMethod?: (dispatch: Dispatch, id: number, fileId: number,) => Promise<void>;
  //TODO TYPES
  createMethod?: (
    dispatch: Dispatch<ProjectActionTypes | ActivityLogActionTypes>,
    projectId: number,
    productId: number,
    file: DesignStageFileRequest
  ) => Promise<void>;
};

type ListItemProps = {
  file: FileItem;
  first: boolean;
};

type CompoundComponents = {
  Header: React.FC;
  List: React.FC;
  ListItem: React.FC<ListItemProps>;
};

type FileItem = {
  id: number;
  url: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  filename: string;
  size: number;
  description: string;
  version: string;
  openedBy: number[];
  downloadUrl?: string;
  requireRetouch?: boolean
};

const RetouchImageStageContext = React.createContext<ContextValues>({
  editable: false,
  acceptable: false,
  publishable: false,
  addMethod: async (dispatch, id, data) => {
    return Promise.resolve();
  },
});

const useRetouchImageStageContext = (): ContextValues => {
  const context = useContext(RetouchImageStageContext);
  if (!context) {
    throw new Error(`Toggle compound components cannot be rendered outside the Toggle component`);
  }
  return context;
};

export const Header: React.FC = () => {

  return (
    <div className="rows">
      <div className="row columns">
        <div className="column">
          <BriefHeaderWithLength briefType="PhotoRetouch" />
        </div>
      </div>
    </div>
  );
};

export const ListItem: React.FC<ListItemProps> = ({ file, first, ...props }) => {
  const [fileLoading, setFileLoading] = useState(false);
  const { projectId, productId } = useNumberParams();
  const { deleteMethod, editable } = useRetouchImageStageContext();

  const dispatch = useDispatch();
  const uploadedBy = useSelector((state: AppState) => userSelectors.getUserFullNameById(file.createdBy)(state))

  return (
    <>
      <div className="file-list-item">
        <div className="row columns has-items-centered">
          <div className="column has-items-centered is-2 is-dimmed">

            <CustomImage field={{ value: file }} disabled={true} alt={file.filename} toggleLightBox={() => window.open(file.url, "_blank")} />
          </div>
          <div className="rows column is-3 ml-1">
            <div className="row">
              <span>
                {file.filename}
              </span>
            </div>
            <div className="row">
              <span>{fileSize(Number(file.size), { base: 10 })}</span>
            </div>
          </div>
          <div className="column is-1">
            <span>{file.version}</span>
          </div>
          <div className="column is-2">
            <span>{file.createdAt && format(file.createdAt, 'dd-MM-yyyy')}</span>
          </div>
          <div className="column is-1">
            <span>{file.createdAt && format(file.createdAt, 'HH:mm')}</span>
          </div>
          <div className="column is-2">
            <span>{uploadedBy}</span>
          </div>
          {editable &&
            <div className="rows column has-items-centered is-1">
              <button
                type="button"
                className="button is-orange-lighten"
                onClick={(): void => {
                  if (typeof deleteMethod === 'function') {
                    deleteMethod(dispatch, productId, file.id)

                  }
                }}
              >
                <span>Remove</span>
              </button>
            </div>
          }


        </div>

        <div className="column is-1"> {/* <Delete /> */}</div>

      </div>

      <div className="rows file-list-item__dialog">
        <div className="row wrap">
          <span className="font-small p-1">{file.description}</span>
        </div>
        <div className="row items-right">
          {file.downloadUrl &&
            <button
              type="button"
              className="button is-orange-lighten with-icon with-icon--right "
              disabled={fileLoading}
              onClick={(): void => {
                window.open(file.downloadUrl, "_blank")
              }}
            >
              <span>{fileLoading ? <ClipLoader color="#fff" size={1.5} sizeUnit="rem" /> : `Download`}</span>
              <DownloadIcon />
            </button>
          }
        </div>
      </div>
    </>
  );
};

export const List: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { addMethod, acceptMethod, rejectMethod, publishMethod, editable, acceptable, publishable } = useRetouchImageStageContext();
  const [modalOpen, toggleModal] = useToggler(false);
  const [acceptModalOpen, toggleAcceptModal] = useToggler(false);
  const [rejectModalOpen, toggleRejectModal] = useToggler(false);
  const [publishModalOpen, togglePublishModal] = useToggler(false);
  const { projectId, productId } = useNumberParams();
  const [isSubmitting, setSubmitting] = useState(false);

  const isProjectLevelFile = false

  return (
    <>
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>PHOTOS</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine />
          </div>
        </div>
        <div className="row items-right">
          {acceptable &&
            <button className="button is-orange-lighten mr-3 disabled"
              onClick={toggleAcceptModal}
            >
              <span>Accept</span>
            </button>
          }
          {acceptable &&
            <button className="button is-orange-lighten mr-3 disabled"
              onClick={toggleRejectModal}
            >
              <span>Reject</span>
            </button>
          }
          {publishable &&
            <button className="button is-orange-lighten mr-3 disabled"
              onClick={togglePublishModal}
            >
              <span>Publish</span>
            </button>
          }
          {editable && (
            <>
              <div className="button is-orange-lighten with-icon with-icon--left" onClick={toggleModal}>
                <FileIcon />
                <span>Add photos</span>
              </div>
              {addMethod && (
                <ImagesFormModal
                  open={modalOpen}
                  setOpen={toggleModal}
                  addMethod={addMethod}
                  dispatch={dispatch}
                  projectId={projectId}
                  productId={productId}
                  headerText="Add photos"
                  isProjectLevelFile={isProjectLevelFile}
                />
              )}
            </>
          )}

        </div>
      </div>
      <div className="rows">{children}</div>
      {acceptModalOpen && (
        <Modal open={acceptModalOpen} setOpen={toggleAcceptModal}>
          <Modal.Root>
            <Modal.Header>Accept photos</Modal.Header>
            <Modal.Content>
              Accept retouched photos &nbsp;
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                disabled={isSubmitting}
                type="button"
                onClick={async (): Promise<void> => {
                  if (typeof acceptMethod === 'function') {
                    setSubmitting(true);
                    await acceptMethod(dispatch, isProjectLevelFile ? projectId : productId);
                    setSubmitting(false);
                  }
                  toggleAcceptModal();
                }}
              >
                <span>Accept</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={toggleAcceptModal}>
                <span>Cancel</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
      {rejectModalOpen && (
        <Modal open={rejectModalOpen} setOpen={toggleRejectModal}>
          <Modal.Root>
            <Modal.Header>Reject photos</Modal.Header>
            <Modal.Content>
              Reject photos and send back to retouch &nbsp;
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                disabled={isSubmitting}
                type="button"
                onClick={async (): Promise<void> => {
                  if (typeof rejectMethod === 'function') {
                    setSubmitting(true);
                    await rejectMethod(dispatch, productId);
                    setSubmitting(false);
                  }
                  toggleRejectModal();
                }}
              >
                <span>Reject</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={toggleRejectModal}>
                <span>Cancel</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
      {publishModalOpen && (
        <Modal open={publishModalOpen} setOpen={togglePublishModal}>
          <Modal.Root>
            <Modal.Header>Publish retouched photos</Modal.Header>
            <Modal.Content>
              Publish uploaded photos &nbsp;
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                disabled={isSubmitting}
                type="button"
                onClick={async (): Promise<void> => {
                  if (typeof publishMethod === 'function') {
                    setSubmitting(true);
                    await publishMethod(dispatch, productId);
                    setSubmitting(false);
                  }
                  togglePublishModal();
                }}
              >
                <span>Publish</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={togglePublishModal}>
                <span>Cancel</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
    </>
  );
};

export const RetouchImageStage: React.FC<any> & CompoundComponents = ({
  type,
  editable,
  acceptable,
  publishable,
  children,
}) => {
  const computedValues = useMemo(() => {
    return {
      className: 'file-list-item__photo-retouch',
      text: 'PhotoRetouch',
      group: ProductStageGroup.GROUP_9,
      acceptMethod: acceptPhotoRetouch,
      publishMethod: publishPhotoRetouch,
      rejectMethod: rejectPhotoRetouch,
      addMethod: addPhotoRetouchFile,
      deleteMethod: deletePhotoRetouchFile,
      isRetouchSelection: false,
      openFile: photoRetouchApi.getPhotoRetouchFileDetails,
    };

  }, []);

  return (
    <RetouchImageStageContext.Provider value={{ editable, acceptable, publishable, ...computedValues }}>
      {children}
    </RetouchImageStageContext.Provider>
  );
};

RetouchImageStage.Header = Header;
RetouchImageStage.List = List;
RetouchImageStage.ListItem = ListItem;
