enum actionTypes {
  SAVE_BRIEF = 'briefs/SAVE_BRIEF',
  UPDATE_BRIEF = 'briefs/UPDATE_BRIEF',
  ADD_BRIEF_BLANKING = 'briefs/ADD_BRIEF_BLANKING',
  ADD_BRIEF_PHOTO = 'briefs/ADD_BRIEF_PHOTO',
  DELETE_BRIEF_BLANKING = 'briefs/DELETE_BRIEF_BLANKING',
  DELETE_BRIEF_PHOTO = 'briefs/DELETE_BRIEF_PHOTO',
  DELETE_BRIEF_PHOTO_BRIEF_PHOTO = 'briefs/DELETE_BRIEF_PHOTO_BRIEF_PHOTO',
  CLONE_BRIEF = 'briefs/CLONE_BRIEF',
  REDIRECT_TO = 'briefs/REDIRECT_TO',
}

export default actionTypes;
