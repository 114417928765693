import photoRetouchApi from 'API/photoRetouch';
import {
  DesignStageFileRequest,
  DesignStageFileResponse,
  DesignStageResponse,
  DesignStageStore,
} from 'interfaces/API/draft';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { ProductActionTypes, getProductByIdAction, updateProductsOnSuccess } from 'modules/products/actions';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { ActionType, createAction } from 'typesafe-actions';


export type PhotoRetouchState = {
  photoRetouchList: DesignStageStore[];
};

export enum actionTypes {
  ADD_PHOTO_RETOUCH = 'photoRetouch/ADD_PHOTO_RETOUCH',
  UPDATE_PHOTO_RETOUCH = 'photoRetouch/UPDATE_PHOTO_RETOUCH',
  ADD_PHOTO_RETOUCH_FILE = 'photoRetouch/ADD_PHOTO_RETOUCH_FILE',
  UPDATE_PHOTO_RETOUCH_OPENED_BY = 'photoRetouch/UPDATE_PHOTO_RETOUCH_OPENED_BY',
  DELETE_PHOTO_RETOUCH_FILE = 'photoRetouch/DELETE_PHOTO_RETOUCH_FILE',
}

const internalActions = {
  addPhotoRetouch: createAction(actionTypes.ADD_PHOTO_RETOUCH)<{ productId: number; response: DesignStageResponse }>(),
  updatePhotoRetouch: createAction(actionTypes.UPDATE_PHOTO_RETOUCH)<{
    productId: number;
    response: DesignStageResponse;
  }>(),
  addPhotoRetouchFile: createAction(actionTypes.ADD_PHOTO_RETOUCH_FILE)<{
    productId: number;
    response: DesignStageFileResponse;
  }>(),
  deletePhotoRetouchFile: createAction(actionTypes.DELETE_PHOTO_RETOUCH_FILE)<{
    productId: number;
    fileId: number;
  }>(),
  updateOpenedFile: createAction(actionTypes.UPDATE_PHOTO_RETOUCH_OPENED_BY)<{
    productId: number;
    response: number[];
    fileId: number;
  }>()
};

export type PhotoRetouchActionTypes = ActionType<typeof internalActions>;
type PhotoRetouchFile = PhotoRetouchActionTypes | ProductActionTypes | ActivityLogActionTypes;

export const getPhotoRetouchByProductId = async (
  productId: number,
  dispatch: Dispatch<PhotoRetouchActionTypes>
): Promise<boolean> => {
  const response = await photoRetouchApi.getPhotoRetouchByProductId(productId);
  if (!response) {
    return false;
  }
  dispatch(internalActions.addPhotoRetouch({ productId, response }));
  return true;
};

export const addPhotoRetouchFile = async (
  dispatch: Dispatch<PhotoRetouchFile>,
  productId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await photoRetouchApi.addPhotoRetouchFile(productId, data);
  if (response) {
    dispatch(internalActions.addPhotoRetouchFile({ productId, response }));
    const draftResponse = await photoRetouchApi.getPhotoRetouchByProductId(productId);
    if (draftResponse) {
      dispatch(internalActions.updatePhotoRetouch({ productId, response: draftResponse }));
      await ActivityLogActions.getProductMessages(dispatch, productId);
    }
    // await updateProductsOnSuccess(projectId, dispatch);
    // await ProjectsActions.getPhotoProjectByIdAction(dispatch, productId);
    toast.success('Dodano Photoretouch image');
  } else {
    toast.error('Nie udało się dodać');
  }
};

export const deletePhotoRetouchFile = async (
  dispatch: Dispatch<PhotoRetouchFile>,
  productId: number,
  fileId: number,
): Promise<void> => {
  const response = await photoRetouchApi.deletePhotoRetouchFile(productId, fileId);
  if (response !== null) {
    dispatch(internalActions.deletePhotoRetouchFile({ productId, fileId }));
    const draftResponse = await photoRetouchApi.getPhotoRetouchByProductId(productId);
    if (draftResponse) {
      dispatch(internalActions.updatePhotoRetouch({ productId, response: draftResponse }));
      await ActivityLogActions.getProductMessages(dispatch, productId);
    }
    // await updateProductsOnSuccess(projectId, dispatch);
    // await ProjectsActions.getPhotoProjectByIdAction(dispatch, productId);
    toast.success('Usunięto');
  } else {
    toast.error('Nie udało się usunąć');
  }
};

export const acceptPhotoRetouch = async (dispatch: Dispatch<PhotoRetouchFile>, productId: number): Promise<void> => {
  const response = await photoRetouchApi.acceptPhotoRetouch(productId);
  if (response) {
    dispatch(internalActions.updatePhotoRetouch({ productId, response }));
    await updateProductsOnSuccess(productId, dispatch);
    await getProductByIdAction(dispatch, productId);
    await ActivityLogActions.getProductMessages(dispatch, productId);
    toast.success('Zaakceptowano Photoretouch');
  } else {
    toast.error('Wystąpił błąd podczas akceptowania');
  }
};

export const rejectPhotoRetouch = async (dispatch: Dispatch<PhotoRetouchFile>, productId: number): Promise<void> => {
  const response = await photoRetouchApi.rejectPhotoRetouch(productId);
  if (response) {
    dispatch(internalActions.updatePhotoRetouch({ productId, response }));
    await updateProductsOnSuccess(productId, dispatch);
    await getProductByIdAction(dispatch, productId);
    await ActivityLogActions.getProductMessages(dispatch, productId);
    toast.success('Odrzucono');
  } else {
    toast.error('Wystąpił błąd podczas odrzucania');
  }
};

export const publishPhotoRetouch = async (dispatch: Dispatch<PhotoRetouchFile>, productId: number): Promise<void> => {
  const response = await photoRetouchApi.publishPhotoRetouch(productId);
  if (response) {
    dispatch(internalActions.updatePhotoRetouch({ productId, response }));
    await updateProductsOnSuccess(productId, dispatch);
    await getProductByIdAction(dispatch, productId);
    await ActivityLogActions.getProductMessages(dispatch, productId);
    toast.success('Opublikowano');
  } else {
    toast.error('Wystąpił błąd podczas publikowania');
  }
};

