import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import { DeleteIcon, InfoIcon } from 'assets';
import Tooltip from './Tooltip';

interface ButtonCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  className?: string;
  isDeleteButtonVisible?: boolean;
  withTooltip?: boolean;
  tooltipText?: string;
  handleDeleteClick?: () => void;
}

const ButtonCheckbox: React.FC<ButtonCheckboxProps> = ({
  children,
  className,
  isDeleteButtonVisible,
  withTooltip,
  tooltipText,
  handleDeleteClick,
  ...props
}) => (
  <div className="button-checkbox">
    <input type="checkbox" id={props.name} {...props} />
    <label
      htmlFor={props.id || props.name}
      className={cx([
        'button is-light-grey',
        'button-checkbox',
        { 'button-checkbox__tooltip': withTooltip },
        { 'button-checkbox--is-active': !props.name?.includes('PhotoField') && props.checked },
        { 'button-checkbox--is-active-blue': props.name?.includes('PhotoField') && props.checked },
        { 'button-checkbox--is-disabled': props.disabled },
        className,
      ])}
    >
      <span>{children}</span>
      {withTooltip && (
        <Tooltip text={tooltipText || ''}>
          <InfoIcon />
        </Tooltip>
      )}
    </label>
    {isDeleteButtonVisible && (
      <div className="button-checkbox__delete" onClick={handleDeleteClick}>
        <DeleteIcon />
      </div>
    )}
  </div>
);

export default ButtonCheckbox;
