import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { AnyProductStore } from 'interfaces/API/products';
import { productStages, productPhotoStages } from 'modules/data/selectors';
import { ProductStage, ProjectType } from 'interfaces/API/projects';

const currentStageSelector = (state: AppState): number => state.products.currentStage;

const productsSelector = (state: AppState): AnyProductStore[] => state.products.productsList;

const productsByProjectId = (projectId: number): Selector<AppState, AnyProductStore[]> =>
  createSelector([productsSelector], (list) => list.filter((item) => item.project === projectId));

const productById = (productId: number): Selector<AppState, AnyProductStore | undefined> =>
  createSelector([productsSelector], (list) => list.find((item) => item.id === productId));

const projectProductsLength = (projectId: number): Selector<AppState, number> =>
  createSelector([productsByProjectId(projectId)], (list) => list.length);

const projectProductIndex = (projectId: number, productId: number): Selector<AppState, number> =>
  createSelector(productsByProjectId(projectId), (list) => list.findIndex((item) => item.id === productId));

const newProductId = (
  projectId: number,
  productId: number
): Selector<AppState, { nextProductId: number; previousProductId: number }> =>
  createSelector(
    productsByProjectId(projectId),
    projectProductsLength(projectId),
    projectProductIndex(projectId, productId),
    (list, length, index) => {
      const next = (index + 1) % length;
      const previous = index === 0 ? length - 1 : index - 1;
      return {
        nextProductId: list[next].id,
        previousProductId: list[previous].id,
      };
    }
  );

const productStage = (
  productId: number
): Selector<AppState, { label: string; group: number; name: ProductStage } | undefined> =>
  createSelector(productById(productId), productStages, productPhotoStages, (product, stages, productPhotoStages) => {
    const findResult = [...stages, ...productPhotoStages].find((stage) => stage.name === product?.stage);
    if (findResult) {
      const { label, group, name } = findResult;
      return { label, group, name };
    }
    return undefined;
  });

const productType = (productId: number): Selector<AppState, ProjectType | undefined> =>
  createSelector(productById(productId), (product) => product?.type);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  currentStageSelector,
  productsSelector,
  productsByProjectId,
  productById,
  projectProductsLength,
  projectProductIndex,
  newProductId,
  productStage,
  productType,
};
