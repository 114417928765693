import * as yup from 'yup';

const ProjectBriefSchema = yup.object().shape({
  name: yup.string().required('Wpisz nazwę projektu'),
  description: yup.string().required('Wpisz opis projektu'),
  productsAmount: yup
    .number()
    .typeError('Wybierz ilość produktów')
    .integer('Wybierz ilość produktów')
    .positive('Wybierz ilość produktów')
    .required('Wybierz ilość produktów'),
  category: yup.number().typeError('Wybierz kategorię').required('Wybierz kategorię'),
  brand: yup.number().typeError('Wybierz markę').required('Wybierz markę'),
  deadlineDate: yup
    .string()
    .matches(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
    .required(),
  promoDate: yup
    .string()
    .matches(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
    .required(),
});

export default ProjectBriefSchema;
