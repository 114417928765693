import { packagingTypeSelector } from 'modules/data/selectors';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrayHelpers, ErrorMessage, Field, FieldArray, FormikProps } from 'formik';
import FieldTextInput from 'molecules/fields/FieldTextInput';
import FieldInputError from 'atoms/FieldInputError';
import DropdownField from 'molecules/fields/DropdownField';
import FieldCheckbox from 'molecules/fields/FieldCheckbox';
import { TextilesBriefFormValues, TextilesSizeModel } from '../ProductBrief.interfaces';
import AddCircleButton from 'molecules/AddCircleButton';
import RemoveButton from 'molecules/RemoveButton';
import { t } from 'i18n';

interface ProductBriefMainDetailsProps {
  isEditable: boolean;
  isOther: boolean;
  isTextiles: boolean;
}

export default function ProductBriefMainDetails({
  isEditable,
  isOther,
  isTextiles,
}: ProductBriefMainDetailsProps): React.ReactElement {
  const packagingTypes = useSelector(packagingTypeSelector);
  const [newSizeName, setNewSizeName] = useState<string>('');
  const [newSizeNameError, setNewSizeNameError] = React.useState<boolean>(false);

  const handleNewFieldError = (
    itemsList: TextilesSizeModel[],
    newItemValue: string,
    setError: Dispatch<SetStateAction<boolean>>
  ) => {
    if (itemsList.find((item) => item.name.toLocaleLowerCase() === newItemValue.toLocaleLowerCase()) || !newItemValue) {
      setError(true);
      return;
    }
    setError(false);
  };

  const addNewSize = (push: (obj: TextilesSizeModel) => void) => {
    if (newSizeNameError) return;
    push({ name: newSizeName.toUpperCase(), ean: '' });
    setNewSizeName('');
  };

  return (
    <div className="field-section">
      <div className="rows">
        <div className="row rows">
          <div className="row">
            <Field
              name="name"
              component={FieldTextInput}
              type="text"
              placeholder={t('productBrief.mainDetails.name.placeholder')}
              className="brief__input"
              disabled={!isEditable}
            />
          </div>
          <div className="row">
            <ErrorMessage name="name" component={FieldInputError} />
          </div>
        </div>

        <div className="row columns stacked gap-1">
          <div className="row rows">
            <div className="column is-narrow">
              <Field
                name="modelNo"
                component={FieldTextInput}
                type="text"
                placeholder={t('productBrief.mainDetails.modelNo.placeholder')}
                className="brief__input"
                disabled={!isEditable}
              />
              <ErrorMessage name="modelNo" component={FieldInputError} />
            </div>
          </div>
          <div className="column is-narrow mt-stacked-1 mb-stacked-1 ml-stacked-0 mr-stacked-0">
            <Field
              name="batchNo"
              component={FieldTextInput}
              type="text"
              placeholder={t('productBrief.mainDetails.batchNo.placeholder')}
              className="brief__input"
              disabled={!isEditable}
            />
            <ErrorMessage name="batchNo" component={FieldInputError} />
          </div>

          {isOther && (
            <div className="column is-narrow">
              <Field
                name="ean"
                component={FieldTextInput}
                type="text"
                placeholder={t('productBrief.mainDetails.ean.placeholder')}
                className="brief__input"
                disabled={!isEditable}
              />
              <ErrorMessage name="ean" component={FieldInputError} />
            </div>
          )}

          {isTextiles && (
            <div className="column is-4">
              <Field
                placeholder={t('productBrief.mainDetails.packagingType.placeholder')}
                fullwidth={true}
                items={packagingTypes}
                name="packagingType"
                component={DropdownField}
                disabled={!isEditable}
              />
              <ErrorMessage name="packagingType" component={FieldInputError} />
            </div>
          )}
        </div>

        {isTextiles && (
          <div className="rows">
            <div className="row">
              <span className="bolder mb-1">{t('productBrief.mainDetails.sizes.title')}:</span>
            </div>
            <FieldArray name="sizes">
              {({
                replace,
                push,
                remove,
                form: {
                  values: { sizes },
                },
              }: ArrayHelpers & { form: FormikProps<TextilesBriefFormValues> }) => {
                const sizesFields = sizes.map((size: TextilesSizeModel, currentIndex: number) => {
                  return (
                    <div className="row" key={size.name}>
                      <div className="column is-2 mb-1">
                        <Field
                          name={`sizes[${currentIndex}].name`}
                          component={FieldTextInput}
                          type="text"
                          placeholder={t('productBrief.mainDetails.sizes.fields.name.placeholder')}
                          className="brief__input text-center remove-right-radius"
                          value={size.name.toUpperCase()}
                          disabled={!isEditable}
                          onChange={(e: any) => {
                            replace(currentIndex, { name: e?.target?.value.toUpperCase() || '', ean: size.ean });
                          }}
                        />
                        <ErrorMessage name={`sizes[${currentIndex}].name`} component={FieldInputError} />
                      </div>
                      <div className="column is-1">
                        <RemoveButton handleClick={() => remove(currentIndex)} isEditable={isEditable} />
                      </div>
                      <div className="column is-4">
                        <Field
                          name={`sizes[${currentIndex}].ean`}
                          component={FieldTextInput}
                          type="text"
                          placeholder={t('productBrief.mainDetails.sizes.fields.ean.placeholder')}
                          className="brief__input"
                          value={size.ean}
                          disabled={!isEditable}
                          onChange={(e: any) => {
                            replace(currentIndex, { name: size.name, ean: e?.target?.value || '' });
                          }}
                        />
                        <ErrorMessage name={`sizes[${currentIndex}].ean`} component={FieldInputError} />
                      </div>
                    </div>
                  );
                });

                return (
                  <>
                    {sizesFields}
                    {isEditable && (
                      <AddCircleButton
                        value={newSizeName}
                        placeholder={t('productBrief.mainDetails.sizes.addLabel')}
                        isEditable={isEditable}
                        handleDataOnClick={() => addNewSize(push)}
                        handleChange={setNewSizeName}
                        isError={newSizeNameError}
                        setIsError={() => handleNewFieldError(sizes, newSizeName, setNewSizeNameError)}
                        className={'default-height'}
                      />
                    )}
                  </>
                );
              }}
            </FieldArray>
          </div>
        )}

        <div className="row columns has-items-centered">
          {isOther && (
            <div className="column is-4">
              <Field
                placeholder={t('productBrief.mainDetails.packagingType.placeholder')}
                fullwidth={true}
                items={packagingTypes}
                name="packagingType"
                component={DropdownField}
                disabled={!isEditable}
              />
              <ErrorMessage name="packagingType" component={FieldInputError} />
            </div>
          )}

          {isTextiles && <span className="bolder">{t('productBrief.mainDetails.productPhotography.title')}:</span>}
          <div className={`column ml-2`}>
            <Field
              type="checkbox"
              component={FieldCheckbox}
              name="requirePhotoBrief"
              label={t('productBrief.mainDetails.productPhotography.photography')}
              disabled={!isEditable}
            />
            <Field
              type="checkbox"
              component={FieldCheckbox}
              name="requireIllustration"
              label={t('productBrief.mainDetails.productPhotography.illustration')}
              disabled={!isEditable}
            />

            {isOther && (
              <Field
                type="checkbox"
                component={FieldCheckbox}
                name="requireManual"
                label={t('productBrief.mainDetails.productPhotography.manual')}
                disabled={!isEditable}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
