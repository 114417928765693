import React, { useContext } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import './App.scss';
import { Switch, Router, Redirect } from 'react-router-dom';
import { Login, Main } from 'containers';
import { PrivateRoute, NotAuthorizedRoute } from 'helpers/PrivateRoute';
import { createBrowserHistory } from 'history';
import 'react-image-lightbox/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createContext, useState } from 'react';

export const ThemeContext = createContext<any>(null)

export const ThemeContextProvider: React.FC = (props) => {
  const [theme, setTheme] = useState<"light" | "dark">('light')

  const toggleTheme = () => theme === "light" ? setTheme("dark") : setTheme("light")

  return (
    <ThemeContext.Provider value={[theme, setTheme, toggleTheme]}>
      <div id={theme}>
        {props.children}
      </div>
    </ThemeContext.Provider>
  );
}

export const ThemeToggler: React.FC = () => {
  const [theme, setTheme, toggleTheme] = useContext(ThemeContext)

  return (
    <button className='button is-orange-lighten m-1' onClick={toggleTheme}>
      {theme}
    </button>
  )
}

export const history = createBrowserHistory();

const App: React.FC = () => {
  const [theme, setTheme] = useState('light')

  const toggleTheme = () => {
    if (window.location.href.indexOf('login') !== -1) {
      setTheme('light')
    } else {
      setTheme((curr) => curr === 'light' ? 'dark' : 'light')
    }
  }

  return (
    //@ts-ignore
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      <div id={theme}>
        <Router history={history}>
          <Provider store={store}>
            <Switch>
              <NotAuthorizedRoute exact path="/login">
                <ToastContainer position={toast.POSITION.BOTTOM_LEFT} autoClose={5000} />
                <Login />
              </NotAuthorizedRoute>
              <PrivateRoute path={['/projects/:id/products/:pid', '/projects/new', '/projects/:id', '/project-photo/:id/products/:pid', '/project-photo/new', '/project-photo/:id', '/']}>
                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={5000} />
                <Main />
              </PrivateRoute>
              <Redirect to="/" />
            </Switch>
          </Provider>
        </Router>
      </div>
    </ThemeContext.Provider>
  );
};

export default App;
