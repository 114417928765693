import React from 'react';
import './RemoveButton.scss';

interface RemoveButtonProps {
  handleClick: () => void;
  isEditable: boolean;
}

export default function RemoveButton({ handleClick, isEditable }: RemoveButtonProps) {
  return (
    <button
      type="button"
      className={'button is-orange-lighten button-square'}
      onClick={handleClick}
      disabled={!isEditable}
    >
      <span>X</span>
    </button>
  );
}
