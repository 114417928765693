import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { BriefStore } from 'interfaces/API/products';

const briefsSelector = (state: AppState): BriefStore[] => state.brief.briefs;

const briefById = (briefId: number): Selector<AppState, BriefStore | undefined> =>
  createSelector(briefsSelector, (list) => list.find((item) => item.id === briefId));

const briefRedirectTo = (state: AppState): string | null => state.brief.redirectTo;

export default {
  briefById,
  briefsSelector,
  briefRedirectTo,
};
