import { AxiosError } from 'axios';
import API, { TOKEN } from './axiosConfig';
import { history } from '../App';
import { toast } from 'react-toastify';
import {
  AuthorizationRequest,
  UserDataResponse,
  AuthorizationResponse,
  UserListItem,
} from 'interfaces/API/userAuthorization';

const userAuthorization = async ({ username, password }: AuthorizationRequest): Promise<boolean> => {
  try {
    const response = await API.post<AuthorizationResponse>('/token/', { username, password });
    const token = response.data.token;
    localStorage.setItem(TOKEN, token);
    return true;
  } catch (err) {
    const error = err as AxiosError; // TODO: Use isAxiosError after upgrading axios
    if (error.response) {
      toast.error(error.response.data.nonFieldErrors[0]);
    } else {
      toast.error('Wystąpił problem. Sprobuj ponownie.');
    }
    return false;
  }
};

const fetchCurrentUser = async (): Promise<UserDataResponse | null> => {
  try {
    const response = await API.get<UserDataResponse>('/users/me/');
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const logout = async (): Promise<boolean> => {
  try {
    localStorage.removeItem(TOKEN);
    history.push('/login');
    return await Promise.resolve(true);
  } catch (err) {
    console.error(err);
    return await Promise.resolve(false);
  }
};

const fetchAllUsers = async (): Promise<UserListItem[] | []> => {
  try {
    const response = await API.get<UserListItem[]>('/users/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  userAuthorization,
  fetchCurrentUser,
  logout,
  fetchAllUsers,
};
