import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import projectSelectors from 'modules/projects/selectors';
import { AppState } from 'modules/rootReducer';
import productSelectors from 'modules/products/selectors';
import { descriptionByStageId, groupByStageId } from 'modules/data/selectors';
import { ProductStage, ProjectType } from 'interfaces/API/projects';

const StepBar: React.FC = (props) => {
  const { id: projectId, pid: productId } = useParams<{ id: string; pid: string }>();
  const numberArray = Array.from(Array(6), (x, i) => i);

  const [description, group, projectType] = useSelector((state: AppState) => [
    productId
      ? descriptionByStageId(productSelectors.productById(Number(productId))(state)?.stage || '1')(state)
      : descriptionByStageId(projectSelectors.projectById(Number(projectId))(state)?.maxProductStage || '1')(state),
    productId
      ? groupByStageId(productSelectors.productById(Number(productId))(state)?.stage || ProductStage.STAGE_1)(state)
      : groupByStageId(projectSelectors.projectById(Number(projectId))(state)?.maxProductStage || ProductStage.STAGE_1)(
          state
        ),
        projectSelectors.projectType(Number(projectId))(state)
  ]);

  return (
    <ul className="steps is-small step-progress">
      {projectId !== 'new' ? (
        <>
          {numberArray.map((element, index) => (
            <li
              key={index}
              className={classNames(
                'steps-segment',
                `is-stage-${index + 1}`,
                group > index + 1 ? 'is-active' : 'is-disabled'
              )}
            >
              <span
                className={classNames(
                  'steps-marker',
                  `is-stage-${index + 1}`,
                  'is-tooltip-active',
                  `is-tooltip-stage-${index + 1}`,
                  { 'is-white': index === 0 },
                  { tooltip: group === index + 1 }
                )}
                data-tooltip={description}
              >
                {projectType === ProjectType.Packaging && group >= index + 1 ? index + 1 : ''}
                {projectType === ProjectType.Photo && group >= index + 1 ? String.fromCharCode(65+index) : ''}
              </span>
            </li>
          ))}
        </>
      ) : null}
    </ul>
  );
};

export default StepBar;
