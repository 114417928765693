import React, { useCallback } from 'react';
import cx from 'classnames';
import { ProductPhotoStage } from 'interfaces/API/projects';
import { useDispatch } from 'react-redux';
import { redirectTo } from 'modules/brief/actions';
import { t } from 'i18n';
export interface ProductBriefNavigationButtonsProps {
  editable: boolean;
  previousProductId: number | null;
  nextProductId: number | null;
  projectId: number | undefined;
  acceptable: boolean;
  publishable: boolean;
  currentUserRole: string | null;
  rejectProductBrief: () => void;
  publishProductBrief: () => void;
  acceptProductBrief: () => void;
  stage: string | null;
  isSubmitting: boolean;
  isPublishModalOpen: boolean;
  productsLength: number;
  currentProductIndex: number;
  onSaveAction: () => Promise<void>;
}

const PhotoProductBriefNavigationButtons: React.FC<ProductBriefNavigationButtonsProps> = ({
  previousProductId,
  projectId,
  nextProductId,
  acceptable,
  editable,
  publishable,
  acceptProductBrief,
  rejectProductBrief,
  publishProductBrief,
  stage,
  isSubmitting,
  isPublishModalOpen,
  productsLength,
  currentProductIndex,
  onSaveAction,
}) => {
  const renderButton = useCallback(() => {
    if (editable) {
      if (publishable) {
        return (
          <>
            <button type="button" className="button is-orange-lighten mr-1" onClick={publishProductBrief}>
              <span>{t('productBrief.header.navigation.publish')}</span>
            </button>
            <button
              type="button"
              onClick={onSaveAction}
              disabled={isSubmitting && !isPublishModalOpen}
              className={cx('button is-orange-lighten', 'mr-1', { 'is-loading': isSubmitting && !isPublishModalOpen })}
            >
              <span>{t('productBrief.header.navigation.save')}</span>
            </button>
          </>
        );
      } else {
        return (
          <button
            type="button"
            onClick={onSaveAction}
            disabled={isSubmitting}
            className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
          >
            <span>{t('productBrief.header.navigation.save')}</span>
          </button>
        );
      }
    } else if (acceptable) {
      return (
        <>
          <button type="button" className="button is-orange-lighten mr-1" onClick={rejectProductBrief}>
            <span>{t('productBrief.header.navigation.reject')}</span>
          </button>
          <button type="button" className="button is-orange-lighten mr-1" onClick={acceptProductBrief}>
            <span>{t('productBrief.header.navigation.accept')}</span>
          </button>
        </>
      );
    }
    return null;
  }, [
    editable,
    acceptable,
    publishable,
    publishProductBrief,
    onSaveAction,
    isSubmitting,
    isPublishModalOpen,
    rejectProductBrief,
    acceptProductBrief,
  ]);
  const dispatch = useDispatch();

  return (
    <>
      {currentProductIndex !== 0 && (
        <button
          className="button is-orange-lighten mr-1 ml-1"
          onClick={() => dispatch(redirectTo(`/project-photo/${projectId}/products/${previousProductId}`))}
        >
          &lt; &nbsp; <span>{t('productBrief.header.navigation.previous')}</span>
        </button>
      )}
      {stage === ProductPhotoStage.STAGE_1 || stage === ProductPhotoStage.STAGE_1A || stage === ProductPhotoStage.STAGE_1B
        ? renderButton()
        : null}
      {currentProductIndex + 1 !== productsLength && (
        <button
          className="button is-orange-lighten mr-1"
          onClick={() => dispatch(redirectTo(`/project-photo/${projectId}/products/${nextProductId}`))}
        >
          <span>{t('productBrief.header.navigation.next')}</span> &nbsp; &gt;
        </button>
      )}
    </>
  );
};

export default PhotoProductBriefNavigationButtons;
