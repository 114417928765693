import { actionTypes, CallSheetActionTypes, CallSheetState } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DesignStageStore } from 'interfaces/API/draft';

const initialState: CallSheetState = {
  callSheetList: [],
};

const callSheetReducer = createReducer<CallSheetState, CallSheetActionTypes>(initialState)
  .handleType(actionTypes.ADD_CALL_SHEET, (state, action) =>
    produce(state, (callSheetState) => {
      const draft: DesignStageStore = { ...action.payload.response, id: action.payload.projectId };
      callSheetState.callSheetList.push(draft);
    })
  )
  .handleType(actionTypes.ADD_CALL_SHEET_FILE, (state, { payload: { projectId, response } }) =>
    produce(state, (callSheetState) => {
      const index = callSheetState.callSheetList.findIndex((draft) => draft.id === projectId);
      callSheetState.callSheetList[index].files.unshift(response);
    })
  )
  .handleType(actionTypes.UPDATE_CALL_SHEET, (state, { payload: { projectId, response } }) =>
    produce(state, (callSheetState) => {
      const index = callSheetState.callSheetList.findIndex((draft) => draft.id === projectId);
      callSheetState.callSheetList[index] = { ...callSheetState.callSheetList[index], ...response };
    })
  )
  .handleType(actionTypes.UPDATE_CALL_SHEET_OPENED_BY, (state, { payload: { projectId, response, fileId } }) =>
    produce(state, (callSheetState) => {
      const index = callSheetState.callSheetList.findIndex((draft) => draft.id === projectId);
      const files = callSheetState.callSheetList[index].files;
      const fileIndex = files.findIndex((file) => file.id === fileId);
      callSheetState.callSheetList[index].files[fileIndex].openedBy = response;
    })
  );

export default callSheetReducer;
