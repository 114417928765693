import BriefHeader from 'atoms/BriefHeader';
import ButtonCheckbox from 'atoms/ButtonCheckbox';
import FieldInputError from 'atoms/FieldInputError';
import HorizontalLine from 'atoms/HorizontalLine';
import { ArrayHelpers, ErrorMessage, Field, FieldArray, FormikProps } from 'formik';
import { t } from 'i18n';
import { PhotoBriefFormField } from 'interfaces/API/products';
import AddCircleButton from 'molecules/AddCircleButton';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import { TextilesBriefFormValues } from 'organisms/ProductBrief/ProductBrief.interfaces';
import React, { Dispatch, SetStateAction } from 'react';
import ProductBriefPhotos from './ProductBriefPhotos';
import translateWithContext from 'helpers/translateWithContext';

interface ProductBriefPhotoProps {
  isEditable: boolean;
  productId: number;
}

const PHOTO_TYPES = [
  { name: 'packshot', value: 'packshot' },
  { name: 'arrangement photo', value: 'arrangement' },
  { name: 'photo with model', value: 'model' },
  { name: 'close-up', value: 'close_up' },
  { name: 'other', value: 'other' },
];

export default function ProductBriefPhoto({ isEditable, productId }: ProductBriefPhotoProps): JSX.Element {
  const [newName, setNewName] = React.useState<string>('');
  const [newNameError, setNewNameError] = React.useState<boolean>(false);

  const addNewName = (push: (obj: PhotoBriefFormField) => void) => {
    if (newNameError) return;
    push({
      name: newName,
      isRequired: true,
      description: '',
      photoType: '',
      photos: [],
    });
    setNewName('');
  };

  const handleNewFieldError = (
    setError: Dispatch<SetStateAction<boolean>>,
    newItemValue: string,
    itemsList?: PhotoBriefFormField[]
  ) => {
    if (itemsList === undefined) return;
    if (itemsList.find((item) => item.name === newItemValue) || !newItemValue) {
      setError(true);
      return;
    }
    setError(false);
  };

  return (
    <div className="field-section field-section--inner">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>{t('photoBrief.title')}</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine isLightBlue />
          </div>
        </div>
        <div className="rows">
          <div className="rows columns">
            <span className="bolder">{t('photoBrief.name.title')}</span>
          </div>
          <div className="row wrap">
            <FieldArray name="photoBrief.fields">
              {({
                replace,
                push,
                remove,
                form: {
                  values: { photoBrief },
                },
              }: ArrayHelpers & { form: FormikProps<TextilesBriefFormValues> }) => {
                if (!photoBrief?.fields.length)
                  return (
                    <AddCircleButton
                      value={newName}
                      placeholder={t('photoBrief.name.addLabel')}
                      isEditable={isEditable}
                      handleDataOnClick={() => addNewName(push)}
                      handleChange={setNewName}
                      isError={newNameError}
                      setIsError={() => handleNewFieldError(setNewNameError, newName, photoBrief?.fields)}
                    />
                  );

                return photoBrief?.fields.map((field: PhotoBriefFormField, currentIndex: number) => {
                  return (
                    <React.Fragment key={`additionalPhotoField-${field.name}`}>
                      <div className="column is-narrow">
                        <ButtonCheckbox
                          disabled={!isEditable}
                          name={`additionalPhotoField-name-${field.name}`}
                          checked={field.isRequired}
                          onChange={() => replace(currentIndex, { ...field, isRequired: !field.isRequired })}
                          isDeleteButtonVisible={isEditable}
                          handleDeleteClick={() => remove(currentIndex)}
                        >
                          {field.name === 'Main photo' ? t('photoBrief.name.default') : field.name}
                        </ButtonCheckbox>
                      </div>
                      {currentIndex === photoBrief?.fields.length - 1 && isEditable && (
                        <AddCircleButton
                          isLightBlue
                          value={newName}
                          placeholder={t('photoBrief.name.addLabel')}
                          isEditable={isEditable}
                          handleDataOnClick={() => addNewName(push)}
                          handleChange={setNewName}
                          isError={newNameError}
                          setIsError={() => handleNewFieldError(setNewNameError, newName, photoBrief?.fields)}
                        />
                      )}
                    </React.Fragment>
                  );
                });
              }}
            </FieldArray>
          </div>
        </div>
        <FieldArray name="photoBrief.fields">
          {({
            replace,
            form: {
              values: { photoBrief },
            },
          }: ArrayHelpers & { form: FormikProps<TextilesBriefFormValues> }) => {
            return photoBrief?.fields.map((field: PhotoBriefFormField, index: number) => {
              return (
                field.isRequired && (
                  <div className="row rows" key={`additionalPhotoField-value-${field.name}`}>
                    <div className="rows">
                      <span className="bolder is-light-blue-text">
                        {t('photoBrief.description.title', {
                          name:
                            field.name === 'Main photo'
                              ? t('photoBrief.name.default')
                              : `${field.name[0].toUpperCase()}${field.name.slice(1)}`,
                        })}
                      </span>
                    </div>
                    <div className="rows">
                      <div>
                        <Field
                          component={FieldTextArea}
                          placeholder={t('photoBrief.description.placeholder', {
                            name:
                              field.name === 'Main photo'
                                ? t('photoBrief.name.default')
                                : `${field.name[0].toUpperCase()}${field.name.slice(1)}`,
                          })}
                          name={`photoBrief.fields[${index}].description`}
                          className="form__input--textarea"
                          disabled={!isEditable}
                          value={field.description}
                          onChange={(e: any) => replace(index, { ...field, description: e?.target?.value || '' })}
                        />
                      </div>
                      <div className="row wrap">
                        {PHOTO_TYPES.map((type: { name: string; value: string }) => {
                          return (
                            <ButtonCheckbox
                              key={`additionalPhotoField-type-${type.name}-${field.name}`}
                              name={`additionalPhotoField-type-${type.name}-${field.name}`}
                              disabled={!isEditable}
                              checked={field.photoType === type.value}
                              onChange={() => replace(index, { ...field, photoType: type.value })}
                              withTooltip
                              tooltipText={translateWithContext('photoBrief.type.type', type.name)}
                            >
                              {translateWithContext('photoBrief.type.type', type.name)}
                            </ButtonCheckbox>
                          );
                        })}
                      </div>
                    </div>
                    <div className="row">
                      <ErrorMessage name={`photoBrief.fields[${index}].description`} component={FieldInputError} />
                    </div>
                    {(isEditable || (!isEditable && field.photos.length)) ? (
                      <ProductBriefPhotos
                        isLightBlue
                        allPhotos={field.photos}
                        formFieldName={`photoBrief.fields[${index}].photos`}
                        isEditable={isEditable}
                        productId={productId}
                        photoBriefCurrentIndex={index}
                      />
                    ): null}
                  </div>
                )
              );
            });
          }}
        </FieldArray>
      </div>
    </div>
  );
}
