import React from 'react';

type RightSidebarHeaderProps = {
  fullSlug: string;
  displayName: string;
};

const RightSidebarHeader: React.FC<RightSidebarHeaderProps> = ({ fullSlug, displayName }) => {
  return (
    <div className="rows right-header">
      <div className="row">
        <span className="right-header__slug">{fullSlug}</span>
      </div>
      <div className="row">
        <span className="right-header__model">{displayName}</span>
      </div>
    </div>
  );
};

export default RightSidebarHeader;
