import { t } from 'i18n';
import { PhotoBriefSchema } from 'organisms/PhotoBrief/PhotoBrief.schema';
import * as yup from 'yup';

const DescriptionLanguageSchema = yup.object().shape({
  name: yup.string(),
  isRequired: yup.bool(),
});

const AdditionalDescriptionSchema = yup.object().shape({
  name: yup.string(),
  value: yup.string(),
  isRequired: yup.bool(),
});

const ProductBriefSchema = yup.object().shape({
  // MAIN DETAILS
  name: yup.string().required(t('productBrief.mainDetails.name.error')),
  modelNo: yup
    .string()
    .typeError(t('productBrief.mainDetails.modelNo.error'))
    .required(t('productBrief.mainDetails.modelNo.error')),
  batchNo: yup
    .string()
    .typeError(t('productBrief.mainDetails.batchNo.error'))
    .required(t('productBrief.mainDetails.batchNo.error')),
  packagingType: yup
    .number()
    .typeError(t('productBrief.mainDetails.packagingType.error'))
    .required(t('productBrief.mainDetails.packagingType.error')),

  requirePhotoBrief: yup.boolean(),
  photoBrief: yup
    .array()
    .of(PhotoBriefSchema)
    .when('requirePhotoBrief', {
      is: true,
      then: (m: any) => m.required(),
    }),

  requireIllustration: yup.boolean(),
  illustration: yup.string().when('requireIllustration', {
    is: true,
    then: (m: any) => m.required(t('productBrief.illustration.illustration.error')),
  }),

  // ATTACHMENTS
  photos: yup.array().min(1, t('productBrief.attachments.photos.error')),
  blankings: yup.array().min(1, t('productBrief.attachments.blankings.error')),

  // DESCRIPTION
  descriptionLanguages: yup.array().of(DescriptionLanguageSchema),
  mainDescription: yup.string().required(t('productBrief.description.mainDescription.error')),
  extraDescription: yup.string().required(t('productBrief.description.additionalDescription.error')),

  // ADDITIONAL DESCRIPTION
  additionalDescriptionElements: yup.array().of(AdditionalDescriptionSchema),

  // PACKAGING MARKINGS
  certificate: yup.string().required(t('productBrief.signs.certificate.error')),
});

export default ProductBriefSchema;
