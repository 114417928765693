import { useSelector } from 'react-redux';
import productSelectors from 'modules/products/selectors';
import useNumberParams from 'hooks/useNumberParams';
import { AppState } from 'modules/rootReducer';
import { AnyProductStore } from 'interfaces/API/products';

interface UseProductBriefNavigationProps {
  isAcceptable: boolean;
  isEditable: boolean;
  isPublishable: boolean;
  toggleRejectModal: () => void;
  togglePublishModal: () => void;
  toggleAcceptModal: () => void;
  toggleAddSampleModal: () => void;
  isPublishModalOpen: boolean;
  isSubmitting: boolean;
  onSaveAction: () => Promise<void>;
  product: AnyProductStore | undefined;
  newId: { nextProductId: number; previousProductId: number };
  userRole: string | null;
}

export default function useProductBriefNavigation({
  isAcceptable,
  isEditable,
  isPublishable,
  toggleRejectModal,
  togglePublishModal,
  toggleAcceptModal,
  toggleAddSampleModal,
  isPublishModalOpen,
  isSubmitting,
  onSaveAction,
  product,
  newId,
  userRole,
}: UseProductBriefNavigationProps) {
  const { projectId, productId } = useNumberParams();

  const [currentProjectProductsLength, currentProductIndex] = useSelector((state: AppState) => [
    productSelectors.projectProductsLength(projectId)(state),
    productSelectors.projectProductIndex(projectId, productId)(state),
  ]);

  return {
    stage: product ? product.stage : null,
    acceptable: isAcceptable,
    editable: isEditable,
    publishable: isPublishable,
    projectId: Number(projectId),
    nextProductId: newId ? newId.nextProductId : null,
    previousProductId: newId ? newId.previousProductId : null,
    currentUserRole: userRole,
    rejectProductBrief: toggleRejectModal,
    publishProductBrief: togglePublishModal,
    acceptProductBrief: toggleAcceptModal,
    addSample: toggleAddSampleModal,
    isPublishModalOpen: isPublishModalOpen,
    productsLength: currentProjectProductsLength,
    currentProductIndex: currentProductIndex,
    isSubmitting: isSubmitting,
    onSaveAction: onSaveAction,
  };
}
