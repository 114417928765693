import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import { DesignStageStore } from 'interfaces/API/draft';
import useRequestState, { RequestState } from './useRequestState';
import { photoRetouchById } from 'modules/photoRetouch/selectors';
import { getPhotoRetouchByProductId } from 'modules/photoRetouch/actions';

type CurrentPhotoRetouch = [RequestState, DesignStageStore | null, () => void];

const useCurrentPhotoRetouch = (): CurrentPhotoRetouch => {
  const { productId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const draft = useSelector(photoRetouchById(productId));

  const handleRetry = (): void => {
    setDefault();
  };

  useEffect(() => {
    if (!draft && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await getPhotoRetouchByProductId(productId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, draft, productId, setDefault, setError, setLoading, status]);

  return [status, draft ? draft : null, handleRetry];
};
export default useCurrentPhotoRetouch;
