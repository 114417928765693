import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import Navbar from 'components/Navbar';
import { useDispatch } from 'react-redux';
import { getCurrentUser, getAllUsers } from 'modules/users/actions';
import { getPackagingTypes, getCategories, getBrands, getProductStages, getProductPhotoStages } from 'modules/data/actions';
import LeftSidebar from 'organisms/LeftSidebar';
import RightSideBar from 'organisms/RightSideBar';
import { ClimbingBoxLoader } from 'react-spinners';
import projectActions from 'modules/projects/actions';
import { getProductsList } from 'modules/products/actions';
import ProgressBar from 'molecules/Progressbar';
import Dashboard from 'organisms/Dashboard';
import { RetryScreen } from 'organisms/RetryScreen';
import { OrderByEnum } from 'interfaces/API/projects';
import useLocationParams from 'hooks/useLocationParams';

const Main: React.FC = (props) => {
  const [params] = useLocationParams();

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const handleFetchData = useCallback(async () => {
    setIsLoading(true);
    const results = [
      await getProductsList(dispatch),
      await getCurrentUser(dispatch),
      await getProductStages(dispatch),
      await getPackagingTypes(dispatch),
      await getCategories(dispatch),
      await getBrands(dispatch),
      await getAllUsers(dispatch),
      await getProductPhotoStages(dispatch)
    ];
    if (results.includes(false)) {
      setError(true);
    } else {
      setError(false);
    }
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    (async (): Promise<void> => {
      await handleFetchData();
      setLoaded(true);
    })();
  }, [dispatch, handleFetchData]);

  const handleFetchProjects = useCallback(async () => {
    setProjectsLoading(true);
    await projectActions.getProjectsListAction(dispatch, {
      order_by: params.get('orderBy') as OrderByEnum,
      query: params.get('searchValue') as string,
    });
    setProjectsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.get('searchValue'), params.get('orderBy')]);
  useEffect(() => {
    handleFetchProjects();
  }, [handleFetchProjects]);

  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = (ev: any) => {
    if (sidebarRef.current) {
      sidebarRef.current.style.height = `calc(100vh - 3.25rem + min(${ev.target.scrollTop}px, 3.25rem)`;
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return loaded ? (
    <div className="container is-fluid" id="container">
      <Navbar { ...props} />
      <ProgressBar />
      <div className="columns is-marginless">
        <div
          ref={sidebarRef}
          id={'sidebar'}
          className="column is-3 left-sidebar"
          style={{
            height: 'calc(100vh - 3.25rem)',
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            top: 0,
          }}
        >
          {match && <LeftSidebar isLoading={projectsLoading} />}
        </div>
        <div className="column">
          {error ? (
            <RetryScreen
              onRetry={() => {
                handleFetchData();
                handleFetchProjects();
              }}
              isLoading={isLoading}
            />
          ) : (
            <Dashboard {...props} />
          )}
        </div>
        <div className="column is-3 right-container">{match && <RightSideBar />}</div>
      </div>
    </div>
  ) : (
    <div className="hero is-fullheight">
      <div className="container columns has-items-centered">
        <div className="column">
          <ClimbingBoxLoader color="#f58c39" size={1.5} sizeUnit="rem" />
        </div>
      </div>
    </div>
  );
};

export default Main;
