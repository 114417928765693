import { actionTypes, DataState, DataActionTypes } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

const initialState: DataState = {
  packagingTypes: [],
  brands: [],
  categories: [],
  productStages: [],
  productPhotoStages: [],
};

const dataReducer = createReducer<DataState, DataActionTypes>(initialState)
  .handleType(actionTypes.SET_PACKAGING_TYPES, (state, action) =>
    produce(state, (draftState) => {
      draftState.packagingTypes = action.payload;
    })
  )
  .handleType(actionTypes.SET_BRANDS, (state, action) =>
    produce(state, (draftState) => {
      draftState.brands = action.payload;
    })
  )
  .handleType(actionTypes.SET_CATEGORIES, (state, action) =>
    produce(state, (draftState) => {
      draftState.categories = action.payload;
    })
  )
  .handleType(actionTypes.SET_PRODUCT_STAGES, (state, action) =>
    produce(state, (draftState) => {
      draftState.productStages = action.payload;
    })
  )
  .handleType(actionTypes.SET_PRODUCT_PHOTO_STAGES, (state, action) =>
    produce(state, (draftState) => {
      draftState.productPhotoStages = action.payload;
    })
  );

export default dataReducer;
