import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import callSheetApi from 'API/callSheets';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import {
  DesignStageResponse,
  DesignStageStore,
  DesignStageFileRequest,
  DesignStageFileResponse,
} from 'interfaces/API/draft';
import { ProductActionTypes, updateProductsOnSuccess } from 'modules/products/actions';
import ProjectsActions from 'modules/projects/actions';
import { toast } from 'react-toastify';

export type CallSheetState = {
  callSheetList: DesignStageStore[];
};

export enum actionTypes {
  ADD_CALL_SHEET = 'callSheets/ADD_CALL_SHEET',
  UPDATE_CALL_SHEET = 'callSheets/UPDATE_CALL_SHEET',
  ADD_CALL_SHEET_FILE = 'callSheets/ADD_CALL_SHEET_FILE',
  UPDATE_CALL_SHEET_OPENED_BY = 'callSheets/UPDATE_CALL_SHEET_OPENED_BY',
}

const internalActions = {
  addCallSheet: createAction(actionTypes.ADD_CALL_SHEET)<{ projectId: number; response: DesignStageResponse }>(),
  updateCallSheet: createAction(actionTypes.UPDATE_CALL_SHEET)<{
    projectId: number;
    response: DesignStageResponse;
  }>(),
  addCallSheetFile: createAction(actionTypes.ADD_CALL_SHEET_FILE)<{
    projectId: number;
    response: DesignStageFileResponse;
  }>(),
  updateOpenedFile: createAction(actionTypes.UPDATE_CALL_SHEET_OPENED_BY)<{
    projectId: number;
    response: number[];
    fileId: number;
  }>(),
};

export type CallSheetActionTypes = ActionType<typeof internalActions>;
type CallSheetFile = CallSheetActionTypes | ProductActionTypes | ActivityLogActionTypes;

export const getCallSheetByProjectId = async (
  projectId: number,
  dispatch: Dispatch<CallSheetActionTypes>
): Promise<boolean> => {
  const response = await callSheetApi.getCallSheetByProjectId(projectId);
  if (!response) {
    return false;
  }
  dispatch(internalActions.addCallSheet({ projectId, response }));
  return true;
};

export const addCallSheetFile = async (
  dispatch: Dispatch<CallSheetFile>,
  projectId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await callSheetApi.addCallSheetFile(projectId, data);
  if (response) {
    dispatch(internalActions.addCallSheetFile({ projectId, response }));
    const draftResponse = await callSheetApi.getCallSheetByProjectId(projectId);
    if (draftResponse) {
      dispatch(internalActions.updateCallSheet({ projectId, response: draftResponse }));
      await ActivityLogActions.getProjectMessages(dispatch, projectId);
    }
    await updateProductsOnSuccess(projectId, dispatch);
    await ProjectsActions.getPhotoProjectByIdAction(dispatch, projectId);
    toast.success('Dodano Call Sheet');
  } else {
    toast.error('Nie udało się dodać');
  }
};

export const acceptCallSheetFile = async (dispatch: Dispatch<CallSheetFile>, projectId: number): Promise<void> => {
  const response = await callSheetApi.acceptCallSheet(projectId);
  if (response) {
    dispatch(internalActions.updateCallSheet({ projectId, response }));
    await updateProductsOnSuccess(projectId, dispatch);
    await ProjectsActions.getPhotoProjectByIdAction(dispatch, projectId);
    await ActivityLogActions.getProjectMessages(dispatch, projectId);
    toast.success('Zaakceptowano Call Sheet');
  } else {
    toast.error('Wystąpił błąd podczas akceptowania');
  }
};

export const updateOpenCallSheetFile = (
  dispatch: Dispatch<CallSheetActionTypes>,
  projectId: number,
  openedBy: number[],
  fileId: number
) => {
  dispatch(internalActions.updateOpenedFile({ projectId, response: openedBy, fileId }));
};
