import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { PhotoBriefStore, PhotoBriefFullResponse } from 'interfaces/API/products';
import briefApi from 'API/photoBrief';
import { filterPhotos } from 'helpers/briefFileHelpers';
import { toast } from 'react-toastify';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { ProductActionTypes, updatePhotoProductOnSuccess } from 'modules/products/actions';
import actionTypes from './actionTypes';
import { ProductSampleCreate, ProductSampleResponse } from 'interfaces/API/sample';
export interface BriefState {
  photoBriefs: PhotoBriefStore[];
  redirectTo: string | null;
};

export const updateBrief = createAction(actionTypes.UPDATE_BRIEF)<{ briefId: number; response: PhotoBriefFullResponse }>();

export const redirectTo = createAction(actionTypes.REDIRECT_TO)<string | null>();

const externalActions = {
  updateBrief,
  redirectTo,
};

const internalActions = {
  saveBrief: createAction(actionTypes.SAVE_BRIEF)<{ briefId: number; response: PhotoBriefFullResponse }>(),
  deletePhotoBriefPhoto: createAction(actionTypes.DELETE_BRIEF_PHOTO_BRIEF_PHOTO)<{ briefId: number; photoId: number, photoBriefIndex: number }>(),
  addSample: createAction(actionTypes.ADD_SAMPLE)<ProductSampleResponse>(),
};

export type BriefActionTypes = ActionType<typeof internalActions | typeof externalActions>;

type ModifyProductActionTypes = BriefActionTypes | ActivityLogActionTypes | ProductActionTypes;

const getBriefById = async (dispatch: Dispatch<BriefActionTypes>, briefId: number): Promise<boolean> => {
  const response = await briefApi.getBriefById(briefId);
  if (response !== null) {
    dispatch(internalActions.saveBrief({ briefId, response }));
    
    const sampleResponse = await briefApi.getSample(briefId);
    if (sampleResponse !== null) dispatch(internalActions.addSample(sampleResponse));

    
    return true;
  }
  return false;
};

const patchBriefAction = async (
  dispatch: Dispatch<ModifyProductActionTypes>,
  briefId: number,
  {fields, ...brief}: PhotoBriefStore
): Promise<void> => {

  const photoBriefFields = fields.map((field) => {
    return {
      ...field,
      photos: filterPhotos(field.photos),
    };
  });
  const response = await briefApi.patchBriefById(briefId, {
    fields: photoBriefFields,
    ...brief
      });
  if (response !== null) {
    dispatch(updateBrief({ briefId, response }));
    await updatePhotoProductOnSuccess(briefId, dispatch);
    toast.success('Zapisano produkt.');
  } else {
    toast.error('Błąd zapisu.');
  }
};

const acceptBriefAction = async (dispatch: Dispatch<ModifyProductActionTypes>, briefId: number): Promise<void> => {
  const response = await briefApi.acceptBrief(briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
    await updatePhotoProductOnSuccess(briefId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, briefId);
    toast.success('Zaakceptowano produkt.');
  }
};

const rejectBriefAction = async (dispatch: Dispatch<ModifyProductActionTypes>, briefId: number): Promise<void> => {
  const response = await briefApi.rejectBrief(briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
    await updatePhotoProductOnSuccess(briefId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, briefId);
    toast.success('Odrzucono produkt.');
  }
};

const publishBriefAction = async (dispatch: Dispatch<ModifyProductActionTypes>, briefId: number): Promise<void> => {
  const response = await briefApi.publishBrief(briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
    await updatePhotoProductOnSuccess(briefId, dispatch);
    await ActivityLogActions.getProductMessages(dispatch, briefId);
    toast.success('Opublikowano produkt.');
  }
};

const deletePhotoBriefPhotoAction = async (dispatch: Dispatch<BriefActionTypes>, photoId: number, briefId: number, photoBriefIndex: number): Promise<void> => {
  const response = await briefApi.deletePhotoBriefPhoto(photoId);
  if (response === 200 || response === 204) {
    dispatch(internalActions.deletePhotoBriefPhoto({ briefId, photoId, photoBriefIndex }));
  }
};

const cloneBrief = async (dispatch: Dispatch<BriefActionTypes>, briefId: number, cloneFrom: number): Promise<void> => {
  const response = await briefApi.cloneBrief(cloneFrom, briefId);
  if (response) {
    dispatch(updateBrief({ briefId, response }));
  }
};

const sampleAction = async (dispatch: Dispatch<any>, body: ProductSampleCreate): Promise<void> => {
  const response = await briefApi.addSample(body)
  if(response) {
    dispatch(internalActions.addSample(response))
//     await getBriefById(dispatch, response.product)
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cloneBrief,
  deletePhotoBriefPhotoAction,
  getBriefById,
  acceptBriefAction,
  rejectBriefAction,
  publishBriefAction,
  patchBriefAction,
  sampleAction,
};
