import API, {PACKAGING_PROJECTS_PREFIX} from './axiosConfig';
import { DesignStageResponse, DesignStageFileRequest, DesignStageFileResponse } from 'interfaces/API/draft';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { AxiosResponse } from 'axios';

const getDraftById = async (draftId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.get<DesignStageResponse>(`/${PACKAGING_PROJECTS_PREFIX}/product-drafts/${draftId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const addDraftFile = async (draftId: number, data: DesignStageFileRequest): Promise<DesignStageFileResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<DesignStageFileResponse>(`/${PACKAGING_PROJECTS_PREFIX}/product-drafts/${draftId}/files/`, formData);
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptDraft = async (draftId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PACKAGING_PROJECTS_PREFIX}/product-drafts/${draftId}/accept/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const openDraft = async (projectId: number, productDraftId: number, fileId: number) => {
  try {
    const response = await API.get<AxiosResponse>(`/${PACKAGING_PROJECTS_PREFIX}/product-drafts/${productDraftId}/files/${fileId}/open-file/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  openDraft,
  getDraftById,
  addDraftFile,
  acceptDraft,
};
// export const fetchDrafts = async (productId: number): Promise<DraftResponse[] | []> => {
//   try {
//     const response = await API.get<DraftResponse[]>('');
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return [];
//   }
// };
