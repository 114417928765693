import { CirclePlus } from 'assets';
import React, { useEffect, useRef, useState } from 'react';
import './AddCircleButton.scss';

interface AddCircleButtonProps {
  value: string;
  placeholder: string;
  isEditable: boolean;
  isError: boolean;
  className?: string;
  isLightBlue?: boolean;
  handleDataOnClick: () => void;
  handleChange: (value: string) => void;
  setIsError: () => void;
}

enum KeyBoardKeys {
  Enter = 'Enter',
}

export default function AddCircleButton({
  value,
  placeholder,
  isEditable,
  isError,
  className,
  isLightBlue,
  handleDataOnClick,
  handleChange,
  setIsError,
}: AddCircleButtonProps): JSX.Element {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!isOpened) {
      setIsOpened(true);
      return;
    }

    setIsOpened(false);
    handleDataOnClick();
  };

  useEffect(() => {
    if (!isOpened) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (inputRef.current?.contains(e.target as Node)) return;
      setIsOpened(false);
      handleChange('');
    };

    const handleEnterClick = (e: KeyboardEvent) => {
      if (isError) return;
      if (e.key === KeyBoardKeys.Enter) {
        setIsOpened(false);
        handleDataOnClick();
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleEnterClick);

    inputRef.current?.focus();
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEnterClick);
    };
  }, [handleChange, handleDataOnClick, isError, isOpened]);

  useEffect(() => setIsError(), [setIsError, value]);

  return (
    <div className={`addCircleButton ${className}`}>
      <div onClick={handleClick} className="addCircleButton__icon">
        <CirclePlus className={`${isLightBlue ? 'icon--blue' : ''}`} />
      </div>
      {isOpened ? (
        <div className="addCircleButton__field">
          <input
            className={`field__input ${isError ? 'field__input--error' : ''}`}
            placeholder={placeholder}
            value={value}
            disabled={!isEditable}
            onChange={(e) => handleChange(e.target.value)}
            ref={inputRef}
          />
        </div>
      ) : null}
    </div>
  );
}
