enum actionTypes {
  SAVE_BRIEF = 'photoBriefs/SAVE_BRIEF',
  UPDATE_BRIEF = 'photoBriefs/UPDATE_BRIEF',
  ADD_BRIEF_BLANKING = 'photoBriefs/ADD_BRIEF_BLANKING',
  ADD_BRIEF_PHOTO = 'photoBriefs/ADD_BRIEF_PHOTO',
  DELETE_BRIEF_BLANKING = 'photoBriefs/DELETE_BRIEF_BLANKING',
  DELETE_BRIEF_PHOTO = 'photoBriefs/DELETE_BRIEF_PHOTO',
  DELETE_BRIEF_PHOTO_BRIEF_PHOTO = 'photoBriefs/DELETE_BRIEF_PHOTO_BRIEF_PHOTO',
  CLONE_BRIEF = 'photoBriefs/CLONE_BRIEF',
  REDIRECT_TO = 'photoBriefs/REDIRECT_TO',
  ADD_SAMPLE = 'photo-projects/ADD_SAMPLE'
}

export default actionTypes;
