import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0c108cf089404f8f8d5dbf247d2f7d0b@sentry.ermlab.software/8',
    environment: process.env.REACT_APP_OUTBO_ENV,
  });
  LogRocket.init('efklor/outbo');
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

const body = document.querySelector('body');
const now = new Date();
if (body) {
  // body.style.opacity = ((100-Math.min(95,(+now - Date.parse('2021-08-1'))/86400000*3))/100).toString()
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
