import { AxiosResponse } from 'axios';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { DesignStageFileRequest, DesignStageFileResponse, DesignStageResponse } from 'interfaces/API/draft';
import API, { PHOTO_PROJECTS_PREFIX } from './axiosConfig';

const getPhotoRetouchByProductId = async (productId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.get<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-retouch/${productId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const addPhotoRetouchFile = async (
  productId: number,
  data: DesignStageFileRequest
): Promise<DesignStageFileResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<DesignStageFileResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-retouch/${productId}/files/`, formData);
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptPhotoRetouch = async (productId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-retouch/${productId}/accept/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const rejectPhotoRetouch = async (productId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-retouch/${productId}/accept/`, { accept: false });
    return response.data;
  } catch (err) {
    return null;
  }
};

const publishPhotoRetouch = async (productId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-retouch/${productId}/publish/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const getPhotoRetouchFileDetails = async (projectId: number, productId: number, fileId: number) => {
  try {
    const response = await API.get<AxiosResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-retouch/${productId}/files/${fileId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const deletePhotoRetouchFile= async ( productId: number, fileId: number) => {
  try {
    const response = await API.delete<AxiosResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-retouch/${productId}/files/${fileId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  getPhotoRetouchByProductId,
  addPhotoRetouchFile,
  acceptPhotoRetouch,
  getPhotoRetouchFileDetails,
  rejectPhotoRetouch,
  publishPhotoRetouch,
  deletePhotoRetouchFile
};
