import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import photoShootPlanApi from 'API/photoShootPlans';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import {
  DesignStageResponse,
  DesignStageStore,
  DesignStageFileRequest,
  DesignStageFileResponse,
} from 'interfaces/API/draft';
import { ProductActionTypes, updateProductsOnSuccess } from 'modules/products/actions';
import ProjectsActions from 'modules/projects/actions';
import { toast } from 'react-toastify';

export type PhotoShootPlanState = {
  photoShootPlanList: DesignStageStore[];
};

export enum actionTypes {
  ADD_PHOTO_SHOOT_PLAN = 'photoShootPlans/ADD_PHOTO_SHOOT_PLAN',
  UPDATE_PHOTO_SHOOT_PLAN = 'photoShootPlans/UPDATE_PHOTO_SHOOT_PLAN',
  ADD_PHOTO_SHOOT_PLAN_FILE = 'photoShootPlans/ADD_PHOTO_SHOOT_PLAN_FILE',
  UPDATE_PHOTO_SHOOT_PLAN_OPENED_BY = 'photoShootPlans/UPDATE_PHOTO_SHOOT_PLAN_OPENED_BY',
}

const internalActions = {
  addPhotoShootPlan: createAction(actionTypes.ADD_PHOTO_SHOOT_PLAN)<{ projectId: number; response: DesignStageResponse }>(),
  updatePhotoShootPlan: createAction(actionTypes.UPDATE_PHOTO_SHOOT_PLAN)<{
    projectId: number;
    response: DesignStageResponse;
  }>(),
  addPhotoShootPlanFile: createAction(actionTypes.ADD_PHOTO_SHOOT_PLAN_FILE)<{
    projectId: number;
    response: DesignStageFileResponse;
  }>(),
  updateOpenedFile: createAction(actionTypes.UPDATE_PHOTO_SHOOT_PLAN_OPENED_BY)<{
    projectId: number;
    response: number[];
    fileId: number;
  }>(),
};

export type PhotoShootPlanActionTypes = ActionType<typeof internalActions>;
type PhotoShootPlanFile = PhotoShootPlanActionTypes | ProductActionTypes | ActivityLogActionTypes;

export const getPhotoShootPlanByProjectId = async (
  projectId: number,
  dispatch: Dispatch<PhotoShootPlanActionTypes>
): Promise<boolean> => {
  const response = await photoShootPlanApi.getPhotoShootPlanByProjectId(projectId);
  if (!response) {
    return false;
  }
  dispatch(internalActions.addPhotoShootPlan({ projectId, response }));
  return true;
};

export const addPhotoShootPlanFile = async (
  dispatch: Dispatch<PhotoShootPlanFile>,
  projectId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await photoShootPlanApi.addPhotoShootPlanFile(projectId, data);
  if (response) {
    dispatch(internalActions.addPhotoShootPlanFile({ projectId, response }));
    const draftResponse = await photoShootPlanApi.getPhotoShootPlanByProjectId(projectId);
    if (draftResponse) {
      dispatch(internalActions.updatePhotoShootPlan({ projectId, response: draftResponse }));
      await ActivityLogActions.getProjectMessages(dispatch, projectId);
    }
    await updateProductsOnSuccess(projectId, dispatch);
    await ProjectsActions.getPhotoProjectByIdAction(dispatch, projectId);
    toast.success('Dodano Photoshoot plan');
  } else {
    toast.error('Nie udało się dodać');
  }
};

export const acceptPhotoShootPlanFile = async (dispatch: Dispatch<PhotoShootPlanFile>, projectId: number): Promise<void> => {
  const response = await photoShootPlanApi.acceptPhotoShootPlan(projectId);
  if (response) {
    dispatch(internalActions.updatePhotoShootPlan({ projectId, response }));
    await updateProductsOnSuccess(projectId, dispatch);
    await ProjectsActions.getPhotoProjectByIdAction(dispatch, projectId);
    await ActivityLogActions.getProjectMessages(dispatch, projectId);
    toast.success('Zaakceptowano Photoshoot plan');
  } else {
    toast.error('Wystąpił błąd podczas akceptowania');
  }
};

export const updateOpenPhotoShootPlanFile = (
  dispatch: Dispatch<PhotoShootPlanActionTypes>,
  projectId: number,
  openedBy: number[],
  fileId: number
) => {
  dispatch(internalActions.updateOpenedFile({ projectId, response: openedBy, fileId }));
};
