import { actionTypes, PhotoRetouchActionTypes, PhotoRetouchState } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DesignStageStore } from 'interfaces/API/draft';

const initialState: PhotoRetouchState = {
  photoRetouchList: [],
};

const photoRetouchReducer = createReducer<PhotoRetouchState, PhotoRetouchActionTypes>(initialState)
  .handleType(actionTypes.ADD_PHOTO_RETOUCH, (state, action) =>
    produce(state, (photoRetouchState) => {
      const draft: DesignStageStore = { ...action.payload.response, id: action.payload.productId };
      photoRetouchState.photoRetouchList.push(draft);
    })
  )
  .handleType(actionTypes.ADD_PHOTO_RETOUCH_FILE, (state, { payload: { productId, response } }) =>
    produce(state, (photoRetouchState) => {
      const index = photoRetouchState.photoRetouchList.findIndex((draft) => draft.id === productId);
      photoRetouchState.photoRetouchList[index].files.unshift(response);
    })
  )
  .handleType(actionTypes.DELETE_PHOTO_RETOUCH_FILE, (state, { payload: { productId, fileId } }) =>
    produce(state, (photoRetouchState) => {
      const index = photoRetouchState.photoRetouchList.findIndex((draft) => draft.id === productId);
      const files = photoRetouchState.photoRetouchList[index].files;
      photoRetouchState.photoRetouchList[index].files = files.filter((file)=> file.id !== fileId)
    })
  )
  .handleType(actionTypes.UPDATE_PHOTO_RETOUCH, (state, { payload: { productId, response } }) =>
    produce(state, (photoRetouchState) => {
      const index = photoRetouchState.photoRetouchList.findIndex((draft) => draft.id === productId);
      photoRetouchState.photoRetouchList[index] = { ...photoRetouchState.photoRetouchList[index], ...response };
    })
  )
  .handleType(actionTypes.UPDATE_PHOTO_RETOUCH_OPENED_BY, (state, { payload: { productId, response, fileId } }) =>
    produce(state, (photoRetouchState) => {
      const index = photoRetouchState.photoRetouchList.findIndex((draft) => draft.id === productId);
      const files = photoRetouchState.photoRetouchList[index].files;
      const fileIndex = files.findIndex((file) => file.id === fileId);
      photoRetouchState.photoRetouchList[index].files[fileIndex].openedBy = response;
    })
  );

export default photoRetouchReducer;
