import React, { useContext, useState } from 'react';
import { OptionDots, OutboLogo, DocIcon } from 'assets';
import DropdownItem from './DropdownItem';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'modules/rootReducer';
import { UserActionTypes, logoutUser } from 'modules/users/actions';
import { Dispatch } from 'redux';
import { ThemeContext } from 'App';

const Navbar: React.FC = (props) => {
  const {theme, setTheme, toggleTheme} = useContext(ThemeContext)
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();
  const user = useSelector((state: AppState) => state.users.currentUser);
  const [fontSize, setFontSize] = useState(false)

 const root = document.documentElement;
 const fontSizeHandler = () => {
   if (localStorage.getItem('fontSize') === '20px'){
     root.style.setProperty('font-size', 16+"px");
     localStorage.setItem('fontSize', 16+"px");
     setFontSize(false)
   }
   else if (localStorage.getItem('fontSize') === '16px' || (localStorage.getItem('fontSize') === null))  {
     root.style.setProperty('font-size', 20+"px");
     localStorage.setItem('fontSize', 20+"px");
     setFontSize(true)
   }
 }

  return (
    <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item navbar-item__logo">
          <OutboLogo style={{ maxHeight: '2.75rem' }} className="navbar-item__logo" />
        </a>
      </div>
      <div className="navbar-menu">
        <div className="navbar-end">
      <div className="navbar-togglers">
         <button className='button is-orange-lighten m-1' onClick={toggleTheme}><span>{theme}</span></button>
        <button className='button is-orange-lighten m1' onClick={fontSizeHandler}>
          {!fontSize
          ? <p className='bigger'>a <span> / A</span></p>
          : <p className='bigger'><span style={{textTransform: 'lowercase'}}>a /</span> A</p>
          }
        </button>
      </div>
          {user && (
            <div className="navbar-item">
              <div className="avatar">{user.photo && <img src={user.photo} alt={'avatar'} />}</div>
              <div className="navbar-item rows" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <div>{user.fullName}</div>
                <div className="font-small">{user.organization.name}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="navbar-item has-dropdown is-hoverable">
        <div className="navbar-item">
          <div className="navbar-item__dots">
            <OptionDots />
          </div>
          <div className="navbar-dropdown is-right">
            <DropdownItem
              text="Logout"
              icon={<DocIcon />}
              onClick={()=> {
                logoutUser(dispatch);
                setTheme("light")
              }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
