export default function checkEAN(ean: string): boolean {
  if (!ean) return false;

  const eanList = ean.split('');

  if (eanList.length !== 13) return false;

  let oddDigitsChecksum = 0;
  let evenDigitsChecksum = 0;

  for (let i = 0; i < 12; i++) {
    const digit = parseInt(eanList[i]);
    if (isNaN(digit)) {
      return false;
    }
    if (i % 2 === 0) {
      oddDigitsChecksum += digit;
    } else {
      evenDigitsChecksum += digit;
    }
  }
  evenDigitsChecksum *= 3;

  const checksum = oddDigitsChecksum + evenDigitsChecksum;
  const checkDigit = (10 - (checksum % 10)) % 10;

  if (checkDigit !== parseInt(eanList[12])) return false;

  return true;
}
