import { ProjectState, ProjectActionTypes } from './actions';
import actionTypes from './actionTypes';
import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { OrderByEnum } from 'interfaces/API/projects';
const initialState: ProjectState = {
  projectsList: [],
  orderBy: OrderByEnum.MOD,
};

const projectsReducer = createReducer<ProjectState, ProjectActionTypes>(initialState)
  .handleType(actionTypes.UPDATE_PROJECT_LIST, (state, action) => {
    return { ...state, projectsList: action.payload };
  })
  .handleType(actionTypes.ADD_PROJECT, (state, action) =>
    produce(state, (draftState) => {
      draftState.projectsList.push(action.payload);
      draftState.projectsList = draftState.projectsList.sort((a, b) =>
        a.deadlineDate > b.deadlineDate ? 1 : -1
      );
    })
  )
  .handleType(actionTypes.ADD_PHOTO_PROJECT, (state, action) =>
    produce(state, (draftState) => {
      draftState.projectsList.push(action.payload);
      draftState.projectsList = draftState.projectsList.sort((a, b) =>
        a.deadlineDate > b.deadlineDate ? 1 : -1
      );
    })
  )
  .handleType(actionTypes.UPDATE_PROJECT, (state, action) =>
    produce(state, (draftState) => {
      const index = draftState.projectsList.findIndex((project) => project.id === action.payload.projectId);
      draftState.projectsList[index] = { ...draftState.projectsList[index], ...action.payload.response };
    })
  )
  .handleType(actionTypes.UPDATE_LIST_ORDER, (state, action) =>
    produce(state, (draftState) => {
      draftState.orderBy = action.payload;
    })
  );

export default projectsReducer;
