
import { CirclePlus } from 'assets';
import FieldInputError from 'atoms/FieldInputError';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { handleImageSelect } from 'helpers/fileHelpers';
import useToggler from 'hooks/useToggler';
import CustomImage from 'molecules/ProductBriefImage';
import React, { useRef, useState } from 'react';
import briefActions from 'modules/photoBrief/actions';
import ProductBriefModal from '../PhotoBriefModal';
import { t } from 'i18n';
import { useDispatch } from 'react-redux';
import CustomLightbox from 'molecules/CustomLightbox';
import { PhotoFormModel } from 'interfaces/API/products';

export interface ProductBriefPhotosProps {
  formFieldName: string;
  isEditable: boolean;
  productId: number;
  allPhotos: PhotoFormModel[];
  photoBriefCurrentIndex: number;
  isLightBlue?: boolean;
};

export default function ProductBriefPhotos({
  formFieldName,
  isEditable,
  productId,
  allPhotos,
  photoBriefCurrentIndex,
  isLightBlue,
}: ProductBriefPhotosProps): JSX.Element {
  const [isPhotoModalOpen, togglePhotoModal] = useToggler();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [lightboxOpen, toggleLightBox] = useToggler();
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const dispatch = useDispatch();

  const onImagePickerClick = (): void => {
    if (imageInputRef && imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  return (
    <>
      <div className="field-section">
        <div className="rows">
          <FieldArray name={formFieldName}>
            {({ push, remove, form: { values } }): JSX.Element => {
              const formFieldPhotos = values.fields[photoBriefCurrentIndex].photos;
              const photos = formFieldPhotos?.map((photo: PhotoFormModel, index: number) => {
                return (
                  <Field
                    key={photo.url}
                    component={CustomImage}
                    removeItem={(e: any): any => {
                      e.stopPropagation();
                      togglePhotoModal();
                      setCurrentIndex(index);
                    }}
                    name={`${formFieldName}[${index}]`}
                    className="image image__box pointer image__with-remove"
                    disabled={!isEditable}
                    toggleLightBox={(): void => {
                      setLightboxIndex(index);
                      toggleLightBox();
                    }}
                    alt={photo.filename}
                  />
                );
              });

              return (
                <>
                  <div className="row">
                    <span className="bolder">Zdjęcia produktu:</span>
                    <input
                      type="file"
                      ref={imageInputRef}
                      className="image-picker__input pointer"
                      accept="image/*"
                      multiple
                      onChange={(e) => handleImageSelect(e, push)}
                    />
                  </div>
                  <div className="row wrap">
                    {isEditable && (
                      <div
                        className="image-picker image-picker__box pointer"
                        onClick={onImagePickerClick}
                        data-testid="productPhotoPicker"
                      >
                        <CirclePlus className={`${isLightBlue ? 'is-light-blue-fill' : ''}`} />
                      </div>
                    )}
                    {photos || null}
                    <ErrorMessage name={formFieldName} component={FieldInputError} />
                  </div>
                  {isPhotoModalOpen && currentIndex !== null && (
                    <ProductBriefModal
                      isLightBlue={isLightBlue}
                      isModalOpen={isPhotoModalOpen}
                      setOpen={togglePhotoModal}
                      header={t('productBrief.attachments.photos.modal.header')}
                      content={
                        <div className="modal-content__image">
                          <img
                            src={allPhotos[currentIndex] && allPhotos[currentIndex].url}
                            alt={allPhotos[currentIndex].filename}
                          />
                        </div>
                      }
                      isSubmitting={false}
                      handleSuccess={async (): Promise<void> => {
                        if ('file' in allPhotos[currentIndex]) {
                          remove(currentIndex);
                        } else if ('id' in allPhotos[currentIndex]) {
                          await briefActions.deletePhotoBriefPhotoAction(
                              dispatch,
                              allPhotos[currentIndex].id as number,
                              productId,
                              photoBriefCurrentIndex
                            );
                                                  }
                        togglePhotoModal();
                      }}
                      handleCancel={togglePhotoModal}
                      successButtonLabel={t('productBrief.attachments.photos.modal.delete')}
                    />
                  )}
                </>
              );
            }}
          </FieldArray>
        </div>
      </div>
      {lightboxOpen && (
        <CustomLightbox
          mainSrc={allPhotos}
          length={allPhotos.length}
          toggle={toggleLightBox}
          initialInternalIndex={lightboxIndex}
        />
      )}
    </>
  );
}
