import { useEffect, useState } from 'react';
import { BriefStore } from 'interfaces/API/products';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import projectActions from 'modules/projects/actions';
import briefActions from 'modules/brief/actions';
import briefSelectors from 'modules/brief/selectors';
import useRequestState, { RequestState } from './useRequestState';

type Status = [RequestState, BriefStore | null, () => void];

const useCurrentBrief = (): Status => {
  const { productId, projectId } = useNumberParams();
  const dispatch = useDispatch();
  const [retry, setRetry] = useState(0);
  const [status, setError, setLoading, setDefault] = useRequestState();

  const handleRetry = (): void => {
    setDefault();
    setRetry(retry + 1);
  };

  const brief = useSelector(briefSelectors.briefById(productId));
  useEffect(() => {
    if (!brief && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        projectActions.getProjectByIdAction(dispatch, projectId);
        const response = await briefActions.getBriefById(dispatch, productId);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, brief, productId, status, setLoading, setDefault, setError, retry]);

  return [status, brief ? brief : null, handleRetry];
};
export default useCurrentBrief;
