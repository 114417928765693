import { AppState } from 'modules/rootReducer';
import { createSelector, Selector } from 'reselect';
import { PhotoBriefStore } from 'interfaces/API/products';

const briefsSelector = (state: AppState): PhotoBriefStore[] => state.photoBrief.photoBriefs;

const briefById = (briefId: number): Selector<AppState, PhotoBriefStore | undefined> =>
  createSelector(briefsSelector, (list) => list.find((item) => item.id === briefId));

const briefRedirectTo = (state: AppState): string | null => state.photoBrief.redirectTo;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  briefById,
  briefsSelector,
  briefRedirectTo,
};
