import * as yup from 'yup';
import ProductBriefSchema from './ProductBrief.schema';
import { t } from 'i18n';
import checkEAN from 'helpers/checkEan';

const OtherBriefSchema = ProductBriefSchema.concat(
  yup.object().shape({
    // MAIN DETAILS
    ean: yup
      .string()
      .test('check-digit', t('productBrief.mainDetails.ean.typeError'), checkEAN)
      .required(t('productBrief.mainDetails.ean.error')),
    
    requireManual: yup.bool(),
    manual: yup
      .string()
      .when('manualRequired', { is: true, then: (m: any) => m.required(t('productBrief.manual.manual.error')) }),

    // PACKAGING MARKINGS
    capabilitiesIcons: yup.string().required(t('productBrief.signs.capabilitiesIcons.error')),
    productIcons: yup.string().required(t('productBrief.signs.productIcons.error')),
    informationSigns: yup.string().required(t('productBrief.signs.informationSigns.error')),
  })
);

export default OtherBriefSchema;
