import { BriefState, BriefActionTypes } from './actions';
import actionTypes from './actionTypes';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { PhotoBriefStore } from 'interfaces/API/products';

const initialState: BriefState = {
  photoBriefs: [],
  redirectTo: null,
};

const photoBriefreducer = createReducer<BriefState, BriefActionTypes>(initialState)
  .handleType(actionTypes.UPDATE_BRIEF, (state, action) =>
    produce(state, (draftState) => {
      const index = state.photoBriefs.findIndex((brief) => brief.id === action.payload.briefId);
      draftState.photoBriefs[index] = {...draftState.photoBriefs[index], id: action.payload.briefId, ...action.payload.response };
    })
  )
  .handleType(actionTypes.SAVE_BRIEF, (state, action) =>
    produce(state, (draftState) => {
      const index = state.photoBriefs.findIndex((brief) => brief.id === action.payload.briefId);
      if (index !== -1) {
        draftState.photoBriefs[index] = {...draftState.photoBriefs[index], id: action.payload.briefId, ...action.payload.response };

      } else {
        const brief: PhotoBriefStore = { ...action.payload.response, id: action.payload.briefId };
        draftState.photoBriefs.push(brief);
      }

    })
  )
  .handleType(actionTypes.DELETE_BRIEF_PHOTO_BRIEF_PHOTO, (state, action) =>
    produce(state, (draftState) => {
      const briefIndex = draftState.photoBriefs.findIndex((item) => item.id === action.payload.briefId);
      if (briefIndex >= 0) {
        const photoIndex = draftState.photoBriefs[briefIndex].fields[action.payload.photoBriefIndex].photos.findIndex((item: any) => item.id === action.payload.photoId);
        draftState.photoBriefs[briefIndex].fields[action.payload.photoBriefIndex].photos.splice(photoIndex, 1);
      }
    })
  )
  .handleType(actionTypes.REDIRECT_TO, (state, action) =>
    produce(state, (draftState) => {
      if (draftState.redirectTo !== action.payload) {
        draftState.redirectTo = action.payload;
      }
    })
  )
  .handleType(actionTypes.ADD_SAMPLE, (state, action) =>
    produce(state, (draftState) => {
      const briefIndex = draftState.photoBriefs.findIndex((item) => item.id === action.payload.product);
      if (briefIndex >= 0) {
        draftState.photoBriefs[briefIndex].sample = action.payload
}
    })
  );

export default photoBriefreducer;
