import React, { useState } from 'react';
import { BriefHeaderWithLength } from 'molecules/BriefHeaderWithLength';
import cx from 'classnames';
import  { ProductBriefNavigationButtonsProps } from 'molecules/ProductBriefNavigationButtons';
import briefActions from 'modules/photoBrief/actions';
import { ManagementProps } from '../PhotoBrief.interfaces';
import { t } from 'i18n';
import PhotoProductBriefNavigationButtons from 'molecules/PhotoProductBriefNavigationButtons';
import { ProductSampleResponse } from 'interfaces/API/sample';

interface ProductBriefHeaderProps {
  cloneProductEnable: boolean;
  productId: number;
  sample?: ProductSampleResponse;
};

export default function ProductBriefHeader({
  cloneProductEnable,
  productId,
  dispatch,
  sample,
  ...rest
}: ProductBriefHeaderProps & ManagementProps & ProductBriefNavigationButtonsProps): React.ReactElement {
  const [isCopying, setCopying] = useState(false);

  const handleCloneProduct = async (previousId: number) => {
    setCopying(true);
    await briefActions.cloneBrief(dispatch, productId, previousId);
    setCopying(false);
  };

  const sampleToDisplay = <span className="display-sample">{`R: ${sample?.rack} S: ${sample?.shelf}`}</span>

  return (
    <div className="field-section">
      <div className="rows">
        <div className="row">
          <BriefHeaderWithLength briefType={t('productBrief.header.title')} />
          {sample && sampleToDisplay}
        </div>
        <div className="row columns">
          <div className="column form__header--left">
            {cloneProductEnable && (
              <button
                className={cx('button is-orange-lighten', { 'is-loading': isCopying })}
                disabled={isCopying}
                onClick={async (): Promise<void> => handleCloneProduct(rest.previousProductId as number)}
                type="button"
              >
                <span>
                  {t('productBrief.header.cloneButton', {
                    currentProductIndex: rest.currentProductIndex,
                    productsLength: rest.productsLength,
                  })}
                </span>
              </button>
            )}
          </div>

          <div className="column items-right">
            <PhotoProductBriefNavigationButtons {...rest} />
          </div>
        </div>
      </div>
    </div>
  );
}
