import React from 'react';
import { withFormik, FormikProps, Form, Field, ErrorMessage } from 'formik';
import { UserIcon, LockIcon } from '../assets/index';
import userApi from 'API/userAuthorization';
import { History, Location } from 'history';
import { getCurrentUser, UserActionTypes } from 'modules/users/actions';
import { Dispatch } from 'redux';
import FieldTextInput from 'molecules/fields/FieldTextInput';
import FieldInputError from 'atoms/FieldInputError';
import cx from 'classnames';
import * as yup from 'yup';
import { parse } from 'query-string';
import { t } from '../i18n';

const LoginSchema = yup.object().shape({
  username: yup.string().required('Podaj nazwę użytkownika.'),
  password: yup.string().required('Podaj hasło.'),
});
interface LoginFormValues {
  username: string;
  password: string;
}
interface LoginManagementProps {
  dispatch: Dispatch<UserActionTypes>;
  history: History;
  location: Location;
}

const LoginForm: React.FC<FormikProps<LoginFormValues>> = (props) => {
  return (
    <Form className="field-section" data-testid="login-form">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column">
            <span className="font-dark">{t('login.login')}</span>
          </div>
          <div className="column items-right">
            <div className="button is-custom-light p-2">{t('login.register')}</div>
          </div>
        </div>

        <div className="row rows">
          <div className="row relative">
            <Field
              name="username"
              component={FieldTextInput}
              placeholder={t('login.form.login')}
              type="text"
              className="brief__input pl-4"
              data-testid="username-field"
            />
            <UserIcon className="login__icon" />
          </div>
          <div className="row">
            <ErrorMessage name="username" component={FieldInputError} />
          </div>
        </div>

        <div className="row rows">
          <div className="row relative">
            <Field
              name="password"
              component={FieldTextInput}
              placeholder={t('login.form.password')}
              type="password"
              className="brief__input pl-4"
              data-testid="password-field"
            />
            <LockIcon className="login__icon" />
          </div>
          <div className="row">
            <ErrorMessage name="password" component={FieldInputError} />
          </div>
        </div>

        <div className="row columns ">
          <div className="column">
            <button
              data-testid="submit-button"
              type="submit"
              className={cx([{ 'is-loading': props.isSubmitting }], 'button is-orange-lighten')}
            >
              <span>{t('login.login')}</span>
            </button>
          </div>
          <div className="column is-narrow has-items-centered">
            <span className="font-small">{t('login.resetPassword')}</span>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default withFormik<LoginManagementProps & Partial<LoginFormValues>, LoginFormValues>({
  // Transform outer props into form values
  enableReinitialize: true,
  validationSchema: LoginSchema,
  mapPropsToValues: ({ history, dispatch, location, ...props }) => {
    return {
      username: '',
      password: '',
      ...props,
    };
  },
  handleSubmit: async ({ username, password }, { props: { history, location, dispatch } }) => {
    const response = await userApi.userAuthorization({ username, password });

    if (response) {
      const query = parse(location.search);
      const redirectUrl = query.next ? (query.next as string) : '/';
      getCurrentUser(dispatch);
      history.push(redirectUrl);
    }
    // do submitting things
  },
})(LoginForm);
