import { actionTypes, PhotoShootPlanActionTypes, PhotoShootPlanState } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DesignStageStore } from 'interfaces/API/draft';

const initialState: PhotoShootPlanState = {
  photoShootPlanList: [],
};

const photoShootPlanReducer = createReducer<PhotoShootPlanState, PhotoShootPlanActionTypes>(initialState)
  .handleType(actionTypes.ADD_PHOTO_SHOOT_PLAN, (state, action) =>
    produce(state, (photoShootPlanState) => {
      const draft: DesignStageStore = { ...action.payload.response, id: action.payload.projectId };
      photoShootPlanState.photoShootPlanList.push(draft);
    })
  )
  .handleType(actionTypes.ADD_PHOTO_SHOOT_PLAN_FILE, (state, { payload: { projectId, response } }) =>
    produce(state, (photoShootPlanState) => {
      const index = photoShootPlanState.photoShootPlanList.findIndex((draft) => draft.id === projectId);
      photoShootPlanState.photoShootPlanList[index].files.unshift(response);
    })
  )
  .handleType(actionTypes.UPDATE_PHOTO_SHOOT_PLAN, (state, { payload: { projectId, response } }) =>
    produce(state, (photoShootPlanState) => {
      const index = photoShootPlanState.photoShootPlanList.findIndex((draft) => draft.id === projectId);
      photoShootPlanState.photoShootPlanList[index] = { ...photoShootPlanState.photoShootPlanList[index], ...response };
    })
  )
  .handleType(actionTypes.UPDATE_PHOTO_SHOOT_PLAN_OPENED_BY, (state, { payload: { projectId, response, fileId } }) =>
    produce(state, (photoShootPlanState) => {
      const index = photoShootPlanState.photoShootPlanList.findIndex((draft) => draft.id === projectId);
      const files = photoShootPlanState.photoShootPlanList[index].files;
      const fileIndex = files.findIndex((file) => file.id === fileId);
      photoShootPlanState.photoShootPlanList[index].files[fileIndex].openedBy = response;
    })
  );

export default photoShootPlanReducer;
