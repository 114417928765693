import photoShootApi from 'API/photoShoots';
import {
  DesignStageFileRequest,
  DesignStageFileResponse,
  DesignStageResponse,
  DesignStageStore,
} from 'interfaces/API/draft';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { ProductActionTypes, getProductByIdAction, updateProductsOnSuccess } from 'modules/products/actions';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { ActionType, createAction } from 'typesafe-actions';

export type PhotoShootState = {
  photoShootList: DesignStageStore[];
};

export enum actionTypes {
  ADD_PHOTO_SHOOT = 'photoShoots/ADD_PHOTO_SHOOT',
  UPDATE_PHOTO_SHOOT = 'photoShoots/UPDATE_PHOTO_SHOOT',
  ADD_PHOTO_SHOOT_FILE = 'photoShoots/ADD_PHOTO_SHOOT_FILE',
  UPDATE_PHOTO_SHOOT_OPENED_BY = 'photoShoots/UPDATE_PHOTO_SHOOT_OPENED_BY',
  UPDATE_PHOTO_SHOOT_FILE = 'photoShoots/UPDATE_PHOTO_SHOOT_FILE',
}

const internalActions = {
  addPhotoShoot: createAction(actionTypes.ADD_PHOTO_SHOOT)<{ productId: number; response: DesignStageResponse }>(),
  updatePhotoShoot: createAction(actionTypes.UPDATE_PHOTO_SHOOT)<{
    productId: number;
    response: DesignStageResponse;
  }>(),
  addPhotoShootFile: createAction(actionTypes.ADD_PHOTO_SHOOT_FILE)<{
    productId: number;
    response: DesignStageFileResponse;
  }>(),
  updateOpenedFile: createAction(actionTypes.UPDATE_PHOTO_SHOOT_OPENED_BY)<{
    productId: number;
    response: number[];
    fileId: number;
  }>(),
  updatePhotoShootFile: createAction(actionTypes.UPDATE_PHOTO_SHOOT_FILE)<{
    productId: number;
    response: DesignStageResponse;
    fileId: number;
  }>(),
};

export type PhotoShootActionTypes = ActionType<typeof internalActions>;
type PhotoShootFile = PhotoShootActionTypes | ProductActionTypes | ActivityLogActionTypes;

export const getPhotoShootByProductId = async (
  productId: number,
  dispatch: Dispatch<PhotoShootActionTypes>
): Promise<boolean> => {
  const response = await photoShootApi.getPhotoShootByProductId(productId);
  if (!response) {
    return false;
  }
  dispatch(internalActions.addPhotoShoot({ productId, response }));
  return true;
};

export const addPhotoShootFile = async (
  dispatch: Dispatch<PhotoShootFile>,
  productId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await photoShootApi.addPhotoShootFile(productId, data);
  if (response) {
    dispatch(internalActions.addPhotoShootFile({ productId, response }));
    const draftResponse = await photoShootApi.getPhotoShootByProductId(productId);
    if (draftResponse) {
      dispatch(internalActions.updatePhotoShoot({ productId, response: draftResponse }));
      await ActivityLogActions.getProductMessages(dispatch, productId);
    }
    toast.success('Dodano Photoshoot image');
  } else {
    toast.error('Nie udało się dodać');
  }
};

export const acceptPhotoShoot = async (dispatch: Dispatch<PhotoShootFile>, productId: number): Promise<void> => {
  const response = await photoShootApi.acceptPhotoShoot(productId);
  if (response) {
    dispatch(internalActions.updatePhotoShoot({ productId, response }));
    await updateProductsOnSuccess(productId, dispatch);
    await getProductByIdAction(dispatch, productId);
    await ActivityLogActions.getProductMessages(dispatch, productId);
    toast.success('Zaakceptowano Photoshoot');
  } else {
    toast.error('Wystąpił błąd podczas akceptowania');
  }
};

export const updatePhotoShootFile = async (dispatch: Dispatch<PhotoShootFile>, productId: number, fileId: number, data: { requireRetouch: boolean }): Promise<void> => {
  const response = await photoShootApi.updatePhotoShootFile(productId, fileId, data);
  if (response) {
    dispatch(internalActions.updatePhotoShootFile({ productId, response, fileId }));
    const draftResponse = await photoShootApi.getPhotoShootByProductId(productId);
    if (draftResponse) {
      dispatch(internalActions.updatePhotoShoot({ productId, response: draftResponse }));
    }
  }
};

export const updateOpenPhotoShootFile = (
  dispatch: Dispatch<PhotoShootActionTypes>,
  productId: number,
  openedBy: number[],
  fileId: number
) => {
  dispatch(internalActions.updateOpenedFile({ productId, response: openedBy, fileId }));
};
