import { BlankingIcon, FileIcon } from 'assets';
import Blanking from 'molecules/Blanking';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { downloadItem, handleFileSelect } from 'helpers/fileHelpers';
import useToggler from 'hooks/useToggler';
import React, { useRef, useState } from 'react';
import FieldInputError from 'atoms/FieldInputError';
import { ManagementProps } from '../../ProductBrief.interfaces';
import briefActions from 'modules/brief/actions';
import { BlankingFormModel } from 'interfaces/API/products';
import { t } from 'i18n';
import ProductBriefModal from '../ProductBriefModal';

export interface ProductBriefBlankingsProps {
  isEditable: boolean;
  productId: number;
  allBlankings: BlankingFormModel[];
}

export default function ProductBriefBlankings({
  isEditable,
  productId,
  allBlankings,
  dispatch,
}: ProductBriefBlankingsProps & ManagementProps): JSX.Element {
  const [isBlankingModalOpen, toggleBlankingModal] = useToggler();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const onFilePickerClick = (): void => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div className="field-section">
      <div className="rows">
        <FieldArray name="blankings">
          {({ push, remove, form: { values } }) => {
            const blankings =
              values.blankings && values.blankings.length > 0
                ? values.blankings.map((blanking: any, index: number) => (
                    <Field
                      key={index}
                      component={Blanking}
                      removeItem={(): void => {
                        toggleBlankingModal();
                        setCurrentIndex(index);
                      }}
                      downloadItem={downloadItem}
                      name={`blankings.${index}`}
                      disabled={!isEditable}
                    />
                  ))
                : null;

            return (
              <>
                <div className="row mb-1 items-spaced">
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="picker__input"
                    accept=".pdf"
                    onChange={(e) => handleFileSelect(e, push)}
                    multiple
                  />

                  <span className="bolder">{t('productBrief.attachments.blankings.title')}:</span>
                  {isEditable && (
                    <button
                      type="button"
                      className="button is-orange-lighten with-icon--left"
                      onClick={onFilePickerClick}
                      data-testid="productBlankingPicker"
                    >
                      <FileIcon />
                      <span>{t('productBrief.attachments.blankings.addLabel')}</span>
                    </button>
                  )}
                </div>

                {blankings}
                <ErrorMessage name="blankings" component={FieldInputError} />

                {isBlankingModalOpen && currentIndex !== null && (
                  <ProductBriefModal
                    isModalOpen={isBlankingModalOpen}
                    setOpen={toggleBlankingModal}
                    isSubmitting={false}
                    header={t('productBrief.attachments.blankings.modal.header')}
                    content={
                      <div className="columns has-items-centered">
                        <div className="column is-narrow has-items-centered">
                          <BlankingIcon />
                        </div>
                        <div className="column">
                          <span className="properities__name">
                            {allBlankings[currentIndex] && allBlankings[currentIndex].filename}
                          </span>
                        </div>
                      </div>
                    }
                    handleSuccess={async (): Promise<void> => {
                      if ('file' in allBlankings[currentIndex]) {
                        remove(currentIndex);
                      } else if ('id' in allBlankings[currentIndex]) {
                        await briefActions.deleteBlanking(dispatch, allBlankings[currentIndex].id as number, productId);
                      }
                      toggleBlankingModal();
                    }}
                    handleCancel={toggleBlankingModal}
                    successButtonLabel={t('productBrief.attachments.blankings.modal.delete')}
                  />
                )}
              </>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
}
