/* eslint-disable import/no-anonymous-default-export */
import API, {PACKAGING_PROJECTS_PREFIX, PHOTO_PROJECTS_PREFIX} from './axiosConfig';
import { ProductResponse, SimpleProductResponse, AnyProductStore } from 'interfaces/API/products';

export enum MessageType {
  USER = 'USER',
  SYSTEM_SUCCESS = 'SYSTEM_SUCCESS',
  SYSTEM_FAILURE = 'SYSTEM_FAILURE',
}

export type ProductActivityLogItem = {
  messageType: MessageType;
  message: string;
  stage: string;
  createdBy: number;
  createdAt: Date;
};

const getProducts = async (): Promise<SimpleProductResponse[] | null> => {
  try {
    const response = await API.get<SimpleProductResponse[]>(`/products/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const getProductById = async (id: number): Promise<ProductResponse | null> => {
  try {
    const response = await API.get<ProductResponse>(`/${PACKAGING_PROJECTS_PREFIX}/products/${id}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const getPhotoProductById = async (id: number): Promise<ProductResponse | null> => {
  try {
    const response = await API.get<ProductResponse>(`/${PHOTO_PROJECTS_PREFIX}/products/${id}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const getProductsByURL = async (url: string): Promise<AnyProductStore[] | null> => {
  try {
    const response = await API.get<AnyProductStore[]>(url);
    return response.data;
  } catch (err) {
    return null;
  }
};

const getProductsByProjectId = async (projectId: number): Promise<AnyProductStore[] | null> => {
  try {
    const response = await API.get<AnyProductStore[]>(`/products/?project=${projectId}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  getProductById,
  getProducts,
  getProductsByURL,
  getProductsByProjectId,
  getPhotoProductById
};
