import { actionTypes, MasterDraftActionTypes, MasterDraftState } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DesignStageStore } from 'interfaces/API/draft';

const initialState: MasterDraftState = {
  masterDraftList: [],
};

const masterDraftReducer = createReducer<MasterDraftState, MasterDraftActionTypes>(initialState)
  .handleType(actionTypes.ADD_MASTER_DRAFT, (state, action) =>
    produce(state, (masterDraftState) => {
      const draft: DesignStageStore = { ...action.payload.response, id: action.payload.projectId };
      masterDraftState.masterDraftList.push(draft);
    })
  )
  .handleType(actionTypes.ADD_MASTER_DRAFT_FILE, (state, { payload: { projectId, response } }) =>
    produce(state, (masterDraftState) => {
      const index = masterDraftState.masterDraftList.findIndex((draft) => draft.id === projectId);
      masterDraftState.masterDraftList[index].files.unshift(response);
    })
  )
  .handleType(actionTypes.UPDATE_MASTER_DRAFT, (state, { payload: { projectId, response } }) =>
    produce(state, (masterDraftState) => {
      const index = masterDraftState.masterDraftList.findIndex((draft) => draft.id === projectId);
      masterDraftState.masterDraftList[index] = { ...masterDraftState.masterDraftList[index], ...response };
    })
  )
  .handleType(actionTypes.UPDATE_MASTER_DRAFT_OPENED_BY, (state, { payload: { projectId, response, fileId } }) =>
    produce(state, (masterDraftState) => {
      const index = masterDraftState.masterDraftList.findIndex((draft) => draft.id === projectId);
      const files = masterDraftState.masterDraftList[index].files;
      const fileIndex = files.findIndex((file) => file.id === fileId);
      masterDraftState.masterDraftList[index].files[fileIndex].openedBy = response;
    })
  );

export default masterDraftReducer;
