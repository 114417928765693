import React from 'react';
import ProjectMembersHeader from 'molecules/projectMembers/ProjectMembersHeader';
import ProjectMembersContent from 'molecules/projectMembers/ProjectMembersContent';
import useToggler from 'hooks/useToggler';
import { useSelector } from 'react-redux';
import projectSelectors from 'modules/projects/selectors';
import useNumberParams from 'hooks/useNumberParams';
import { AppState } from 'modules/rootReducer';

const ProjectMembers: React.FC = () => {
  const [open, toggleOpen] = useToggler(true);
  const { projectId } = useNumberParams();
  const [currentProject, members] = useSelector((state: AppState) => [
    projectSelectors.projectById(projectId)(state),
    projectSelectors.projectMembers(projectId)(state),
  ]);

  return (
    <>
      <ProjectMembersHeader toggleOpen={toggleOpen} open={open} fullSlug={currentProject ? currentProject.fullSlug : '--/--'} />
      {open && <ProjectMembersContent members={members} />}
    </>
  );
};
export default ProjectMembers;
