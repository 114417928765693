import { actionTypes, PhotoShootActionTypes, PhotoShootState } from './actions';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DesignStageStore } from 'interfaces/API/draft';

const initialState: PhotoShootState = {
  photoShootList: [],
};

const photoShootReducer = createReducer<PhotoShootState, PhotoShootActionTypes>(initialState)
  .handleType(actionTypes.ADD_PHOTO_SHOOT, (state, action) =>
    produce(state, (photoShootState) => {
      const draft: DesignStageStore = { ...action.payload.response, id: action.payload.productId };
      photoShootState.photoShootList.push(draft);
    })
  )
  .handleType(actionTypes.ADD_PHOTO_SHOOT_FILE, (state, { payload: { productId, response } }) =>
    produce(state, (photoShootState) => {
      const index = photoShootState.photoShootList.findIndex((draft) => draft.id === productId);
      photoShootState.photoShootList[index].files.unshift(response);
    })
  )
  .handleType(actionTypes.UPDATE_PHOTO_SHOOT, (state, { payload: { productId, response } }) =>
    produce(state, (photoShootState) => {
      const index = photoShootState.photoShootList.findIndex((draft) => draft.id === productId);
      photoShootState.photoShootList[index] = { ...photoShootState.photoShootList[index], ...response };
    })
  )
  .handleType(actionTypes.UPDATE_PHOTO_SHOOT_OPENED_BY, (state, { payload: { productId, response, fileId } }) =>
    produce(state, (photoShootState) => {
      const index = photoShootState.photoShootList.findIndex((draft) => draft.id === productId);
      const files = photoShootState.photoShootList[index].files;
      const fileIndex = files.findIndex((file) => file.id === fileId);
      photoShootState.photoShootList[index].files[fileIndex].openedBy = response;
    })
  );

export default photoShootReducer;
