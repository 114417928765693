import React from 'react';

interface DropdownItemProps {
  text: string;
  icon: JSX.Element;
  onClick: () => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ( {text, icon, onClick} ) => {
  return (
    <a className="navbar-item" onClick={onClick}>
      {icon}
      <span className="ml-1">{text}</span>
    </a>
  );
};

export default DropdownItem;
