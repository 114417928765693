import useNumberParams from './useNumberParams';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import projectSelectors from 'modules/projects/selectors';
import { isFullProject, isSimpleProject } from 'helpers/isSimpleProject';
import { ProjectResponse } from 'interfaces/API/projects';
import projectActions from 'modules/projects/actions';
import { AppState } from 'modules/rootReducer';
import useRequestState, { RequestState } from './useRequestState';

type Status = [RequestState, ProjectResponse | null, () => void];

const useCurrentPhotoProject = (): Status => {
  const { projectId } = useNumberParams();
  const dispatch = useDispatch();
  const [retry, setRetry] = useState(0);

  const [status, setError, setLoading, setDefault] = useRequestState();
  const [project, shouldUpdateProject] = useSelector((state: AppState) => [
    projectSelectors.projectById(projectId)(state),
    projectSelectors.shouldUpdateProjectById(projectId)(state),
  ]);

  const handleRetry = (): void => {
    setDefault();
    setRetry(retry + 1);
  };

  useEffect(() => {
    if (status === RequestState.DEFAULT && project && (isSimpleProject(project) || shouldUpdateProject)) {
      setLoading();
      (async (): Promise<void> => {
        const response = await projectActions.getPhotoProjectByIdAction(dispatch, projectId);

        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, project, projectId, setDefault, setError, setLoading, shouldUpdateProject, status, retry]);
  return [status, project && isFullProject(project) ? project : null, handleRetry];
};

export default useCurrentPhotoProject;
