import React, { useMemo } from 'react';
import useNumberParams from 'hooks/useNumberParams';
import { useSelector } from 'react-redux';
import productSelectors from 'modules/products/selectors';
import BriefHeader from 'atoms/BriefHeader';
import { AppState } from 'modules/rootReducer';

type BriefHeaderProps = {
  briefType: string;
  isProjectLevelFile?: boolean;
};

export const BriefHeaderWithLength: React.FC<BriefHeaderProps> = ({ briefType, isProjectLevelFile }) => {
  const { projectId, productId } = useNumberParams();
  const [product, productIndex, productsLength] = useSelector((state: AppState) => [
    productSelectors.productById(productId)(state),
    productSelectors.projectProductIndex(projectId, productId)(state),
    productSelectors.projectProductsLength(projectId)(state),
  ]);

  const headerText = useMemo(
    () =>
      isProjectLevelFile ? briefType : `${productIndex + 1} / ${productsLength} - ${briefType} - ${product?.displayName}`,
    [isProjectLevelFile, briefType, productIndex, productsLength, product?.displayName]
  );

  return <BriefHeader>{headerText}</BriefHeader>;
};
