import React from 'react';
import StepBar from '../molecules/StepBar';
import StageBar from '../molecules/StageBar';
import { Switch, Route } from 'react-router';
import projectSelectors from 'modules/projects/selectors';
import photoBriefSelectors from 'modules/photoBrief/selectors';
import briefSelectors from 'modules/brief/selectors';
import StoreProvider from 'components/StoreProvider';
import DraftList from './DraftList';
import FirstFinalList from './FirstFinalList';
import PrintingFilesList from './PrintingFilesList';
import ProjectBrief from './ProjectBrief/ProjectBrief';
import ProductBrief from './ProductBrief';
import PhotoBrief from './PhotoBrief/PhotoBrief';
import MasterDraft from './MasterDraft';
import PhotoShootPlan from './PhotoShootPlan';
import CallSheet from './CallSheet';
import { useDispatch } from 'react-redux';
import ProjectPhotoBrief from './ProjectPhoto/ProjectPhoto';
import PhotoShoot from './PhotoShoot';
import PhotoRetouch from './PhotoRetouch';
// https://forbeslindesay.github.io/express-route-tester/


const Dashboard: React.FC = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="brief-container">
      <Route
        component={StepBar}
        exact
        path={[
          '/projects/:id',
          '/projects/:id/products/:pid',
          '/projects/:id/products/:pid/master-draft',
          '/projects/:id/products/:pid/draft',
          '/projects/:id/products/:pid/ff',
          '/projects/:id/products/:pid/pf',
          '/project-photo/:id/',
          '/project-photo/:id/products/:pid',
          '/project-photo/:id/products/:pid/photo-shoot-plan',
          '/project-photo/:id/products/:pid/call-sheet',
          '/project-photo/:id/products/:pid/photo-shoot',
          '/project-photo/:id/products/:pid/photo-retouch',
        ]}
      />

      <div className="brief">
        <Route
          component={StageBar}
          exact
          path={[
            '/projects/new',
            '/projects/:id',
            '/projects/:id/products/:pid',
            '/projects/:id/products/:pid/master-draft',
            '/projects/:id/products/:pid/draft',
            '/projects/:id/products/:pid/ff',
            '/projects/:id/products/:pid/pf',
            '/project-photo/new',
            '/project-photo/:id/',
            '/project-photo/:id/products/:pid',
            '/project-photo/:id/products/:pid/photo-shoot-plan',
            '/project-photo/:id/products/:pid/call-sheet',
            '/project-photo/:id/products/:pid/photo-shoot',
            '/project-photo/:id/products/:pid/photo-retouch',
          ]}
        />

        <div className="brief__form">
          <Switch>
            <Route
              component={(props: any) => {
                const { id } = props.match.params;
                return (
                  <StoreProvider
                    selectors={{
                      currentProject: projectSelectors.projectById(Number(id)),
                    }}
                    component={(componentProps) => {
                      return <ProjectBrief {...props} {...componentProps} dispatch={dispatch} />;
                    }}
                  />
                );
              }}
              exact
              path={['/projects/new', '/projects/:id']}
            />
            <Route
              component={(props: any) => {
                const { id, pid } = props.match.params;

                return (
                  <StoreProvider
                    selectors={{
                      currentBrief: briefSelectors.briefById(Number(pid)),
                      projectCategory: projectSelectors.projectCategory(Number(id)),
                    }}
                    component={(componentProps) => {
                      return <ProductBrief {...props} {...componentProps} dispatch={dispatch} />;
                    }}
                  />
                );
              }}
              exact
              path="/projects/:id/products/:pid"
            />
            <Route exact path="/projects/:id/products/:pid/master-draft">
              <MasterDraft />
            </Route>
            <Route exact path="/projects/:id/products/:pid/draft">
              <DraftList />
            </Route>
            <Route exact path="/projects/:id/products/:pid/ff">
              <FirstFinalList />
            </Route>
            <Route exact path="/projects/:id/products/:pid/pf">
              <PrintingFilesList />
            </Route>

            <Route
              component={(props: any) => {
                const { id } = props.match.params;
                return (
                  <StoreProvider
                    selectors={{
                      currentProject: projectSelectors.projectById(Number(id)),
                    }}
                    component={(componentProps) => {
                      return <ProjectPhotoBrief {...props} {...componentProps} dispatch={dispatch} />;
                    }}
                  />
                );
              }}
              exact
              path={['/project-photo/new', '/project-photo/:id']}
            />
            <Route
              component={(props: any) => {
                const { id, pid } = props.match.params;

                return (
                  <StoreProvider
                    selectors={{
                      currentBrief: photoBriefSelectors.briefById(Number(pid)),
                      projectCategory: projectSelectors.projectCategory(Number(id)),
                    }}
                    component={(componentProps) => {
                      return <PhotoBrief {...props} {...componentProps} dispatch={dispatch} />;
                    }}
                  />
                );
              }}
              exact
              path="/project-photo/:id/products/:pid"
            />

            <Route exact path="/project-photo/:id/products/:pid/photo-shoot-plan">
              <PhotoShootPlan />
            </Route>
            <Route exact path="/project-photo/:id/products/:pid/call-sheet">
              <CallSheet />
            </Route>
            <Route exact path="/project-photo/:id/products/:pid/photo-shoot">
              <PhotoShoot />
            </Route>
            <Route exact path="/project-photo/:id/products/:pid/photo-retouch">
              <PhotoRetouch />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
