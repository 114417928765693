import BriefHeader from 'atoms/BriefHeader';
import ButtonCheckbox from 'atoms/ButtonCheckbox';
import FieldInputError from 'atoms/FieldInputError';
import HorizontalLine from 'atoms/HorizontalLine';
import { ArrayHelpers, ErrorMessage, Field, FieldArray, FormikProps } from 'formik';
import { AdditionalDescriptionModel, DescriptionLanguage } from 'interfaces/API/products';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import React, { Dispatch, SetStateAction } from 'react';
import { TextilesBriefFormValues } from '../ProductBrief.interfaces';
import AddCircleButton from 'molecules/AddCircleButton';
import translateWithContext from 'helpers/translateWithContext';
import { t } from 'i18n';

interface ProductBriefDescriptionProps {
  isEditable: boolean;
}

export default function ProductBriefDescription({ isEditable }: ProductBriefDescriptionProps): JSX.Element {
  const [newLanguage, setNewLanguage] = React.useState<string>('');
  const [newLanguageError, setNewLanguageError] = React.useState<boolean>(false);
  const [newAdditionalDescription, setNewAdditionalDescription] = React.useState<string>('');
  const [newAdditionalDescriptionError, setNewAdditionalDescriptionError] = React.useState<boolean>(false);

  const addNewLanguage = (push: (obj: DescriptionLanguage) => void) => {
    if (newLanguageError) return;
    push({ name: newLanguage, isRequired: true });
    setNewLanguage('');
  };

  const addNewAdditionalDescription = (push: (obj: AdditionalDescriptionModel) => void) => {
    if (newAdditionalDescriptionError) return;
    push({ name: newAdditionalDescription, value: '', isRequired: true });
    setNewAdditionalDescription('');
  };

  const handleNewFieldError = (
    itemsList: DescriptionLanguage[] | AdditionalDescriptionModel[],
    newItemValue: string,
    setError: Dispatch<SetStateAction<boolean>>
  ) => {
    if (itemsList.find((item) => item.name === newItemValue) || !newItemValue) {
      setError(true);
      return;
    }
    setError(false);
  };

  return (
    <div className="field-section">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>{t('productBrief.description.title')}</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine />
          </div>
        </div>
        <div className="rows">
          <div className="rows columns">
            <span className="bolder">{t('productBrief.description.languages.title')}:</span>
          </div>
          <div className="row wrap">
            <FieldArray name="descriptionLanguages">
              {({
                replace,
                push,
                remove,
                form: {
                  values: { descriptionLanguages },
                },
              }: ArrayHelpers & { form: FormikProps<TextilesBriefFormValues> }) => {
                if (!descriptionLanguages.length)
                  return (
                    <AddCircleButton
                      value={newLanguage}
                      placeholder={t('productBrief.description.languages.addLabel')}
                      isEditable={isEditable}
                      handleDataOnClick={() => addNewLanguage(push)}
                      handleChange={setNewLanguage}
                      isError={newLanguageError}
                      setIsError={() => handleNewFieldError(descriptionLanguages, newLanguage, setNewLanguageError)}
                    />
                  );

                return descriptionLanguages.map((language: DescriptionLanguage, currentIndex: number) => {
                  return (
                    <React.Fragment key={`additionalLanguage-${language.name}`}>
                      <div className="column is-narrow">
                        <ButtonCheckbox
                          disabled={!isEditable}
                          name={`additionalLanguage-name-${language.name}`}
                          checked={language.isRequired}
                          onChange={() => replace(currentIndex, { ...language, isRequired: !language.isRequired })}
                          isDeleteButtonVisible={isEditable}
                          handleDeleteClick={() => remove(currentIndex)}
                        >
                          {translateWithContext('productBrief.description.languages.default.lng', language.name)}
                        </ButtonCheckbox>
                      </div>
                      {currentIndex === descriptionLanguages.length - 1 && isEditable && (
                        <AddCircleButton
                          value={newLanguage}
                          placeholder={t('productBrief.description.languages.addLabel')}
                          isEditable={isEditable}
                          handleDataOnClick={() => addNewLanguage(push)}
                          handleChange={setNewLanguage}
                          isError={newLanguageError}
                          setIsError={() => handleNewFieldError(descriptionLanguages, newLanguage, setNewLanguageError)}
                        />
                      )}
                    </React.Fragment>
                  );
                });
              }}
            </FieldArray>
          </div>
        </div>

        <div className="row rows">
          <div className="row">
            <Field
              name="mainDescription"
              component={FieldTextArea}
              placeholder={t('productBrief.description.mainDescription.placeholder')}
              rows="5"
              className="form__input--textarea"
              disabled={!isEditable}
            />
          </div>
          <div className="row">
            <ErrorMessage name="mainDescription" component={FieldInputError} />
          </div>
        </div>
        <div className="row rows">
          <div className="row">
            <Field
              name="extraDescription"
              component={FieldTextArea}
              placeholder={t('productBrief.description.additionalDescription.placeholder')}
              rows="5"
              className="form__input--textarea"
              disabled={!isEditable}
            />
          </div>
          <div className="row">
            <ErrorMessage name="extraDescription" component={FieldInputError} />
          </div>
        </div>

        <div className="row rows">
          <div className="row">
            <span className="bolder">{t('productBrief.description.additionalDescriptionElements.title')}</span>
          </div>
          <div className="row wrap">
            <FieldArray name="additionalDescriptionElements">
              {({
                replace,
                push,
                remove,
                form: {
                  values: { additionalDescriptionElements },
                },
              }: ArrayHelpers & { form: FormikProps<TextilesBriefFormValues> }) => {
                if (!additionalDescriptionElements.length)
                  return (
                    <AddCircleButton
                      value={newAdditionalDescription}
                      placeholder={t('productBrief.description.additionalDescriptionElements.addLabel')}
                      isEditable={isEditable}
                      handleDataOnClick={() => addNewAdditionalDescription(push)}
                      handleChange={setNewAdditionalDescription}
                      isError={newAdditionalDescriptionError}
                      setIsError={() =>
                        handleNewFieldError(
                          additionalDescriptionElements,
                          newAdditionalDescription,
                          setNewAdditionalDescriptionError
                        )
                      }
                    />
                  );

                return additionalDescriptionElements.map(
                  (additionalDescription: AdditionalDescriptionModel, currentIndex: number) => {
                    return (
                      <React.Fragment key={`additionalDescription-${additionalDescription.name}`}>
                        <div>
                          <ButtonCheckbox
                            disabled={!isEditable}
                            name={`additionalDescription-name-${additionalDescription.name}`}
                            checked={additionalDescription.isRequired}
                            onChange={() => {
                              replace(currentIndex, {
                                ...additionalDescription,
                                value: !additionalDescription.isRequired ? '' : additionalDescription.value,
                                isRequired: !additionalDescription.isRequired,
                              });
                            }}
                            isDeleteButtonVisible={isEditable}
                            handleDeleteClick={() => remove(currentIndex)}
                          >
                            {translateWithContext(
                              'productBrief.description.additionalDescriptionElements.default.desc',
                              additionalDescription.name
                            )}
                          </ButtonCheckbox>
                        </div>
                        {currentIndex === additionalDescriptionElements.length - 1 && isEditable && (
                          <AddCircleButton
                            value={newAdditionalDescription}
                            placeholder={t('productBrief.description.additionalDescriptionElements.addLabel')}
                            isEditable={isEditable}
                            handleDataOnClick={() => addNewAdditionalDescription(push)}
                            handleChange={setNewAdditionalDescription}
                            isError={newAdditionalDescriptionError}
                            setIsError={() =>
                              handleNewFieldError(
                                additionalDescriptionElements,
                                newAdditionalDescription,
                                setNewAdditionalDescriptionError
                              )
                            }
                          />
                        )}
                      </React.Fragment>
                    );
                  }
                );
              }}
            </FieldArray>
          </div>
        </div>
        <FieldArray name="additionalDescriptionElements">
          {({
            replace,
            form: {
              values: { additionalDescriptionElements },
            },
          }: ArrayHelpers & { form: FormikProps<TextilesBriefFormValues> }) => {
            return additionalDescriptionElements.map((additionalDescription: AdditionalDescriptionModel) => {
              const index = additionalDescriptionElements.findIndex(
                (desc) => desc.name === additionalDescription.name && desc.value === additionalDescription.value
              );
              return (
                additionalDescription.isRequired && (
                  <div className="row rows" key={`additionalDescription-value-${additionalDescription.name}`}>
                    <div className="row">
                      <Field
                        name={additionalDescription.name}
                        component={FieldTextArea}
                        placeholder={
                          translateWithContext(
                            'productBrief.description.additionalDescriptionElements.default.desc',
                            additionalDescription.name
                          )[0].toUpperCase() +
                          translateWithContext(
                            'productBrief.description.additionalDescriptionElements.default.desc',
                            additionalDescription.name
                          ).slice(1)
                        }
                        className="form__input--textarea"
                        disabled={!isEditable}
                        value={additionalDescription.value}
                        onChange={(e: any) =>
                          replace(index, { ...additionalDescription, value: e?.target?.value || '' })
                        }
                      />
                    </div>
                    {!additionalDescription.value && (
                      <div className="row">
                        <FieldInputError>
                          {t('productBrief.description.additionalDescriptionElements.error')}{' '}
                          {translateWithContext(
                            'productBrief.description.additionalDescriptionElements.default.desc',
                            additionalDescription.name
                          )[0].toLowerCase() +
                            translateWithContext(
                              'productBrief.description.additionalDescriptionElements.default.desc',
                              additionalDescription.name
                            ).slice(1)}
                        </FieldInputError>
                      </div>
                    )}
                  </div>
                )
              );
            });
          }}
        </FieldArray>
      </div>
    </div>
  );
}
