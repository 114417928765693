import * as yup from 'yup';
import ProductBriefSchema from './ProductBrief.schema';
import { t } from 'i18n';
import checkEAN from 'helpers/checkEan';

const sizeSchema = yup.object().shape({
  name: yup.string().required(t('productBrief.mainDetails.sizes.fields.name.error')),
  ean: yup
    .string()
    .test('check-digit', t('productBrief.mainDetails.sizes.fields.ean.typeError'), checkEAN)
    .required(t('productBrief.mainDetails.sizes.fields.ean.error')),
});

const TextilesBriefSchema = ProductBriefSchema.concat(
  yup.object().shape({
    // MAIN DETAILS
    sizes: yup.array().of(sizeSchema),

    // PACKAGING MARKINGS
    additionalMarkings: yup.string().required(t('productBrief.signs.additionalMarkings.error')),
  })
);

export default TextilesBriefSchema;
