import axios, { AxiosError } from 'axios';
import { history } from '../App';
import { toast } from 'react-toastify';
import store from 'store/store';
import { setProgress, clearProgress } from 'modules/progress/actions';


export const PACKAGING_PROJECTS_PREFIX = "packaging-projects"
export const PHOTO_PROJECTS_PREFIX = "photo-projects"
export const TOKEN = 'outbo-token-key';
let baseURL = process.env.REACT_APP_BACKEND_URL;

if (!baseURL) {
  // throw new Error('API URL not set')
  // baseURL = 'https://api.outbo.pl/';
  baseURL = 'https://apistage.outbo.pl';
}
const api = axios.create({
  baseURL,
  timeout: 60000,
  validateStatus: (status) => status >= 200 && status < 300,
});

api.defaults.transformResponse = [
  function transformResponse(data) {
    /*eslint no-param-reassign:0*/
    const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}.\d{3,6})[+-]\d{2}:\d{2}/;
    const reviver = (key: string, value: any) => {
      if (typeof value === 'string' && ISO_8601.test(value)) {
        return new Date(value);
      }
      return value;
    };
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data, reviver);
      } catch (e) {
        /* Ignore */
      }
    }
    return data;
  },
];

api.interceptors.request.use(
  (config) => {
    if (localStorage.getItem(TOKEN)) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem(TOKEN)}`;
    }
    return config;
  },
  async (error) => Promise.reject(error)
);

api.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    store.dispatch(setProgress(0));
    return config;
  },
  async (error: AxiosError) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,

  async (error: AxiosError) => {
    if (error.response && [401].includes(error.response.status)) {
      toast.error('Zaloguj się ponownie.');
      localStorage.removeItem(TOKEN);
      history.push('/login');
    }
    return Promise.reject(error);
  }
);

//PROGRSS BAR
const calculatePercentage = (loaded: any, total: any) => Math.floor(loaded * 1.0) / total;

api.defaults.onUploadProgress = (e) => {
  const percentage = calculatePercentage(e.loaded, e.total);
  store.dispatch(setProgress(percentage));
};
api.interceptors.response.use(
  (response) => {
    store.dispatch(clearProgress());
    return response;
  },
  async (error: AxiosError) => {
    store.dispatch(clearProgress());
    return Promise.reject(error);
  }
);

function retryFailedRequest(err: any) {
  if (err.status === 500 && err.config && !err.config.__isRetryRequest) {
    err.config.__isRetryRequest = true;
    return axios(err.config);
  }
  throw err;
}
axios.interceptors.response.use(undefined, retryFailedRequest);

// axios.interceptors.response.use(null, (error) => {
//   if (error.config && error.response && error.response.status === 401) {
//     return updateToken().then((token) => {
//       error.config.headers.xxxx <= set the token
//       return axios.request(error.onfig);
//     });
//   }

//   return Promise.reject(error);
// });

// axiosRetry(api, { retries: 3 });

export default api;
