import { DownloadIcon } from 'assets';
import { downloadByURL } from 'helpers/fileHelpers';
import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import briefApi from 'API/briefs';
import { BriefStore } from 'interfaces/API/products';
import { t } from 'i18n';

interface ProductBriefDownloadProps {
  currentBrief: BriefStore | null;
}

export default function ProductBriefDownload({ currentBrief }: ProductBriefDownloadProps): React.ReactElement {
  const [pdfLoading, setPDFLoading] = useState(false);

  const generatePDF = async (): Promise<void> => {
    setPDFLoading(true);
    const url = await briefApi.exportBriefToPdf(
      currentBrief!.id,
      `BRIEF-${currentBrief?.modelNo}-${currentBrief?.name}.pdf`
    );
    if (url) {
      downloadByURL(url, 'test.pdf');
    }
    setPDFLoading(false);
  };

  return (
    <div className="field-section">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column">
            <button
              type="submit"
              onClick={generatePDF}
              className="button is-orange-lighten with-icon with-icon--right"
              disabled={pdfLoading}
            >
              {pdfLoading ? <ClipLoader color="#fff" size={2} sizeUnit="rem" /> : t('productBrief.downloadPDFButton')}
              <DownloadIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
