import React, { useState, useEffect } from 'react';
import LeftSidebarHeader from 'molecules/LeftSidebarHeader';
import { useSelector } from 'react-redux';
import Project from 'molecules/SidebarProject';
import { useHistory, useParams } from 'react-router';
import SearchBar from 'molecules/SearchBar';
import { AnyProductStore } from 'interfaces/API/products';
import projectSelectors from 'modules/projects/selectors';
import productSelectors from 'modules/products/selectors';
import Routes from '../helpers/routes';
import { AppState } from 'modules/rootReducer';
import { ClipLoader } from 'react-spinners';
import { OrderByEnum } from 'interfaces/API/projects';
import debounce from 'lodash.debounce';
import useLocationParams from 'hooks/useLocationParams';

interface LeftSidebarInterface {
  isLoading: boolean;
};

const LeftSidebar: React.FC<LeftSidebarInterface> = ({ isLoading }) => {
  const [selectedProject, setSelectedProject] = useState<any | null>(null);
  const projects = useSelector(projectSelectors.projectListWithProducts);
  const products = useSelector(productSelectors.productsSelector);
  const [projectsList, setProjectsList] = useState<any[] | null>(projects);
  const orderBy = useSelector((state: AppState) => state.projects.orderBy);

  const { id: projectId, pid: productId } = useParams<{ id: string; pid: string }>();

  const history = useHistory();
  const [params, location] = useLocationParams();

  const createNewProject = (): void => {
    history.push({ pathname: '/projects/new', search: params.toString() });
  };

  const createNewProjectPhoto = (): void => {
    history.push({ pathname: Routes.PROJECT_PHOTO_NEW, search: params.toString() });
  }

  const orderListBy = async (orderBy: OrderByEnum) => {
    params.set('orderBy', orderBy);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const searchFunc = debounce(async (searchValue: string) => {
    params.set('searchValue', searchValue);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, 300);

  useEffect(() => {
    if (projectId) {
      let findSelected = projects.find((project) => project.id === +projectId);
      if (findSelected && findSelected?.packagingProject) {
        findSelected = projects.find((project) => project.id === findSelected?.packagingProject)
      }

      const index = projects.findIndex((project) => project.id === findSelected?.id);
      setSelectedProject(findSelected || selectedProject);
      findSelected && projects.splice(index, 1);
      setProjectsList(projects);
    } else {
      setSelectedProject(null);
      setProjectsList(projects);
    }
  }, [projectId, productId, orderBy, projects, selectedProject]);

  return (
    <>
      <SearchBar searchFunc={searchFunc} />
      <LeftSidebarHeader
        orderBy={orderBy}
        createNewProject={createNewProject}
        createNewProjectPhoto={createNewProjectPhoto}
        orderListBy={orderListBy}
        loading={isLoading}
      />
      {selectedProject && (
        <div className="selected-project-container" data-testid="leftSidebarProjects">
          <Project key={selectedProject.id} id={selectedProject.id}>
            <Project.Slug
              isActive={Number(projectId) === selectedProject.id}
              productCount={selectedProject.products.length}
            >
              {selectedProject.fullSlug}
            </Project.Slug>
            <Project.Name>{selectedProject.name}</Project.Name>
            <Project.ProductList>
              {selectedProject.products &&
                selectedProject.products.map((product: AnyProductStore) => {
                  const photoProduct = products.find(p => p.packagingProduct === product.id)

                  return (
                    <div key={product.id}>
                      <Project.Product
                        key={product.id}
                        projectId={selectedProject.id}
                        productId={product.id}
                        displayName={product.displayName}
                        isActive={Number(productId) === product.id}
                      />
                      {photoProduct &&
                        <div className='ml-3 nested'>
                          <Project.Product
                            key={photoProduct.id}
                            projectId={photoProduct.project}
                            productId={photoProduct.id}
                            displayName={photoProduct.displayName}
                            isActive={Number(productId) === photoProduct.id}
                          />
                        </div>
                      }
                    </div>
                  )
                })
              }
            </Project.ProductList>
          </Project>
        </div>
      )}
      {!isLoading ? (
        <div className="full-height-column" data-testid="leftSidebarProjects">
          {projectsList &&
            projectsList.filter(p => !p.packagingProject).filter(p => !selectedProject || p.id !== selectedProject.id).map((project) => {
              return (
                <Project key={project.id} id={project.id}>
                  <Project.Slug isActive={Number(projectId) === project.id} productCount={project.products.length}>
                    {project.fullSlug}
                  </Project.Slug>
                  <Project.Name>{project.name}</Project.Name>
                  <Project.ProductList>
                    {project.products &&
                      project.products.map((product: AnyProductStore) => {
                        const photoProduct = products.find(p => p.packagingProduct === product.id)

                        return (
                          <div key={product.id}>
                            <Project.Product
                              key={product.id}
                              projectId={project.id}
                              productId={product.id}
                              displayName={product.displayName}
                              isActive={Number(productId) === product.id}
                            />
                            {photoProduct &&
                              <div className='ml-3 nested'>
                                <Project.Product
                                  key={photoProduct.id}
                                  projectId={photoProduct.project}
                                  productId={photoProduct.id}
                                  displayName={photoProduct.displayName}
                                  isActive={Number(productId) === photoProduct.id}
                                />
                              </div>
                            }
                          </div>
                        )
                      })
                    }
                  </Project.ProductList>
                </Project>
              );
            })}
        </div>
      ) : (
        <div className="hero" style={{ backgroundColor: '#fff' }}>
          <div className="container columns has-items-centered">
            <div className="column">
              <ClipLoader color="#f58c39" size={2} sizeUnit="rem" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeftSidebar;
