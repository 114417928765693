import React, { useRef } from 'react';
import { withFormik, Form, FormikProps, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Dispatch } from 'redux';
import Modal from './Modal';
import { FileIcon } from 'assets';
import FieldInputError from 'atoms/FieldInputError';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import cx from 'classnames';
import { DraftActionTypes } from 'modules/draft/actions';
import { DesignStageFileRequest } from 'interfaces/API/draft';
import { PrintingFilesActionTypes } from 'modules/printingFiles/actions';
import { FirstFinalActionTypes } from 'modules/firstFinal/actions';
import { ProductActionTypes } from 'modules/products/actions';
import { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { MasterDraftActionTypes } from 'modules/masterDraft/actions';
import { PhotoShootPlanActionTypes } from 'modules/photoShootPlan/actions';
import { CallSheetActionTypes } from 'modules/callSheet/actions';
import { PhotoShootActionTypes } from 'modules/photoShoot/actions';
const ModalFileFormSchema = yup.object().shape({
  file: yup.mixed().required('Plik jest wymagany.'),
  description: yup.string().required(),
});

type FileFormValues = {
  file?: File;
  description: string;
};
type DispatchActionTypes =
  | PhotoShootActionTypes

type FormManagement = {
  projectId: number;
  productId: number;
  dispatch: Dispatch;
  headerText: string;
  addMethod: (dispatch: Dispatch, id: number, data: DesignStageFileRequest) => Promise<void>;
  open: boolean;
  setOpen: () => void;
  isProjectLevelFile?: boolean;
};

const ImagesFormModal: React.FC<FormManagement & FormikProps<FileFormValues>> = ({
  open,
  setOpen,
  headerText,
  setFieldValue,
  ...props
}) => {
  const draftFileInput = useRef<HTMLInputElement>(null);

  const onFilePickerClick = (): void => {
    if (draftFileInput && draftFileInput.current) {
      draftFileInput.current.click();
    }
  };

  const handleFileSelect = (e: any): void => {
    const { files } = e.target;
    if (files) {
      setFieldValue('file', files[0]);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Form>
        <Modal.Root>
          <Modal.Header>{headerText}</Modal.Header>
          <Modal.Content>
            <input
              type="file"
              ref={draftFileInput}
              className="picker__input"
              accept=".tif, .tiff"
              onChange={(e): void => handleFileSelect(e)}
            />
            {!props.values.file ? (
              <div className="button is-orange-lighten with-icon--left" onClick={onFilePickerClick}>
                <FileIcon />
                <span>Dodaj Plik</span>
              </div>
            ) : (
              <div className="file__name-background">
                <span>{props.values.file ? props.values.file.name : null}</span>
              </div>
            )}
          </Modal.Content>
          <Modal.Content>
            <div className="row rows">
              <div className="row">
                <Field name="description" component={FieldTextArea} placeholder="Opis do pliku" />
              </div>
              <div className="row">
                <ErrorMessage name="description" component={FieldInputError} />
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer>
            <button type="submit" className={cx({ 'is-loading': props.isSubmitting }, 'button is-orange-lighten mr-1')}>
              <span>zapisz</span>
            </button>
            <button type="button" className="button is-light-grey" onClick={setOpen}>
              <span>Anuluj</span>
            </button>
          </Modal.Footer>
        </Modal.Root>
      </Form>
    </Modal>
  );
};
export default withFormik<FormManagement & Partial<FileFormValues>, FileFormValues>({
  enableReinitialize: true,
  validationSchema: ModalFileFormSchema,
  mapPropsToValues: ({ ...props }) => {
    return {
      description: '',
    };
  },
  handleSubmit: (
    values,
    { props: { dispatch, setOpen, projectId, productId, addMethod, isProjectLevelFile }, resetForm }
  ) => {
    addMethod(dispatch, isProjectLevelFile ? projectId : productId, values as DesignStageFileRequest);
    setOpen();
    resetForm();
  },
})(ImagesFormModal);
