import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import useNumberParams from 'hooks/useNumberParams';

import { t } from '../i18n';
import { FilePreviewSummary } from '../molecules/ProjectBriefFiles';
import projectSelectors from '../modules/projects/selectors';
import { useHistory } from 'react-router';
import useLocationParams from '../hooks/useLocationParams';
import { AttachmentIcon, LinkIcon } from '../assets';

const ProjectFilesSummary: React.FC = () => {
  const { projectId } = useNumberParams();
  const files = useSelector(projectSelectors.projectFiles(projectId));
  const history = useHistory();

  const [params] = useLocationParams();

  const handleProjectClick = useCallback(() => {
    history.push({ pathname: `/projects/${projectId}`, search: params.toString() });
  }, [history, projectId, params]);

  return (
    <>
      <div className="file-list-header">
        <span className="file-list-header__title">
          <AttachmentIcon className="files-header__icon" />
          {t('projectBrief.projectFiles.header')}
        </span>

        <button className="file-list-header__link-icon" onClick={handleProjectClick}>
          <LinkIcon />
        </button>
      </div>
      <div className="file-list-content">
        <ul>
          {files.map((file) => (
            <li key={file.id}>
              <FilePreviewSummary file={file} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ProjectFilesSummary;
