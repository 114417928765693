import React from 'react';
import ProductBriefBlankings, { ProductBriefBlankingsProps } from './fields/ProductBriefBlankings';
import { ManagementProps } from '../ProductBrief.interfaces';
import ProductBriefPhotos, { ProductBriefPhotosProps } from './fields/ProductBriefPhotos';

export default function ProductBriefAttachments({
  allPhotos,
  allBlankings,
  ...props
}: ProductBriefBlankingsProps & ProductBriefPhotosProps & ManagementProps): JSX.Element {
  return (
    <>
      <ProductBriefPhotos {...props} allPhotos={allPhotos} />

      <ProductBriefBlankings {...props} allBlankings={allBlankings} />
    </>
  );
}
