import React from 'react';
import ActivityLog from './ActivityLog';
import ProjectMembers from './ProjectMembers';
import ProjectFilesSummary from './ProjectFilesSummary';

const RightSideBar: React.FC = () => {
  return (
    <>
      <ActivityLog />
      <ProjectFilesSummary />
      <ProjectMembers />
    </>
  );
};

export default RightSideBar;
