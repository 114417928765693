import React from 'react';
import { FieldProps } from 'formik';
import { DropdownItem } from 'API/projectData';
import Dropdown, { CurrentValue } from 'atoms/Dropdown';

type DropdownFieldProps = {
  items: DropdownItem[];
  placeholder: string;
};

const DropdownField: React.FC<
  FieldProps & {
    fullwidth: boolean;
    disabled: boolean;
    dataTestid?: string;
    valueDataTestid?: string;
  } & DropdownFieldProps
> = ({ field, form, items, placeholder, valueDataTestid, ...props }) => {
  const onChange = (value: number): void => {
    form.setFieldValue(field.name, value);
  };
  const currentItem = items.find((item) => item.id === field.value);
  const current: CurrentValue | undefined = currentItem &&
    field.value && {
      value: field.value,
      label: currentItem.name,
    };

  return (
    <Dropdown onChange={onChange} {...props} initial={current} title={current ? placeholder : undefined}>
      <Dropdown.Label valueDataTestid={valueDataTestid}>{placeholder}</Dropdown.Label>
      <Dropdown.Menu>
        {items.length > 0 && items.map((item) => <Dropdown.Item key={item.id} label={item.name} value={item.id} />)}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownField;
