import BriefHeader from 'atoms/BriefHeader';
import FieldInputError from 'atoms/FieldInputError';
import HorizontalLine from 'atoms/HorizontalLine';
import { ErrorMessage, Field } from 'formik';
import { t } from 'i18n';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import React from 'react';

interface ProductBriefIllustrationProps {
  isEditable: boolean;
}

export default function ProductBriefIllustration({ isEditable }: ProductBriefIllustrationProps): JSX.Element {
  return (
    <div className="field-section">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>{t('productBrief.illustration.title')}</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine />
          </div>
        </div>
        <div className="row rows">
          <div className="row">
            <Field
              name="illustration"
              component={FieldTextArea}
              placeholder={t('productBrief.illustration.illustration.placeholder')}
              className="form__input--textarea"
              disabled={!isEditable}
            />
          </div>
          <div className="row">
            <ErrorMessage name="illustration" component={FieldInputError} />
          </div>
        </div>
      </div>
    </div>
  );
}
