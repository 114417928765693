import API, { PACKAGING_PROJECTS_PREFIX, PHOTO_PROJECTS_PREFIX } from './axiosConfig';
import { ProductStage, ProductStageGroup } from 'interfaces/API/projects';

export interface ProductStageResponse {
  id: number;
  name: ProductStage;
  label: string;
  group: ProductStageGroup;
  description: string;
}

export interface DropdownItem {
  id: number;
  name: string;
}

export type PackagingType = {
  value: number;
  label: string;
};

export type Brand = {
  id: number;
  name: string;
};

export enum BriefType {
  other = 'other',
  textiles = 'textiles',
}

export type Category = {
  id: number;
  name: string;
  briefType: BriefType;
};

const fetchCategories = async (): Promise<Category[]> => {
  try {
    const response = await API.get<Category[]>('/categories/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const fetchCategoryById = async (id: number | null): Promise<Category | null> => {
  try {
    const response = await API.get<Category>(`/categories/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const fetchBrands = async (): Promise<Brand[]> => {
  try {
    const response = await API.get<Brand[]>('/brands/');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const fetchPackagingTypes = async (): Promise<PackagingType[]> => {
  try {
    const response = await API.get<PackagingType[]>(`/${PACKAGING_PROJECTS_PREFIX}/packaging-types/`);
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const fetchProductStages = async (): Promise<ProductStageResponse[]> => {
  try {
    const response = await API.get<ProductStageResponse[]>(`/${PACKAGING_PROJECTS_PREFIX}/stages/`);
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const fetchProductPhotoStages = async (): Promise<ProductStageResponse[]> => {
  try {
    const response = await API.get<ProductStageResponse[]>(`/${PHOTO_PROJECTS_PREFIX}/stages/`);
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchCategories,
  fetchCategoryById,
  fetchBrands,
  fetchPackagingTypes,
  fetchProductStages,
  fetchProductPhotoStages,
};
