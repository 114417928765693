import { BriefState, BriefActionTypes } from './actions';
import actionTypes from './actionTypes';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { BriefStore } from 'interfaces/API/products';

const initialState: BriefState = {
  briefs: [],
  redirectTo: null,
};

const briefReducer = createReducer<BriefState, BriefActionTypes>(initialState)
  .handleType(actionTypes.UPDATE_BRIEF, (state, action) =>
    produce(state, (draftState) => {
      const index = state.briefs.findIndex((brief) => brief.id === action.payload.briefId);
      draftState.briefs[index] = { id: action.payload.briefId, ...action.payload.response };
    })
  )
  .handleType(actionTypes.SAVE_BRIEF, (state, action) =>
    produce(state, (draftState) => {
      const brief: BriefStore = { ...action.payload.response, id: action.payload.briefId };
      draftState.briefs.push(brief);
    })
  )
  .handleType(actionTypes.DELETE_BRIEF_BLANKING, (state, action) =>
    produce(state, (draftState) => {
      const briefIndex = draftState.briefs.findIndex((item) => item.id === action.payload.briefId);
      if (briefIndex >= 0) {
        const blankingIndex = draftState.briefs[briefIndex].blankings.findIndex(
          (item: any) => item.id === action.payload.blankingId
        );
        draftState.briefs[briefIndex].blankings.splice(blankingIndex, 1);
      }
    })
  )
  .handleType(actionTypes.DELETE_BRIEF_PHOTO, (state, action) =>
    produce(state, (draftState) => {
      const briefIndex = draftState.briefs.findIndex((item) => item.id === action.payload.briefId);
      if (briefIndex >= 0) {
        const photoIndex = draftState.briefs[briefIndex].photos.findIndex((item: any) => item.id === action.payload.photoId);
        draftState.briefs[briefIndex].photos.splice(photoIndex, 1);
      }
    })
  )
  .handleType(actionTypes.DELETE_BRIEF_PHOTO_BRIEF_PHOTO, (state, action) =>
    produce(state, (draftState) => {
      const briefIndex = draftState.briefs.findIndex((item) => item.id === action.payload.briefId);
      if (briefIndex >= 0) {
        const photoIndex = draftState.briefs[briefIndex].photoBrief.fields[action.payload.photoBriefIndex].photos.findIndex((item: any) => item.id === action.payload.photoId);
        draftState.briefs[briefIndex].photoBrief.fields[action.payload.photoBriefIndex].photos.splice(photoIndex, 1);
      }
    })
  )
  .handleType(actionTypes.REDIRECT_TO, (state, action) =>
    produce(state, (draftState) => {
      if (draftState.redirectTo !== action.payload) {
        draftState.redirectTo = action.payload;
      }
    })
  );

export default briefReducer;
