import React from 'react';
import cx from 'classnames';

type HorizontalLineProps = {
  isLightBlue?: boolean;
}

function HorizontalLine({ isLightBlue}: HorizontalLineProps): JSX.Element {
  return <div className={cx('horizontal-line', { 'horizontal-line--light-blue': isLightBlue })} />;
}

export default HorizontalLine;
