/* eslint-disable import/no-anonymous-default-export */
import API, { PACKAGING_PROJECTS_PREFIX, PHOTO_PROJECTS_PREFIX } from './axiosConfig';
import {
  ProjectResponse,
  ProjectRequest,
  SimpleProject,
  ProjectPhotoRequest,
  ProjectPhotoResponse,
} from 'interfaces/API/projects';
import { toast } from 'react-toastify';
import { ProjectsQueryParams } from '../interfaces/API/projects';
import objectToFormDataConverter from '../helpers/objectToFormDataConverter';

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

const getProjects = async (params: RequireAtLeastOne<ProjectsQueryParams>): Promise<SimpleProject[] | null> => {
  try {
    const response = await API.get<ProjectResponse[]>('/projects/', { params });
    return response.data;
  } catch (err) {
    toast.error('Nie udało się pobrać listy projektów.');
    return null;
  }
};

const getProjectById = async (projectId: number): Promise<ProjectResponse | null> => {
  try {
    const response = await API.get<ProjectResponse>(`/${PACKAGING_PROJECTS_PREFIX}/projects/${projectId}/`);
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas pobierania projektu.');
    return null;
  }
};

const getPhotoProjectById = async (projectId: number): Promise<ProjectResponse | null> => {
  try {
    const response = await API.get<ProjectResponse>(`/${PHOTO_PROJECTS_PREFIX}/projects/${projectId}/`);
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas pobierania projektu.');
    return null;
  }
};

const createNewProject = async (data: ProjectRequest): Promise<ProjectResponse | null> => {
  try {
    const response = await API.post(`/${PACKAGING_PROJECTS_PREFIX}/projects/`, data);
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas tworzenia projektu.');
    return null;
  }
};

const createNewPhotoProject = async (data: ProjectPhotoRequest): Promise<ProjectPhotoResponse | null> => {
  try {
    const response = await API.post(`/${PHOTO_PROJECTS_PREFIX}/projects/`, data);
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas tworzenia projektu.');
    return null;
  }
};

const createNewProjectFile = async (projectId: number, file: File, description: string): Promise<void> => {
  try {
    const response = await API.post(
      `/projects/${projectId}/files/create/`,
      objectToFormDataConverter({
        file,
        description,
      })
    );
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas uploadu pliku projektu.');
  }
};

const deleteProjectFile = async (projectId: number, fileId: number): Promise<void> => {
  try {
    const response = await API.delete(`/projects/${projectId}/files/${fileId}/delete/`);
    return response.data;
  } catch (err) {
    toast.error('Wystąpił problem podczas usuwania pliku projektu.');
  }
};

const publishProject = async (projectId: number): Promise<ProjectResponse | null> => {
  try {
    const response = await API.patch<ProjectResponse>(`/projects/${projectId}/`, { publish: true });
    return response.data;
  } catch (err) {
    toast.error('Publikacja projektu nie powiodła się');
    return null;
  }
};

export default {
  getProjects,
  getProjectById,
  getPhotoProjectById,
  createNewProject,
  createNewPhotoProject,
  createNewProjectFile,
  deleteProjectFile,
  publishProject,
};
