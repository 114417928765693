import { Category } from 'API/projectData';
import { UserListItem } from './userAuthorization';

export type MemberData = {
  id: number;
  user: number;
  role: string;
};

export type ProjectMember = {
  user: UserListItem;
  role: string;
};

export interface ProjectRequest {
  name: string;
  description: string;
  category: number | null;
  brand: number | null;
  deadlineDate: string;
  promoDate: string;
  productsAmount: number;
};

export type ProjectPhotoRequest = Omit<ProjectRequest, 'promoDate'>

export enum ProjectType {
  Photo = 'photo',
  Packaging = 'packaging',
};

export type SimpleProject = {
  id: number;
  fullSlug: string;
  name: string;
  products: string;
  maxProductStage: string;
  members: MemberData[];
  published: boolean;
  updatedAt: Date;
  createdAt: Date;
  deadlineDate: string;
  category: Category;
  type: ProjectType;
  packagingProject?: number;
};

export type File = {
  id: number;
  url: string;
  filename: string;
  size: number;
  description: string;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
  deletable: boolean;
};

export type Project = SimpleProject & {
  publishable: boolean;
  description: string;
  category: number;
  brand: number;
  promoDate: string;
  productsAmount: number;
  files: File[];
  missingFields: object | null;
};

export type ProjectsQueryParams = {
  order_by: OrderByEnum;
  query: string;
};

export enum OrderByEnum {
  MOD = 'MOD',
  DL = 'DL',
}

export type ProjectResponse = Project;

export type ProjectPhotoResponse = Omit<ProjectResponse, 'promoDate'>;

export type AnyProject = SimpleProject | Project;

export enum ProductStageGroup {
  INVALID = 0,
  GROUP_1 = 1,
  GROUP_2 = 2,
  GROUP_3 = 3,
  GROUP_4 = 4,
  GROUP_5 = 5,
  GROUP_6 = 6,
  GROUP_7 = 7,
  GROUP_8 = 8,
  GROUP_9 = 9,
};

export enum ProductStage {
  STAGE_1 = 'waiting_for_brief',
  STAGE_1A = 'waiting_for_brief_accept_by_agency',
  STAGE_1B = 'waiting_for_brief_accept_by_client',
  STAGE_1C = 'waiting_for_briefs_accept',
  STAGE_2A = 'waiting_for_master_draft',
  STAGE_2B = 'waiting_for_master_draft_accept_by_agency',
  STAGE_2C = 'waiting_for_master_draft_accept_by_supplier',
  STAGE_2D = 'waiting_for_master_draft_accept_by_client',
  STAGE_3A = 'waiting_for_product_draft',
  STAGE_3B = 'waiting_for_product_draft_accept_by_agency',
  STAGE_3C = 'waiting_for_product_draft_accept_by_supplier',
  STAGE_3D = 'waiting_for_product_draft_accept_by_client',
  STAGE_4A = 'waiting_for_first_final',
  STAGE_4B = 'waiting_for_first_final_accept_by_agency',
  STAGE_4C = 'waiting_for_first_final_accept_by_supplier',
  STAGE_4D = 'waiting_for_first_final_accept_by_client',
  STAGE_5A = 'waiting_for_printing_files',
  STAGE_5B = 'waiting_for_printing_files_accept_by_agency',
  STAGE_5C = 'waiting_for_printing_files_accept_by_supplier',
  STAGE_5D = 'waiting_for_printing_files_accept_by_client',
  STAGE_6 = 'completed',
};

export enum ProductPhotoStage {
  STAGE_1 = 'briefing',
  STAGE_1A = 'brief_accept',
  STAGE_1B = 'waiting_for_briefs_accept',
  STAGE_2 = 'completed',
};
