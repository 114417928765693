import Modal from 'molecules/modals/Modal';
import React from 'react';
import cx from 'classnames';

interface ProductBriefModalProps {
  isModalOpen: boolean;
  setOpen: () => void;
  handleSuccess: () => void;
  handleCancel: () => void;
  isSubmitting: boolean;
  header: string;
  content: React.ReactNode;
  successButtonLabel: string;
  isLightBlue?: boolean;
}

export default function ProductBriefModal({
  isModalOpen,
  setOpen,
  handleSuccess,
  handleCancel,
  isSubmitting,
  header,
  content,
  successButtonLabel,
  isLightBlue
}: ProductBriefModalProps): JSX.Element {
  return (
    <Modal open={isModalOpen} setOpen={setOpen}>
      <Modal.Root>
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Footer>
          <button
            className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting }, { 'is-light-blue-background': isLightBlue })}
            type="button"
            onClick={handleSuccess}
            disabled={isSubmitting}
          >
            <span>{successButtonLabel}</span>
          </button>
          <button type="button" className="button is-light-grey" onClick={handleCancel}>
            <span>Anuluj</span>
          </button>
        </Modal.Footer>
      </Modal.Root>
    </Modal>
  );
}
