import React, { useContext, useState, useCallback, useMemo } from 'react';
import BriefHeader from 'atoms/BriefHeader';
import { Caret, DownloadIcon, FileIcon } from 'assets';
import cx from 'classnames';
import { addDraftFile, acceptDraftFile, DraftActionTypes, updateOpenDraftFile } from 'modules/draft/actions';
import {
  addPrintingFilesFile,
  acceptPrintingFilesFile,
  PrintingFilesActionTypes,
  updateOpenPrintingFile,
} from 'modules/printingFiles/actions';
import {
  addFirstFinalFile,
  acceptFirstFinalFile,
  FirstFinalActionTypes,
  updateOpenFirstFinal,
} from 'modules/firstFinal/actions';
import { format } from 'date-fns';
import fileSize from 'filesize';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/rootReducer';
import HorizontalLine from 'atoms/HorizontalLine';
import { Dispatch } from 'redux';
import { ActivityLogActionTypes } from 'modules/activityLog/actions';
import { BriefHeaderWithLength } from './BriefHeaderWithLength';
import useNumberParams from 'hooks/useNumberParams';
import Modal from './modals/Modal';
import FilesFormModal from './modals/FilesFormModal';
import userSelectors from 'modules/users/selectors';
import productSelectors from 'modules/products/selectors';
import { ProjectActionTypes } from 'modules/projects/actions';
import { DesignStageFileRequest } from 'interfaces/API/draft';
import { ProductActionTypes } from 'modules/products/actions';
import { ProductStageGroup } from 'interfaces/API/projects';
import useToggler from 'hooks/useToggler';
import { drafts } from '../modules/draft/selectors';
import { ClipLoader } from 'react-spinners';
import firstFinalApi from 'API/firstFinal';
import printingFilesApi from 'API/printingFiles';
import draftApi from 'API/drafts';
import masterDraftApi from 'API/masterDrafts';
import photoShootPlanApi from 'API/photoShootPlans';
import callSheetApi from 'API/callSheets';
import { AxiosResponse } from 'axios';
import {
  MasterDraftActionTypes,
  acceptMasterDraftFile,
  addMasterDraftFile,
  updateOpenMasterDraftFile,
} from 'modules/masterDraft/actions';
import { ProductFileIcon } from 'atoms/ProductFileIcon';
import { PhotoShootPlanActionTypes, acceptPhotoShootPlanFile, addPhotoShootPlanFile, updateOpenPhotoShootPlanFile } from 'modules/photoShootPlan/actions';
import { CallSheetActionTypes, acceptCallSheetFile, addCallSheetFile, updateOpenCallSheetFile } from 'modules/callSheet/actions';

export enum ProductFileStageTypes {
  INVALID,
  MASTER_DRAFT,
  DRAFT,
  PF,
  FF,
  PHOTO_SHOOT_PLAN,
  CALL_SHEET
}

type ProductFileStageProps = {
  type: ProductFileStageTypes;
  editable: boolean;
  acceptable: boolean;
};

type ContextValues = {
  type: ProductFileStageTypes;
  editable: boolean;
  acceptable: boolean;
  className?: string;
  text?: string;
  group?: ProductStageGroup;
  addMethod?: (
    dispatch: Dispatch<
      | MasterDraftActionTypes
      | DraftActionTypes
      | PrintingFilesActionTypes
      | FirstFinalActionTypes
      | ProductActionTypes
      | ActivityLogActionTypes
      | PhotoShootPlanActionTypes
      | CallSheetActionTypes
    >,
    id: number,
    data: DesignStageFileRequest
  ) => Promise<void>;
  updateOpen?: (
    dispatch: Dispatch<
      | MasterDraftActionTypes
      | DraftActionTypes
      | PrintingFilesActionTypes
      | FirstFinalActionTypes
      | ProductActionTypes
      | ActivityLogActionTypes
      | PhotoShootPlanActionTypes
      | CallSheetActionTypes
    >,
    id: number,
    data: number[],
    fileId: number
  ) => void;
  openFile?: (projectId: number, productId: number, fileId: number) => Promise<AxiosResponse | null>;
  acceptMethod?: (dispatch: Dispatch, id: number) => Promise<void>;
  //TODO TYPES
  createMethod?: (
    dispatch: Dispatch<ProjectActionTypes | ActivityLogActionTypes>,
    projectId: number,
    productId: number,
    file: DesignStageFileRequest
  ) => Promise<void>;
};

type ListItemProps = {
  file: FileItem;
  first: boolean;
};

type CompoundComponents = {
  Header: React.FC;
  List: React.FC;
  ListItem: React.FC<ListItemProps>;
};

type FileItem = {
  id: number;
  url: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  filename: string;
  size: number;
  description: string;
  version: string;
  openedBy: number[];
};

const ProductFileStageContext = React.createContext<ContextValues>({
  type: ProductFileStageTypes.INVALID,
  className: '',
  text: '',
  editable: false,
  acceptable: false,
  group: ProductStageGroup.INVALID,
  addMethod: async (dispatch, id, data) => {
    return Promise.resolve();
  },
});

const useProductFileStageContext = (): ContextValues => {
  const context = useContext(ProductFileStageContext);
  if (!context) {
    throw new Error(`Toggle compound components cannot be rendered outside the Toggle component`);
  }
  return context;
};

export const Header: React.FC = () => {
  const { type, text } = useProductFileStageContext();
  const isProjectLevelFile = type === ProductFileStageTypes.MASTER_DRAFT || type === ProductFileStageTypes.PHOTO_SHOOT_PLAN || type === ProductFileStageTypes.CALL_SHEET ;

  return (
    <div className="rows">
      <div className="row columns">
        <div className="column">
          <BriefHeaderWithLength briefType={text || ''} isProjectLevelFile={isProjectLevelFile} />
        </div>
      </div>
    </div>
  );
};

export const ListItem: React.FC<ListItemProps> = ({ file, first, ...props }) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [acceptModalOpen, toggleAcceptModal] = useToggler(false);
  const [rejectModalOpen, toggleRejectModal] = useToggler(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { projectId, productId } = useNumberParams();

  const dispatch = useDispatch();

  const {
    className,
    text,
    group,
    acceptMethod,
    addMethod,
    acceptable,
    editable,
    updateOpen,
    openFile,
    type,
  } = useProductFileStageContext();

  const isProjectLevelFile = type === ProductFileStageTypes.MASTER_DRAFT || type === ProductFileStageTypes.PHOTO_SHOOT_PLAN || type === ProductFileStageTypes.CALL_SHEET ;

  const [stage, uploadedBy, userRole, userId] = useSelector((state: AppState) => [
    productSelectors.productStage(productId)(state),
    userSelectors.getUserFullNameById(file.createdBy)(state),
    userSelectors.currentUserRole(state),
    userSelectors.userId(state),
  ]);

  const opened = file.openedBy && file.openedBy.some((id) => id === userId);

  const fileUpdate = (): void => {
    const openedBy = file.openedBy;
    if (!opened) {
      if (typeof updateOpen === 'function') {
        if (Array.isArray(openedBy)) {
          updateOpen(dispatch, isProjectLevelFile ? projectId : productId, [...openedBy, userId!], file.id);
        } else {
          updateOpen(dispatch, isProjectLevelFile ? projectId : productId, [userId!], file.id);
        }
      }
    }
  };

  const openFileByUser = async (file: FileItem): Promise<void> => {
    setFileLoading(true);
    if (typeof openFile === 'function') {
      const response = await openFile(projectId, productId, file.id);
      if (response) {
        fileUpdate();
        window.open(file.url, '_blank');
      }
    }
    setFileLoading(false);
  };

  const handleDescriptionOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const isAcceptedVersion = useMemo(() => (stage && group ? first && stage.group > group : false), [
    first,
    group,
    stage,
  ]);

  return (
    <>
      <div className="file-list-item">
        <div className="row columns has-items-centered">
          <div className="column has-items-centered is-1 is-dimmed">
            <Caret
              onClick={handleDescriptionOpen}
              className={cx(open ? 'caret--opened' : 'caret--closed', 'caret mr-1 ')}
            />
            <ProductFileIcon fileVersion={file.version} className={className} isAcceptedVersion={isAcceptedVersion} />
          </div>
          <div className="rows column is-4 ml-1">
            <div className="row">
              <span className={cx({ [`${className}--nameAccepted`]: className && isAcceptedVersion })}>
                {opened ? file.filename : <b>{file.filename}</b>}
              </span>
            </div>
            <div className="row">
              <span>{fileSize(Number(file.size), { base: 10 })}</span>
            </div>
          </div>
          <div className="column is-1">
            <span>{file.version}</span>
          </div>
          <div className="column is-2">
            <span>{file.createdAt && format(file.createdAt, 'dd-MM-yyyy')}</span>
          </div>
          <div className="column is-1">
            <span>{file.createdAt && format(file.createdAt, 'HH:mm')}</span>
          </div>
          <div className="column is-2">
            <span>{uploadedBy}</span>
          </div>
          <div className="column is-1"> {/* <Delete /> */}</div>
        </div>
      </div>
      {open && (
        <div className="rows file-list-item__dialog">
          <div className="row wrap">
            <span className="font-small p-1">{file.description}</span>
          </div>
          <div className="row items-right">
            <button
              type="button"
              className="button is-orange-lighten with-icon with-icon--right "
              disabled={fileLoading}
              onClick={(): void => {
                openFileByUser(file);
              }}
            >
              <span>{fileLoading ? <ClipLoader color="#fff" size={1.5} sizeUnit="rem" /> : `otwórz ${text}`}</span>
              <DownloadIcon />
            </button>
            {first && acceptable && (
              <button type="button" className="button is-orange-lighten ml-1" onClick={toggleAcceptModal}>
                <span>zaakceptuj {text}</span>
              </button>
            )}
            {first && acceptable && (
              <button type="button" className="button is-orange-lighten ml-1" onClick={toggleRejectModal}>
                <span>odrzuć z poprawkami</span>
              </button>
            )}
          </div>
        </div>
      )}
      <FilesFormModal
        open={rejectModalOpen}
        setOpen={toggleRejectModal}
        addMethod={addMethod!}
        dispatch={dispatch}
        projectId={projectId}
        productId={productId}
        headerText={'Odrzuć z poprawkami'}
        isProjectLevelFile={isProjectLevelFile}
      />
      {acceptModalOpen && (
        <Modal open={acceptModalOpen} setOpen={toggleAcceptModal}>
          <Modal.Root>
            <Modal.Header>Akceptuj {text}</Modal.Header>
            <Modal.Content>
              Zaakceptuj {text} &nbsp;
              <span>{file.filename}</span>
            </Modal.Content>
            <Modal.Footer>
              <button
                className={cx('button is-orange-lighten mr-1', { 'is-loading': isSubmitting })}
                disabled={isSubmitting}
                type="button"
                onClick={async (): Promise<void> => {
                  if (typeof acceptMethod === 'function') {
                    setSubmitting(true);
                    await acceptMethod(dispatch, isProjectLevelFile ? projectId : productId);
                    setSubmitting(false);
                  }
                  toggleAcceptModal();
                }}
              >
                <span>Akceptuj</span>
              </button>
              <button type="button" className="button is-light-grey" onClick={toggleAcceptModal}>
                <span>Anuluj</span>
              </button>
            </Modal.Footer>
          </Modal.Root>
        </Modal>
      )}
    </>
  );
};

export const List: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { text, addMethod, editable, type } = useProductFileStageContext();
  const [modalOpen, toggleModal] = useToggler(false);
  const { projectId, productId } = useNumberParams();
  const [userRole, draftList] = useSelector((state: AppState) => [userSelectors.currentUserRole(state), drafts(state)]);

  const buttonText = useMemo(() => `Dodaj ${text}`, [text, userRole]);

  const isProjectLevelFile = type === ProductFileStageTypes.MASTER_DRAFT || type === ProductFileStageTypes.PHOTO_SHOOT_PLAN || type === ProductFileStageTypes.CALL_SHEET ;

  return (
    <>
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>Dokumenty</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine />
          </div>
        </div>
        {editable && (
          <div className="row items-right">
            <div className="button is-orange-lighten with-icon with-icon--left" onClick={toggleModal}>
              <FileIcon />
              <span>{buttonText}</span>
            </div>
            {addMethod && (
              <FilesFormModal
                open={modalOpen}
                setOpen={toggleModal}
                addMethod={addMethod}
                dispatch={dispatch}
                projectId={projectId}
                productId={productId}
                headerText={buttonText}
                isProjectLevelFile={isProjectLevelFile}
              />
            )}
          </div>
        )}
      </div>
      <div className="rows">{children}</div>
    </>
  );
};

export const ProductFileStage: React.FC<ProductFileStageProps> & CompoundComponents = ({
  type,
  editable,
  acceptable,
  children,
}) => {
  const computedValues = useMemo(() => {
    if (type === ProductFileStageTypes.MASTER_DRAFT) {
      return {
        className: 'file-list-item__master-draft',
        text: 'Master Draft',
        group: ProductStageGroup.GROUP_2,
        acceptMethod: acceptMasterDraftFile,
        addMethod: addMasterDraftFile,
        updateOpen: updateOpenMasterDraftFile,
        openFile: masterDraftApi.openMasterDraft,
      };
    } else if (type === ProductFileStageTypes.DRAFT) {
      return {
        className: 'file-list-item__draft',
        text: 'Product Draft',
        group: ProductStageGroup.GROUP_3,
        acceptMethod: acceptDraftFile,
        addMethod: addDraftFile,
        updateOpen: updateOpenDraftFile,
        openFile: draftApi.openDraft,
      };
    } else if (type === ProductFileStageTypes.FF) {
      return {
        className: 'file-list-item__ff',
        text: 'First Final',
        group: ProductStageGroup.GROUP_4,
        acceptMethod: acceptFirstFinalFile,
        addMethod: addFirstFinalFile,
        updateOpen: updateOpenFirstFinal,
        openFile: firstFinalApi.openFirstFinal,
      };
    } else if (type === ProductFileStageTypes.PF) {
      return {
        className: 'file-list-item__pf',
        text: 'Printing Files',
        group: ProductStageGroup.GROUP_5,
        acceptMethod: acceptPrintingFilesFile,
        addMethod: addPrintingFilesFile,
        updateOpen: updateOpenPrintingFile,
        openFile: printingFilesApi.openPrintingFile,
      };
    }
     else if (type === ProductFileStageTypes.PHOTO_SHOOT_PLAN) {
      return {
        className: 'file-list-item__photo-shoot-plan',
        text: 'Photo Shoot Plan',
        group: ProductStageGroup.GROUP_7,
        acceptMethod: acceptPhotoShootPlanFile,
        addMethod: addPhotoShootPlanFile,
        updateOpen: updateOpenPhotoShootPlanFile,
        openFile: photoShootPlanApi.openPhotoShootPlan,
      };
    }
     else if (type === ProductFileStageTypes.CALL_SHEET) {
      return {
        className: 'file-list-item__call-sheet',
        text: 'Call Sheet',
        group: ProductStageGroup.GROUP_8,
        acceptMethod: acceptCallSheetFile,
        addMethod: addCallSheetFile,
        updateOpen: updateOpenCallSheetFile,
        openFile: callSheetApi.openCallSheet,
      };
    }
    return null;
  }, [type]);

  return (
    <ProductFileStageContext.Provider value={{ type, editable, acceptable, ...computedValues }}>
      {children}
    </ProductFileStageContext.Provider>
  );
};

ProductFileStage.Header = Header;
ProductFileStage.List = List;
ProductFileStage.ListItem = ListItem;
