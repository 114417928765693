import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import { DesignStageStore } from 'interfaces/API/draft';
import useRequestState, { RequestState } from './useRequestState';
import { photoShootPlanById } from 'modules/photoShootPlan/selectors';
import { getPhotoShootPlanByProjectId } from 'modules/photoShootPlan/actions';

type CurrentPhotoShootPlan = [RequestState, DesignStageStore | null, () => void];

const useCurrentPhotoShootPlan = (): CurrentPhotoShootPlan => {
  const { projectId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const draft = useSelector(photoShootPlanById(projectId));

  const handleRetry = (): void => {
    setDefault();
  };

  useEffect(() => {
    if (!draft && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await getPhotoShootPlanByProjectId(projectId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, draft, projectId, setDefault, setError, setLoading, status]);

  return [status, draft ? draft : null, handleRetry];
};

export default useCurrentPhotoShootPlan;
