import React from 'react';
import { ProductImagesStage, ProductImagesStageTypes } from 'molecules/ProductImagesStage';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import { RequestState } from 'hooks/useRequestState';
import RetryButton from 'molecules/RetryButton';
import useCurrentPhotoShoot from 'hooks/useCurrentPhotoShoot';
import useCurrentStage from 'hooks/useCurrentStage';

const PhotoShoot: React.FC = () => {
  const [status, currentDraft, handleRetry] = useCurrentPhotoShoot();
  useCurrentStage(4);

  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} isLoading={false} />
    </div>
  ) : currentDraft ? (
    <ProductImagesStage
      type={ProductImagesStageTypes.PHOTO_SHOOT}
      editable={currentDraft.editable}
      acceptable={currentDraft.acceptable}
    >
      <ProductImagesStage.Header />
      <ProductImagesStage.List>
        {currentDraft &&
          currentDraft.files.map((file, idx) => {
            return <ProductImagesStage.ListItem key={file.id} file={file} first={idx === 0} />;
          })}
      </ProductImagesStage.List>
    </ProductImagesStage>
  ) : null;
};

export default PhotoShoot;
