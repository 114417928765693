import { Form, withFormik } from 'formik';
import useNumberParams from 'hooks/useNumberParams';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  ManagementProps
} from './PhotoBrief.interfaces';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import useCurrentPhotoBrief from 'hooks/useCurrentPhotoBrief';
import useCurrentStage from 'hooks/useCurrentStage';
import useLocationParams from 'hooks/useLocationParams';
import { RequestState } from 'hooks/useRequestState';
import useToggler from 'hooks/useToggler';
import { t } from 'i18n';
import { PhotoBriefForm as PhotoBriefFormValues, PhotoBriefStore } from 'interfaces/API/products';
import briefSelectors from 'modules/brief/selectors';
import briefActions, { redirectTo } from 'modules/photoBrief/actions';
import productSelectors from 'modules/products/selectors';
import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import projectSelectors from 'modules/projects/selectors';
import PhotoProductBriefNavigationButtons from 'molecules/PhotoProductBriefNavigationButtons';
import RetryButton from 'molecules/RetryButton';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../App';
import { UpdateBriefForm } from '../../interfaces/API/products';
import ProductBriefDownload from './components/PhotoBriefDownload';
import ProductBriefHeader from './components/PhotoBriefHeader';
import PhotoProductBriefMainDetails from './components/PhotoBriefMainDetails';
import ProductBriefModal from './components/PhotoBriefModal';
import ProductBriefPhoto from './components/fields/ProductBriefPhoto';
import useProductBriefNavigation from './hooks/usePhotoBriefNavigation';
import { BoxOpenWhite } from 'assets';
import TextInput from 'atoms/TextInput';
import ProductBriefLabelDownload from './components/ProductBriefLabelDownload';

const PhotoBriefForm: React.FC<any>
  = ({ ...props }) => {
    const { projectId, productId } = useNumberParams();
    const [status, currentBrief, handleRetry] = useCurrentPhotoBrief();
    useCurrentStage(1);
    const dispatch = useDispatch();

    const [currentProductIndex, userRole, newId, product, briefRedirectTo, project] = useSelector((state: AppState) => [
      productSelectors.projectProductIndex(projectId, productId)(state),
      userSelectors.currentUserRole(state),
      productSelectors.newProductId(projectId, productId)(state),
      productSelectors.productById(productId)(state),
      briefSelectors.briefRedirectTo(state),
      projectSelectors.projectById(projectId)(state)
    ]);

    const isStorekeeper = useSelector(userSelectors.isCurrentUserStorekeeper);
    const [isPublishModalOpen, togglePublishModal] = useToggler();
    const [isAcceptModalOpen, toggleAcceptModal] = useToggler();
    const [isRejectModalOpen, toggleRejectModal] = useToggler();
    const [isAddSampleModalOpen, toggleAddSampleModal] = useToggler();
    const { isSubmitting, values } = props;
    const [rack, setRack] = useState<any>(null);
    const [shelf, setShelf] = useState('');
    const rackValidationMsg = 'Field with max 2 numbers'
    const shelfValidationMsg = 'Field with 2 capital letters'

    const isEditable = useMemo(() => {
      return (currentBrief && currentBrief.editable) || false;
    }, [currentBrief]);

    const isPublishable = useMemo(() => {
      return (currentBrief && currentBrief.publishable) || false;
    }, [currentBrief]);

    const isAcceptable = useMemo(() => {
      return (currentBrief && currentBrief.acceptable) || false;
    }, [currentBrief]);

    const cloneProductEnable = useMemo(
      () => currentProductIndex !== undefined && currentProductIndex >= 1 && newId && isEditable,
      [currentProductIndex, isEditable, newId]
    );

    const canDownloadBrief = useMemo(
      () =>
        userRole === 'agency' && !['waiting_for_brief', 'waiting_for_brief_accept_by_agency'].includes(product!.stage),
      [product, userRole]
    );

    const canAddSampleProduct = useMemo(
      () => userRole === 'agency' && isStorekeeper, [userRole, isStorekeeper]
    );

    const canDownloadLabel = useMemo(
      () => userRole === 'client' && project?.published, [userRole, currentBrief]
    );

    const handleRejectProduct = async (): Promise<void> => {
      await briefActions.rejectBriefAction(dispatch, Number(productId));
    };

    const handleAcceptProduct = async (): Promise<void> => {
      await briefActions.acceptBriefAction(dispatch, Number(productId));
    };

    const handlePublishProduct = async (): Promise<void> => {
      if (!isEqual(currentBrief, props.values)) {
        await briefActions.patchBriefAction(dispatch, productId, props.values);
      }
      await briefActions.publishBriefAction(dispatch, Number(productId));
    };

    const [isSaveModalOpen, toggleSaveModal, isBriefUnchanged] = useHandleBriefRedirect({
      currentBrief,
      values,
      briefRedirectTo,
    });

    const onSaveAction = useCallback(async () => {
      if (currentBrief) {
        if (!isBriefUnchanged) {
          await briefActions.patchBriefAction(dispatch, Number(productId), values);
        }
      }
    }, [currentBrief, dispatch, isBriefUnchanged, productId, values]);

    const onAddSample = async () => {
      await briefActions.sampleAction(dispatch, { rack, shelf, product: productId })
    }

    const shelfRegex = new RegExp('^[A-Z]{1,2}$');
    const rackRegex = new RegExp('^\\d{1,2}$');
    const checkValidation = () => {
      if (shelfRegex.test(shelf) && rackRegex.test(rack)) {
        return true
      } else return false
    };

    const productBriefNavigationProps = useProductBriefNavigation({
      newId,
      userRole,
      product,
      isAcceptable,
      isEditable,
      isPublishable,
      isPublishModalOpen,
      isSubmitting,
      toggleAcceptModal,
      togglePublishModal,
      toggleRejectModal,
      toggleAddSampleModal,
      onSaveAction,
    });

    return status === RequestState.IS_LOADING ? (
      <ClimbingLoader />
    ) : status === RequestState.HAS_ERROR ? (
      <div className="retry-dashboard">
        <RetryButton onRetry={handleRetry} isLoading={false} />
      </div>
    ) : (
      <Form className="form">
        <ProductBriefHeader
          {...productBriefNavigationProps}
          cloneProductEnable={cloneProductEnable}
          productId={productId}
          dispatch={dispatch}
          sample={currentBrief?.sample}
        />
        {(canAddSampleProduct && !currentBrief?.sample) && (
          <div className='row columns'>
            <div className='column form__header--left'>
              <div className='column items-right'>
                <button type="button" className="button is-orange-lighten mr-1" onClick={toggleAddSampleModal}>
                  <BoxOpenWhite className='sample' />
                  <span className='m-1'> {t('productBrief.header.navigation.sample')}</span>
                </button>
              </div>
            </div>
          </div>
        )}

        {canDownloadBrief && <ProductBriefDownload currentBrief={currentBrief} />}
        {canDownloadLabel && <ProductBriefLabelDownload currentBrief={currentBrief} />}

        <PhotoProductBriefMainDetails isEditable={isEditable} />
        <ProductBriefPhoto isEditable={isEditable} productId={productId} />

        <div className="row items-right p-2 ">
          <PhotoProductBriefNavigationButtons {...productBriefNavigationProps} />
        </div>

        {isAddSampleModalOpen && (
          <ProductBriefModal
            isModalOpen={isAddSampleModalOpen}
            setOpen={toggleAddSampleModal}
            header={t('productBrief.modals.sample.header')}
            content={
              <>
                <div>
                  <TextInput required pattern='^\d{1,2}$' title="Field with max 2 numbers" onChange={(e) => setRack(e.target.value)} placeholder={t('productBrief.modals.sample.content')} />
                  {!checkValidation() && <span>{rackValidationMsg}</span>}
                </div>
                <div>
                  <TextInput required pattern='^[A-Z]{1,2}$' title="Field with max 2 capital letters" onChange={(e) => setShelf(e.target.value)} placeholder={t('productBrief.modals.sample.content2')} />
                  {!checkValidation() && <span>{shelfValidationMsg}</span>}
                </div>
              </>
            }
            isSubmitting={isSubmitting}
            handleSuccess={async (): Promise<void> => {
              if (checkValidation()) {
                await onAddSample();
                toggleAddSampleModal();
              }
            }}
            handleCancel={() => {
              setRack("")
              setShelf("")
              toggleAddSampleModal()
            }}
            successButtonLabel={t('productBrief.modals.sample.success')}
          />
        )}

        {isSaveModalOpen && (
          <ProductBriefModal
            isModalOpen={isSaveModalOpen}
            setOpen={toggleSaveModal}
            header={t('productBrief.modals.save.header')}
            content={t('productBrief.modals.save.content')}
            isSubmitting={isSubmitting}
            handleSuccess={async (): Promise<void> => {
              props.setSubmitting(true);
              await onSaveAction();
              dispatch(redirectTo(null));
              props.setSubmitting(false);
              toggleSaveModal();
              briefRedirectTo && history.push(briefRedirectTo);
            }}
            handleCancel={() => {
              toggleSaveModal();
              dispatch(redirectTo(null));
            }}
            successButtonLabel={t('productBrief.modals.save.success')}
          />
        )}

        {isRejectModalOpen && (
          <ProductBriefModal
            isModalOpen={isRejectModalOpen}
            setOpen={toggleRejectModal}
            header={t('productBrief.modals.reject.header')}
            content={
              <>
                {`${t('productBrief.modals.reject.content')}`} &nbsp;
                <span className="bolder">{` ${currentBrief && currentBrief.name} / ${currentBrief && currentBrief.modelNo
                  }`}</span>
              </>
            }
            isSubmitting={isSubmitting}
            handleSuccess={async (): Promise<void> => {
              props.setSubmitting(true);
              await handleRejectProduct();
              props.setSubmitting(false);
              toggleRejectModal();
            }}
            handleCancel={toggleRejectModal}
            successButtonLabel={t('productBrief.modals.reject.success')}
          />
        )}

        {isAcceptModalOpen && (
          <ProductBriefModal
            isModalOpen={isAcceptModalOpen}
            setOpen={toggleAcceptModal}
            header={t('productBrief.modals.accept.header')}
            content={
              <>
                {`${t('productBrief.modals.accept.content')}`} &nbsp;
                <span className="bolder">{` ${currentBrief && currentBrief.name} / ${currentBrief && currentBrief.modelNo
                  }`}</span>
              </>
            }
            isSubmitting={isSubmitting}
            handleSuccess={async (): Promise<void> => {
              props.setSubmitting(true);
              await handleAcceptProduct();
              props.setSubmitting(false);
              toggleAcceptModal();
            }}
            handleCancel={toggleAcceptModal}
            successButtonLabel={t('productBrief.modals.accept.success')}
          />
        )}
        {isPublishModalOpen && (
          <ProductBriefModal
            isModalOpen={isPublishModalOpen}
            setOpen={togglePublishModal}
            header={t('productBrief.modals.publish.header')}
            content={
              <>
                {`${t('productBrief.modals.publish.content')}`} &nbsp;
                <span className="bolder">{`${currentBrief && currentBrief.name} / ${currentBrief && currentBrief.modelNo
                  }`}</span>
              </>
            }
            isSubmitting={isSubmitting}
            handleSuccess={async (): Promise<void> => {
              props.setSubmitting(true);
              await handlePublishProduct();
              props.setSubmitting(false);
              togglePublishModal();
            }}
            handleCancel={togglePublishModal}
            successButtonLabel={t('productBrief.modals.publish.success')}
          />
        )}
      </Form>
    );
  };

const areBriefValuesUnchanged = (currentBrief: PhotoBriefStore | null, values: unknown) => {
  if (currentBrief) {

    return !currentBrief.editable || isEqual({ ...currentBrief }, values);
  }
  return true;
};

const useHandleBriefRedirect = ({
  currentBrief,
  values,
  briefRedirectTo,
}: {
  currentBrief: PhotoBriefStore | null;
  values: UpdateBriefForm;
  briefRedirectTo: string | null;
}) => {
  const [params, location] = useLocationParams();
  const [isSaveModalOpen, toggleSaveModal] = useToggler();
  const isBriefUnchanged = areBriefValuesUnchanged(currentBrief, values);
  const dispatch = useDispatch();

  useEffect(() => {
    if (briefRedirectTo && briefRedirectTo !== location.pathname) {
      if (isBriefUnchanged) {
        dispatch(redirectTo(null));
        history.push({ pathname: briefRedirectTo, search: params.toString() });
      } else {
        toggleSaveModal();
      }
    }
  }, [briefRedirectTo, isBriefUnchanged, location.pathname, params, toggleSaveModal, dispatch]);

  return [isSaveModalOpen, toggleSaveModal, isBriefUnchanged] as const;
};

export default withFormik<
  { currentBrief: any } & ManagementProps & RouteComponentProps<{ id: string; pid: string }>,
  PhotoBriefFormValues
>({
  enableReinitialize: true,
  mapPropsToValues: ({ currentBrief }) => {
    if (currentBrief) {
      return currentBrief;
    }

    return {
      name: '',
      modelNo: '',
      acceptable: false,
      editable: false,
      publishable: false,
      fields: [],
    };
  },
  handleSubmit: async (
    values,
    {
      props: {
        dispatch,
        currentBrief,
        match: {
          params: { pid: productId },
        },
      },
    }
  ) => {
    if (currentBrief) {
      const { id, acceptable, editable, publishable, ...brief } = currentBrief;
      if (editable && !isEqual({ brief }, values)) {
      }
    }
  },
})(PhotoBriefForm);
