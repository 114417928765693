import React from 'react';
import { PuzzelIcon } from 'assets';
import { useSelector } from 'react-redux';
import userSelectors from 'modules/users/selectors';
import { FilterIcon } from 'assets';
import cx from 'classnames';
import { ProjectsQueryParams } from '../interfaces/API/projects';
import { OrderByEnum } from 'interfaces/API/projects';
import DropdownMenu from 'molecules/DropdownMenu';

type LeftSidebarHeaderProps = {
  createNewProject: () => void;
  createNewProjectPhoto: () => void;
  orderListBy: (orderby: OrderByEnum) => void;
  orderBy: ProjectsQueryParams['order_by'];
  loading: boolean;
};

const LeftSidebarHeader: React.FC<LeftSidebarHeaderProps> = ({
  createNewProject,
  createNewProjectPhoto,
  orderListBy,
  orderBy,
  loading,
}) => {
  const userRole = useSelector(userSelectors.currentUserRole);
  return (
    <div className="rows left-header">
      <div className="row columns is-marginless">
        <div className="column has-items-centered">
          <PuzzelIcon />
          <span className="left-header__title">Projekty</span>
          <button
            className={cx('button order-button', orderBy === OrderByEnum.MOD ? 'is-orange-lighten' : 'is-medium-grey')}
            onClick={() => orderListBy(OrderByEnum.MOD)}
            disabled={loading}
          >
            <span className="order-button__label">
              MOD
              <FilterIcon />
            </span>
          </button>
          <button
            className={cx('button order-button', orderBy === OrderByEnum.DL ? 'is-orange-lighten' : 'is-medium-grey')}
            onClick={() => orderListBy(OrderByEnum.DL)}
            disabled={loading}
          >
            <span className="order-button__label">
              DL
              <FilterIcon />
            </span>
          </button>
        </div>
        {['supplier', 'client'].includes(userRole || '') && (
          <DropdownMenu
            userRole={userRole}
            handlePackagingDesign={createNewProject}
            handleProductPhotoshoot={createNewProjectPhoto}
          />
        )}
      </div>
    </div>
  );
};

export default LeftSidebarHeader;
