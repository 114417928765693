import { AxiosResponse } from 'axios';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { DesignStageFileRequest, DesignStageFileResponse, DesignStageResponse } from 'interfaces/API/draft';
import API, { PHOTO_PROJECTS_PREFIX } from './axiosConfig';

const getPhotoShootByProductId = async (productId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.get<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoots/${productId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const addPhotoShootFile = async (
  productId: number,
  data: DesignStageFileRequest
): Promise<DesignStageFileResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<DesignStageFileResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoots/${productId}/files/`, formData);
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptPhotoShoot = async (productId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoots/${productId}/accept/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const updatePhotoShootFile = async (productId: number, fileId: number, data: { requireRetouch: boolean }): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoots/${productId}/files/${fileId}/`, data);
    return response.data;
  } catch (err) {
    return null;
  }
};

const getPhotoShootFileDetails = async (projectId: number, productId: number, fileId: number) => {
  try {
    const response = await API.get<AxiosResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoots/${productId}/files/${fileId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  getPhotoShootByProductId,
  addPhotoShootFile,
  acceptPhotoShoot,
  getPhotoShootFileDetails,
  updatePhotoShootFile
};
