import React, { useRef, useState } from 'react';
import { t } from '../i18n';

import './ProjectBriefFiles.scss';
import { File } from 'modules/projects/selectors';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  DeleteIcon,
  DownloadAttachmentIcon,
  FileTypeDocIcon,
  FileTypeEpsIcon,
  FileTypeGifIcon,
  FileTypeJpgIcon,
  FileTypePdfIcon,
  FileTypePngIcon,
  FileTypePptIcon,
  FileTypeXlsIcon,
  FileTypeZipIcon,
  NewFileIcon,
} from 'assets';
import useToggler from 'hooks/useToggler';
import ProductBriefModal from 'organisms/ProductBrief/components/ProductBriefModal';
import {sliceText} from "../helpers/sliceText";

export const FilePreview = ({ file, onDelete }: { file: File; onDelete: (e: File) => unknown }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [deleteModalOpen, toggleDeleteModalOpen] = useToggler();
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  return (
    <div className="file-list-entry">
      <div className="file-list-entry__container">
        <button className="file-list-entry__expand" onClick={() => setIsExpanded((expanded) => !expanded)}>
          {isExpanded ? <ArrowDownIcon /> : <ArrowLeftIcon />}
        </button>

        <div className="file-list-entry__file-brief">
          <FileIcon type={file.type} />
          <div>
            <div className="file-list-entry__file-name">{file.name}</div>
            <div className="file-list-entry__file-size">{file.size}</div>
          </div>
        </div>

        <div className="file-list-entry__column">{file.uploadedDate}</div>
        <div className="file-list-entry__column">{file.uploadedTime}</div>
        <div className="file-list-entry__column">{file.uploaderName}</div>
        {file.deletable ? (
          <button
            type="button"
            disabled={deleteDisabled}
            onClick={toggleDeleteModalOpen}
            className="file-list-entry__delete"
          >
            <DeleteIcon title={t('projectBrief.projectFiles.delete')} />
          </button>
        ) : (
          <div />
        )}
      </div>
      {isExpanded && (
        <div className="file-list-entry-description">
          <div>{file.description}</div>
          <a className="file-list__button button is-orange-lighten" href={file.url} download={file.name} target="_blank">
            <DownloadAttachmentIcon />
            {t('projectBrief.projectFiles.download')}
          </a>
        </div>
      )}
      {deleteModalOpen && (
        <ProductBriefModal
          isModalOpen={deleteModalOpen}
          setOpen={toggleDeleteModalOpen}
          header={t('productBrief.attachments.photos.modal.header')}
          content={
            <div className="file-list-entry__file-brief">
              <FileIcon type={file.type} />
              <div>
                <div className="file-list-entry__file-name">{file.name}</div>
                <div className="file-list-entry__file-size">{file.size}</div>
              </div>
            </div>
          }
          isSubmitting={false}
          handleSuccess={async () => {
            try {
              setDeleteDisabled(true);
              await onDelete(file);
            } finally {
              setDeleteDisabled(false);
              toggleDeleteModalOpen();
            }
          }}
          handleCancel={toggleDeleteModalOpen}
          successButtonLabel={t('productBrief.attachments.photos.modal.delete')}
        />
      )}
    </div>
  );
};

export const FilePreviewSummary = ({ file }: { file: File }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="file-list-entry">
      <div className="file-list-entry__container file-list-entry__container--summary">
        <button className="file-list-entry__expand" onClick={() => setIsExpanded((expanded) => !expanded)}>
          {isExpanded ? <ArrowDownIcon /> : <ArrowLeftIcon />}
        </button>

        <div className="file-list-entry__file-brief">
          <FileIcon type={file.type} />
          <div className="file-list-entry__summary-container">
            <div className="file-list-entry__file-name">{sliceText(file.name)}</div>
            <div className="file-list-entry__upload-summary">
              <div className="file-list-entry__file-size">{file.size}</div>
              <div>{file.uploadedDate}</div>
              <div>{file.uploadedTime}</div>
              <div>{file.uploaderName}</div>
            </div>
          </div>
        </div>

        <a href={file.url} download={file.name} target="_blank" className="file-list-entry__download-icon">
          <DownloadAttachmentIcon />
        </a>
      </div>
      {isExpanded && (
        <div className="file-list-entry-description file-list-entry-description--summary">{file.description}</div>
      )}
    </div>
  );
};

export const FileIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'doc':
      return <FileTypeDocIcon />;
    case 'docx':
      return <FileTypeDocIcon />;
    case 'eps':
      return <FileTypeEpsIcon />;
    case 'gif':
      return <FileTypeGifIcon />;
    case 'jpg':
      return <FileTypeJpgIcon />;
    case 'pdf':
      return <FileTypePdfIcon />;
    case 'png':
      return <FileTypePngIcon />;
    case 'ppt':
      return <FileTypePptIcon />;
    case 'pptx':
      return <FileTypePptIcon />;
    case 'xls':
      return <FileTypeXlsIcon />;
    case 'xlsx':
      return <FileTypeXlsIcon />;
    case 'zip':
      return <FileTypeZipIcon />;
    default:
      return <></>;
  }
};

export const FileUpload = ({ onSelected }: { onSelected: (files: FileList) => unknown }) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={ref}
        type="file"
        hidden
        multiple
        onChange={(e) => {
          onSelected(e.currentTarget.files!);
          /** Reset input, so it can select the same files again if the user so desires */
          e.currentTarget.value = null as any;
        }}
      />
      <button
        className="file-list__button button is-orange-lighten"
        onClick={() => {
          ref.current?.click();
        }}
      >
        <NewFileIcon />
        {t('projectBrief.projectFiles.newProjectFile')}
      </button>
    </>
  );
};
