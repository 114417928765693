/* eslint-disable import/no-anonymous-default-export */
import API, {PHOTO_PROJECTS_PREFIX} from './axiosConfig';
import { DesignStageResponse, DesignStageFileRequest, DesignStageFileResponse } from 'interfaces/API/draft';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { AxiosResponse } from 'axios';

const getPhotoShootPlanByProjectId = async (projectId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.get<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoot-plans/${projectId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const addPhotoShootPlanFile = async (
  projectId: number,
  data: DesignStageFileRequest
): Promise<DesignStageFileResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<DesignStageFileResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoot-plans/${projectId}/files/`, formData);
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptPhotoShootPlan = async (projectId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoot-plans/${projectId}/accept/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const openPhotoShootPlan = async (projectId: number, productId: number, fileId: number) => {
  try {
    const response = await API.get<AxiosResponse>(`/${PHOTO_PROJECTS_PREFIX}/photo-shoot-plans/${projectId}/files/${fileId}/open-file/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  getPhotoShootPlanByProjectId,
  addPhotoShootPlanFile,
  acceptPhotoShootPlan,
  openPhotoShootPlan,
};
