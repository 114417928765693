import React from 'react';
import { ErrorMessage, Field } from 'formik';
import FieldTextInput from 'molecules/fields/FieldTextInput';
import FieldInputError from 'atoms/FieldInputError';
import { t } from 'i18n';

interface ProductBriefMainDetailsProps {
  isEditable: boolean;
}

export default function PhotoProductBriefMainDetails({
  isEditable,
}: ProductBriefMainDetailsProps): React.ReactElement {

  return (
    <div className="field-section">
      <div className="rows">
        <div className="row rows">
          <div className="row">
            <Field
              name="name"
              component={FieldTextInput}
              type="text"
              placeholder={t('productBrief.mainDetails.name.placeholder')}
              className="brief__input"
              disabled={!isEditable}
            />
          </div>
          <div className="row">
            <ErrorMessage name="name" component={FieldInputError} />
          </div>
        </div>

        <div className="row columns stacked gap-1">
          <div className="row rows">
            <div className="column is-narrow">
              <Field
                name="modelNo"
                component={FieldTextInput}
                type="text"
                placeholder={t('productBrief.mainDetails.modelNo.placeholder')}
                className="brief__input"
                disabled={!isEditable}
              />
              <ErrorMessage name="modelNo" component={FieldInputError} />
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}
