import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en/translation.json';
import pl from './translations/pl/translation.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: typeof resources['en'];
    returnNull: false;
  }
}

export const defaultNS = 'translation';

export const resources = {
  en: { translation: en },
  pl: { translation: pl },
};

i18n.use(initReactI18next).init({
  defaultNS,
  lng: 'en',
  resources,
  returnNull: false,
});

export const t = i18n.t.bind(i18n);
export default i18n;
