import React from 'react';
import cx from 'classnames';
import { AcceptedFileIcon, CorrectedFileIcon, DraftFileIcon } from 'assets';

enum FileVersionTypes {
  CORRECTED = 'P',
  // TODO: add other types when will be needed
}

type ProductFileIconProps = {
  fileVersion: string;
  className?: string;
  isAcceptedVersion?: boolean;
};

export const ProductFileIcon: React.FC<ProductFileIconProps> = ({ fileVersion, className, isAcceptedVersion }) => {
  return isAcceptedVersion ? (
    <AcceptedFileIcon className={cx({ [`${className}--iconAccepted`]: className && isAcceptedVersion })} />
  ) : fileVersion === FileVersionTypes.CORRECTED ? (
    <CorrectedFileIcon />
  ) : (
    <DraftFileIcon />
  );
};
