import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { FormikProps, withFormik, Form } from 'formik';
import ProductBriefNavigationButtons from 'molecules/ProductBriefNavigationButtons';
import { RouteComponentProps } from 'react-router';
import isEqual from 'lodash.isequal';
import useCurrentBrief from 'hooks/useCurrentBrief';
import useNumberParams from 'hooks/useNumberParams';
import {
  ProductBriefSelectorsProps,
  OtherBriefFormValues,
  ManagementProps,
  TextilesBriefFormValues,
} from './ProductBrief.interfaces';
import { ProductBriefSchema } from './schemas';
import briefActions, { redirectTo } from 'modules/brief/actions';
import useToggler from 'hooks/useToggler';
import productSelectors from 'modules/products/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/rootReducer';
import userSelectors from 'modules/users/selectors';
import useCurrentStage from 'hooks/useCurrentStage';
import RetryButton from 'molecules/RetryButton';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import { RequestState } from 'hooks/useRequestState';
import useLocationParams from 'hooks/useLocationParams';
import { history } from '../../App';
import briefSelectors from 'modules/brief/selectors';
import { BriefStore, UpdateBriefForm } from '../../interfaces/API/products';
import useProductBriefNavigation from './hooks/useProductBriefNavigation';
import ProductBriefHeader from './components/ProductBriefHeader';
import ProductBriefDownload from './components/ProductBriefDownload';
import ProductBriefMainDetails from './components/ProductBriefMainDetails';
import ProductBriefAttachments from './components/ProductBriefAttachments';
import ProductBriefDescription from './components/ProductBriefDescription';
import ProductBriefSigns from './components/ProductBriefSigns';
import { BriefType } from 'API/projectData';
import ProductBriefPhoto from './components/fields/ProductBriefPhoto';
import ProductBriefIllustration from './components/fields/ProductBriefIllustration';
import ProductBriefManual from './components/fields/ProductBriefManual';
import ProductBriefModal from './components/ProductBriefModal';
import { t } from 'i18n';

const ProductBriefForm: React.FC<
  ProductBriefSelectorsProps & ManagementProps & FormikProps<OtherBriefFormValues & TextilesBriefFormValues>
> = ({ ...props }) => {
  const { projectId, productId } = useNumberParams();
  const [status, currentBrief, handleRetry] = useCurrentBrief();
  useCurrentStage(1);
  const dispatch = useDispatch();

  const [currentProductIndex, userRole, newId, product, briefRedirectTo] = useSelector((state: AppState) => [
    productSelectors.projectProductIndex(projectId, productId)(state),
    userSelectors.currentUserRole(state),
    productSelectors.newProductId(projectId, productId)(state),
    productSelectors.productById(productId)(state),
    briefSelectors.briefRedirectTo(state),
  ]);

  const [isPublishModalOpen, togglePublishModal] = useToggler();
  const [isAcceptModalOpen, toggleAcceptModal] = useToggler();
  const [isRejectModalOpen, toggleRejectModal] = useToggler();
  const [isAddSampleModalOpen, toggleAddSampleModal] = useToggler();
  const { isSubmitting, values } = props;

  const isEditable = useMemo(() => {
    return (currentBrief && currentBrief.editable) || false;
  }, [currentBrief]);

  const isPublishable = useMemo(() => {
    return (currentBrief && currentBrief.publishable) || false;
  }, [currentBrief]);

  const isAcceptable = useMemo(() => {
    return (currentBrief && currentBrief.acceptable) || false;
  }, [currentBrief]);

  const cloneProductEnable = useMemo(
    () => currentProductIndex !== undefined && currentProductIndex >= 1 && newId && isEditable,
    [currentProductIndex, isEditable, newId]
  );

  const canDownloadBrief = useMemo(
    () =>
      userRole === 'agency' && !['waiting_for_brief', 'waiting_for_brief_accept_by_agency'].includes(product!.stage),
    [product, userRole]
  );

  const isOther = useMemo(() => props.projectCategory === BriefType.other, [props.projectCategory]);

  const isTextiles = useMemo(() => props.projectCategory === BriefType.textiles, [props.projectCategory]);

  const handleRejectProduct = async (): Promise<void> => {
    await briefActions.rejectBriefAction(dispatch, Number(productId));
  };

  const handleAcceptProduct = async (): Promise<void> => {
    await briefActions.acceptBriefAction(dispatch, Number(productId));
  };

  const handlePublishProduct = async (): Promise<void> => {
    if (!isEqual(currentBrief, props.values)) {
      await briefActions.patchBriefAction(dispatch, productId, props.values);
    }
    await briefActions.publishBriefAction(dispatch, Number(productId));
  };

  const [isSaveModalOpen, toggleSaveModal, isBriefUnchanged] = useHandleBriefRedirect({
    currentBrief,
    values,
    briefRedirectTo,
  });

  const onSaveAction = useCallback(async () => {
    if (currentBrief) {
      if (!isBriefUnchanged) {
        await briefActions.patchBriefAction(dispatch, Number(productId), values);
      }
    }
  }, [currentBrief, dispatch, isBriefUnchanged, productId, values]);

  const toggleProjectPhoto = useCallback(async () => {
    if (currentBrief) {
      if (!isBriefUnchanged) {
        await briefActions.togglePhotoBriefAction(dispatch, Number(productId), values);
      }
    }
  }, [currentBrief, dispatch, isBriefUnchanged, productId, values]);

  const productBriefNavigationProps = useProductBriefNavigation({
    newId,
    userRole,
    product,
    isAcceptable,
    isEditable,
    isPublishable,
    isPublishModalOpen,
    isSubmitting,
    toggleAcceptModal,
    togglePublishModal,
    toggleRejectModal,
    toggleAddSampleModal,
    onSaveAction,
  });

  useEffect(() => {
    toggleProjectPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.requirePhotoBrief]);

  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} isLoading={false} />
    </div>
  ) : (
    <Form className="form">
      <ProductBriefHeader
        {...productBriefNavigationProps}
        cloneProductEnable={cloneProductEnable}
        productId={productId}
        dispatch={dispatch}
      />

      {canDownloadBrief && <ProductBriefDownload currentBrief={currentBrief} />}

      <ProductBriefMainDetails isEditable={isEditable} isOther={isOther} isTextiles={isTextiles} />

      <ProductBriefAttachments
        formFieldName="photos"
        isEditable={isEditable}
        allPhotos={props.values.photos}
        allBlankings={props.values.blankings}
        productId={productId}
        dispatch={dispatch}
      />

      {currentBrief && <ProductBriefDescription isEditable={isEditable} />}

      <ProductBriefSigns isEditable={isEditable} isOther={isOther} isTextiles={isTextiles} />

      {props.values.requireIllustration && <ProductBriefIllustration isEditable={isEditable} />}

      {props.values?.requireManual && <ProductBriefManual isEditable={isEditable} />}

      {props.values.requirePhotoBrief && props.values.photoBrief && (
        <ProductBriefPhoto isEditable={isEditable} productId={productId} />
      )}

      <div className="row items-right p-2 ">
        <ProductBriefNavigationButtons {...productBriefNavigationProps} />
      </div>

      {isSaveModalOpen && (
        <ProductBriefModal
          isModalOpen={isSaveModalOpen}
          setOpen={toggleSaveModal}
          header={t('productBrief.modals.save.header')}
          content={t('productBrief.modals.save.content')}
          isSubmitting={isSubmitting}
          handleSuccess={async (): Promise<void> => {
            props.setSubmitting(true);
            await onSaveAction();
            dispatch(redirectTo(null));
            props.setSubmitting(false);
            toggleSaveModal();
            briefRedirectTo && history.push(briefRedirectTo);
          }}
          handleCancel={() => {
            toggleSaveModal();
            dispatch(redirectTo(null));
          }}
          successButtonLabel={t('productBrief.modals.save.success')}
        />
      )}

      {isPublishModalOpen && (
        <ProductBriefModal
          isModalOpen={isPublishModalOpen}
          setOpen={togglePublishModal}
          header={t('productBrief.modals.publish.header')}
          content={
            <>
              {`${t('productBrief.modals.publish.content')}`} &nbsp;
              <span className="bolder">{`${currentBrief && currentBrief.name} / ${
                currentBrief && currentBrief.modelNo
              }`}</span>
            </>
          }
          isSubmitting={isSubmitting}
          handleSuccess={async (): Promise<void> => {
            props.setSubmitting(true);
            await handlePublishProduct();
            props.setSubmitting(false);
            togglePublishModal();
          }}
          handleCancel={togglePublishModal}
          successButtonLabel={t('productBrief.modals.publish.success')}
        />
      )}

      {isRejectModalOpen && (
        <ProductBriefModal
          isModalOpen={isRejectModalOpen}
          setOpen={toggleRejectModal}
          header={t('productBrief.modals.reject.header')}
          content={
            <>
              {`${t('productBrief.modals.reject.content')}`} &nbsp;
              <span className="bolder">{` ${currentBrief && currentBrief.name} / ${
                currentBrief && currentBrief.modelNo
              }`}</span>
            </>
          }
          isSubmitting={isSubmitting}
          handleSuccess={async (): Promise<void> => {
            props.setSubmitting(true);
            await handleRejectProduct();
            props.setSubmitting(false);
            toggleRejectModal();
          }}
          handleCancel={toggleRejectModal}
          successButtonLabel={t('productBrief.modals.reject.success')}
        />
      )}

      {isAcceptModalOpen && (
        <ProductBriefModal
          isModalOpen={isAcceptModalOpen}
          setOpen={toggleAcceptModal}
          header={t('productBrief.modals.accept.header')}
          content={
            <>
              {`${t('productBrief.modals.accept.content')}`} &nbsp;
              <span className="bolder">{` ${currentBrief && currentBrief.name} / ${
                currentBrief && currentBrief.modelNo
              }`}</span>
            </>
          }
          isSubmitting={isSubmitting}
          handleSuccess={async (): Promise<void> => {
            props.setSubmitting(true);
            await handleAcceptProduct();
            props.setSubmitting(false);
            toggleAcceptModal();
          }}
          handleCancel={toggleAcceptModal}
          successButtonLabel={t('productBrief.modals.accept.success')}
        />
      )}
    </Form>
  );
};

const areBriefValuesUnchanged = (currentBrief: BriefStore | null, values: unknown) => {
  if (currentBrief) {
    const { id, acceptable, editable, publishable, createdAt, updatedAt, ...brief } = currentBrief;
    return !editable || isEqual({ ...brief }, values);
  }
  return true;
};

const useHandleBriefRedirect = ({
  currentBrief,
  values,
  briefRedirectTo,
}: {
  currentBrief: BriefStore | null;
  values: UpdateBriefForm;
  briefRedirectTo: string | null;
}) => {
  const [params, location] = useLocationParams();
  const [isSaveModalOpen, toggleSaveModal] = useToggler();
  const isBriefUnchanged = areBriefValuesUnchanged(currentBrief, values);
  const dispatch = useDispatch();

  useEffect(() => {
    if (briefRedirectTo && briefRedirectTo !== location.pathname) {
      if (isBriefUnchanged) {
        dispatch(redirectTo(null));
        history.push({ pathname: briefRedirectTo, search: params.toString() });
      } else {
        toggleSaveModal();
      }
    }
  }, [briefRedirectTo, isBriefUnchanged, location.pathname, params, toggleSaveModal, dispatch]);

  return [isSaveModalOpen, toggleSaveModal, isBriefUnchanged] as const;
};

export default withFormik<
  ProductBriefSelectorsProps & ManagementProps & RouteComponentProps<{ id: string; pid: string }>,
  OtherBriefFormValues & TextilesBriefFormValues
>({
  enableReinitialize: true,
  validationSchema: ({ projectCategory }: { projectCategory: BriefType }) => {
    return projectCategory === BriefType.textiles ? ProductBriefSchema.textilesSchema : ProductBriefSchema.otherSchema;
  },
  mapPropsToValues: ({ currentBrief }) => {
    if (currentBrief) {
      const { id, acceptable, editable, publishable, updatedAt, createdAt, ...brief } = currentBrief;
      return brief;
    }

    return {
      name: '',
      modelNo: '',
      batchNo: '',
      ean: '',
      requireIllustration: false,
      illustration: '',
      requireManual: false,
      manual: '',
      requirePhotoBrief: false,
      photoBrief: {
        acceptable: false,
        editable: false,
        publishable: false,
        fields: [],
      },
      sizes: [],
      descriptionLanguages: [],
      packagingType: null,
      mainDescription: '',
      extraDescription: '',
      additionalDescriptionElements: [],
      photos: [],
      blankings: [],
      certificate: '',
      additionalMarkings: '',
      informationSigns: '',
      capabilitiesIcons: '',
      productIcons: '',
    };
  },
  handleSubmit: async (
    values,
    {
      props: {
        dispatch,
        currentBrief,
        match: {
          params: { pid: productId },
        },
      },
    }
  ) => {
    if (currentBrief) {
      const { id, acceptable, editable, publishable, ...brief } = currentBrief;
      if (editable && !isEqual({ brief }, values)) {
        await briefActions.patchBriefAction(dispatch, Number(productId), values);
      }
    }
  },
})(ProductBriefForm);
