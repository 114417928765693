import { useLocation } from 'react-router';
import { Location } from 'history';
import { useMemo } from 'react';

const useLocationParams = (): [URLSearchParams, Location<any>] => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  return [params, location];
};

export default useLocationParams;
