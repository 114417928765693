import BriefHeader from 'atoms/BriefHeader';
import FieldInputError from 'atoms/FieldInputError';
import HorizontalLine from 'atoms/HorizontalLine';
import { ErrorMessage, Field } from 'formik';
import { t } from 'i18n';
import FieldTextArea from 'molecules/fields/FieldTextArea';
import React from 'react';

interface ProductBriefSignsProps {
  isEditable: boolean;
  isOther: boolean;
  isTextiles: boolean;
}

export default function ProductBriefSigns({
  isEditable,
  isOther,
  isTextiles,
}: ProductBriefSignsProps): React.ReactElement {
  return (
    <div className="field-section">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column is-narrow">
            <BriefHeader>{t('productBrief.signs.title')}</BriefHeader>
          </div>
          <div className="column">
            <HorizontalLine />
          </div>
        </div>

        <div className="row rows">
          <div className="row">
            <Field
              name="certificate"
              component={FieldTextArea}
              placeholder={t('productBrief.signs.certificate.placeholder')}
              className="form__input--textarea"
              disabled={!isEditable}
            />
          </div>
          <div className="row">
            <ErrorMessage name="certificate" component={FieldInputError} />
          </div>
        </div>
        {isOther && (
          <>
            <div className="row rows">
              <div className="row">
                <Field
                  name="informationSigns"
                  component={FieldTextArea}
                  placeholder={t('productBrief.signs.informationSigns.placeholder')}
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="informationSigns" component={FieldInputError} />
              </div>
            </div>
            <div className="row rows">
              <div className="row">
                <Field
                  name="capabilitiesIcons"
                  component={FieldTextArea}
                  placeholder={t('productBrief.signs.capabilitiesIcons.placeholder')}
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="capabilitiesIcons" component={FieldInputError} />
              </div>
            </div>
            <div className="row rows">
              <div className="row">
                <Field
                  name="productIcons"
                  component={FieldTextArea}
                  placeholder={t('productBrief.signs.productIcons.placeholder')}
                  className="form__input--textarea"
                  disabled={!isEditable}
                />
              </div>
              <div className="row">
                <ErrorMessage name="productIcons" component={FieldInputError} />
              </div>
            </div>
          </>
        )}
        {isTextiles && (
          <div className="row rows">
            <div className="row">
              <Field
                name="additionalMarkings"
                component={FieldTextArea}
                placeholder={t('productBrief.signs.additionalMarkings.placeholder')}
                className="form__input--textarea"
                disabled={!isEditable}
              />
            </div>
            <div className="row">
              <ErrorMessage name="additionalMarkings" component={FieldInputError} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
