import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import { DesignStageStore } from 'interfaces/API/draft';
import useRequestState, { RequestState } from './useRequestState';
import { callSheetById } from 'modules/callSheet/selectors';
import { getCallSheetByProjectId } from 'modules/callSheet/actions';

type CurrentCallSheet = [RequestState, DesignStageStore | null, () => void];

const useCurrentCallSheet = (): CurrentCallSheet => {
  const { projectId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const draft = useSelector(callSheetById(projectId));

  const handleRetry = (): void => {
    setDefault();
  };

  useEffect(() => {
    if (!draft && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await getCallSheetByProjectId(projectId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, draft, projectId, setDefault, setError, setLoading, status]);

  return [status, draft ? draft : null, handleRetry];
};
export default useCurrentCallSheet;
