import React from 'react';
import { Caret } from 'assets';
import cx from 'classnames';
type ProjectMembersType = {
  toggleOpen: () => void;
  open: boolean;
  fullSlug: string;
};

const ProjectMembers: React.FC<ProjectMembersType> = ({ toggleOpen, open, fullSlug }) => {
  return (
    <div className="project-members-header">
      <span className="project-members-header__title">Osoby / {fullSlug}</span>
      <Caret onClick={toggleOpen} className={cx('caret caret--white', open ? 'caret--opened' : 'caret--closed')} />
    </div>
  );
};
export default ProjectMembers;
