import { createAction, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import masterDraftApi from 'API/masterDrafts';
import ActivityLogActions, { ActivityLogActionTypes } from 'modules/activityLog/actions';
import {
  DesignStageResponse,
  DesignStageStore,
  DesignStageFileRequest,
  DesignStageFileResponse,
} from 'interfaces/API/draft';
import { ProductActionTypes, updateProductsOnSuccess } from 'modules/products/actions';
import ProjectsActions from 'modules/projects/actions';
import { toast } from 'react-toastify';

export type MasterDraftState = {
  masterDraftList: DesignStageStore[];
};

export enum actionTypes {
  ADD_MASTER_DRAFT = 'masterDrafts/ADD_MASTER_DRAFT',
  UPDATE_MASTER_DRAFT = 'masterDrafts/UPDATE_MASTER_DRAFT',
  ADD_MASTER_DRAFT_FILE = 'masterDrafts/ADD_MASTER_DRAFT_FILE',
  UPDATE_MASTER_DRAFT_OPENED_BY = 'masterDrafts/UPDATE_MASTER_DRAFT_OPENED_BY',
}

const internalActions = {
  addMasterDraft: createAction(actionTypes.ADD_MASTER_DRAFT)<{ projectId: number; response: DesignStageResponse }>(),
  updateMasterDraft: createAction(actionTypes.UPDATE_MASTER_DRAFT)<{
    projectId: number;
    response: DesignStageResponse;
  }>(),
  addMasterDraftFile: createAction(actionTypes.ADD_MASTER_DRAFT_FILE)<{
    projectId: number;
    response: DesignStageFileResponse;
  }>(),
  updateOpenedFile: createAction(actionTypes.UPDATE_MASTER_DRAFT_OPENED_BY)<{
    projectId: number;
    response: number[];
    fileId: number;
  }>(),
};

export type MasterDraftActionTypes = ActionType<typeof internalActions>;
type MasterDraftFile = MasterDraftActionTypes | ProductActionTypes | ActivityLogActionTypes;

export const getMasterDraftByProjectId = async (
  projectId: number,
  dispatch: Dispatch<MasterDraftActionTypes>
): Promise<boolean> => {
  const response = await masterDraftApi.getMasterDraftByProjectId(projectId);
  if (!response) {
    return false;
  }
  dispatch(internalActions.addMasterDraft({ projectId, response }));
  return true;
};

export const addMasterDraftFile = async (
  dispatch: Dispatch<MasterDraftFile>,
  projectId: number,
  data: DesignStageFileRequest
): Promise<void> => {
  const response = await masterDraftApi.addMasterDraftFile(projectId, data);
  if (response) {
    dispatch(internalActions.addMasterDraftFile({ projectId, response }));
    const draftResponse = await masterDraftApi.getMasterDraftByProjectId(projectId);
    if (draftResponse) {
      dispatch(internalActions.updateMasterDraft({ projectId, response: draftResponse }));
      await ActivityLogActions.getProductMessages(dispatch, projectId);
    }
    await updateProductsOnSuccess(projectId, dispatch);
    await ProjectsActions.getProjectByIdAction(dispatch, projectId);
    toast.success('Dodano master draft');
  } else {
    toast.error('Nie udało się dodać master draftu');
  }
};

export const acceptMasterDraftFile = async (dispatch: Dispatch<MasterDraftFile>, projectId: number): Promise<void> => {
  const response = await masterDraftApi.acceptMasterDraft(projectId);
  if (response) {
    dispatch(internalActions.updateMasterDraft({ projectId, response }));
    await updateProductsOnSuccess(projectId, dispatch);
    await ProjectsActions.getProjectByIdAction(dispatch, projectId);
    await ActivityLogActions.getProductMessages(dispatch, projectId);
    toast.success('Zaakceptowano master draft');
  } else {
    toast.error('Wystąpił błąd podczas akceptowania master draftu');
  }
};

export const updateOpenMasterDraftFile = (
  dispatch: Dispatch<MasterDraftActionTypes>,
  projectId: number,
  openedBy: number[],
  fileId: number
) => {
  dispatch(internalActions.updateOpenedFile({ projectId, response: openedBy, fileId }));
};
