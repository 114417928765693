/* eslint-disable import/no-anonymous-default-export */
import API, { PHOTO_PROJECTS_PREFIX } from './axiosConfig';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { PhotoBriefFullResponse, PhotoBriefPatchRequest } from 'interfaces/API/products';
import { ProductSampleCreate, ProductSampleResponse } from 'interfaces/API/sample';

const getBriefById = async (briefId: number): Promise<PhotoBriefFullResponse | null> => {
  try {
    const response = await API.get<PhotoBriefFullResponse>(`/${PHOTO_PROJECTS_PREFIX}/briefs/${briefId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const patchBriefById = async (
  briefId: number,
  data: PhotoBriefPatchRequest
): Promise<PhotoBriefFullResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.patch<PhotoBriefFullResponse>(`/${PHOTO_PROJECTS_PREFIX}/briefs/${briefId}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptBrief = async (briefId: number): Promise<PhotoBriefFullResponse | null> => {
  try {
    const response = await API.patch<PhotoBriefFullResponse>(`/${PHOTO_PROJECTS_PREFIX}/briefs/${briefId}/accept/`, {
      accept: true,
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

const rejectBrief = async (briefId: number): Promise<PhotoBriefFullResponse | null> => {
  try {
    const response = await API.patch<PhotoBriefFullResponse>(`/${PHOTO_PROJECTS_PREFIX}/briefs/${briefId}/accept/`, {
      accept: false,
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

const publishBrief = async (briefId: number): Promise<PhotoBriefFullResponse | null> => {
  try {
    const response = await API.patch<PhotoBriefFullResponse>(`/${PHOTO_PROJECTS_PREFIX}/briefs/${briefId}/publish/`, {
      publish: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const deletePhotoBriefPhoto = async (photoId: number): Promise<number | null> => {
  try {
    const response = await API.delete<void>(`/${PHOTO_PROJECTS_PREFIX}/brief-images/${photoId}/`);
    return response.status;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const cloneBrief = async (copyFrom: number, briefId: number): Promise<PhotoBriefFullResponse | null> => {
  try {
    const response = await API.patch<PhotoBriefFullResponse>(`/${PHOTO_PROJECTS_PREFIX}/briefs/${briefId}/clone/`, {
      cloneFrom: copyFrom,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

{/* TO DO: needs some extra BE work for new shape of data for standalone photo project */}
const exportPhotoBriefToPdf = async (photoId: number, filename: string): Promise<void | null> => {
  try {
    const response = await API.get(`/${PHOTO_PROJECTS_PREFIX}/briefs/${photoId}/export-to-pdf/`, { responseType: 'blob' });
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    document.body.append(link);
    link.click();
    link.remove();
  } catch (err) {
    return null;
  }
};

const addSample = async (body:ProductSampleCreate): Promise<ProductSampleResponse | null> => {
  try {
    const response = await API.post(`/${PHOTO_PROJECTS_PREFIX}/product-samples/`, body)
    return response.data;
  } catch (err) {
    return null
  }
};

const getSample = async (productId: number): Promise<ProductSampleResponse | null> => {
  try {
    const response = await API.get(`/${PHOTO_PROJECTS_PREFIX}/product-samples/${productId}`)
    return response.data;
  } catch (err) {
    return null
  }
}

const generateBriefLabel = async (photoId: number, filename: string): Promise<void | null> => {
  try {
    const response = await API.get(`/${PHOTO_PROJECTS_PREFIX}/briefs/${photoId}/generate-label/`, { responseType: 'blob' });
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    document.body.append(link);
    link.click();
    link.remove();
  } catch (err) {
    return null;
  }
};

export default {
  cloneBrief,
  getBriefById,
  acceptBrief,
  rejectBrief,
  patchBriefById,
  publishBrief,
  deletePhotoBriefPhoto,
  exportPhotoBriefToPdf,
  addSample,
  getSample,
  generateBriefLabel
};
