import { combineReducers } from 'redux';
import briefReducer from './brief/reducer';
import userReducer from './users/reducer';
import projectReducer from './projects/reducer';
import { BriefActionTypes } from './brief/actions';
import { ActionType } from 'typesafe-actions';
import { UserActionTypes } from './users/actions';
import { ActivityLogActionTypes } from './activityLog/actions';
import activityLogReducer from './activityLog/reducer';
import dataReducer from './data/reducer';
import productReducer from './products/reducer';
import draftReducer from './draft/reducer';
import { DataActionTypes } from './data/actions';
import { ProductActionTypes } from './products/actions';
import { ProjectActionTypes } from './projects/actions';
import { ProgressActionTypes } from './progress/actions';
import progressReducer from './progress/reducer';
import firstFinalReducer from './firstFinal/reducer';
import printingFilesReducer from './printingFiles/reducer';
import { DraftActionTypes } from './draft/actions';
import masterDraftReducer from './masterDraft/reducer';
import { MasterDraftActionTypes } from './masterDraft/actions';
import photoBriefreducer from './photoBrief/reducer';
import { PhotoShootPlanActionTypes } from './photoShootPlan/actions';
import { CallSheetActionTypes } from './callSheet/actions';
import photoShootPlanReducer from './photoShootPlan/reducer';
import photoShootReducer from './photoShoot/reducer';
import callSheetReducer from './callSheet/reducer';
import photoRetouchReducer from './photoRetouch/reducer';
import { PhotoShootActionTypes } from './photoShoot/actions';
import { PhotoRetouchActionTypes } from './photoRetouch/actions';

const rootReducer = (state: any, action: any) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined;
  }
  return combineReducers({
    users: userReducer,
    projects: projectReducer,
    products: productReducer,
    projectData: dataReducer,
    progress: progressReducer,
    activityLog: activityLogReducer,
    brief: briefReducer,
    photoBrief: photoBriefreducer,
    masterDrafts: masterDraftReducer,
    drafts: draftReducer,
    firstFinals: firstFinalReducer,
    printingFiles: printingFilesReducer,
    photoShootPlans: photoShootPlanReducer,
    callSheets: callSheetReducer,
    photoShoots: photoShootReducer,
    photoRetouch: photoRetouchReducer,
  })(state, action);
};

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;

type actions =
  | BriefActionTypes
  | ProjectActionTypes
  | UserActionTypes
  | ActivityLogActionTypes
  | DataActionTypes
  | ProductActionTypes
  | ProgressActionTypes
  | DraftActionTypes
  | MasterDraftActionTypes
  | PhotoShootPlanActionTypes
  | CallSheetActionTypes
  | PhotoShootActionTypes
  | PhotoRetouchActionTypes;

export type RootAction = ActionType<actions>;
