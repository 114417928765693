enum Routes {
  // TODO: Replace routes /projects with /project-packaging
  PROJECT_PACKAGING_NEW = '/project-packaging/new',
  PROJECT_PACKAGING_DETAILS = '/project-packaging/:id',
  PROJECT_PACKAGING_BRIEF = '/project-packaging/:id/products/:pid',
  PROJECT_PACKAGING_MASTER_DRAFT = '/project-packaging/:id/products/:pid/master-draft',
  PROJECT_PACKAGING_DRAFT = '/project-packaging/:id/products/:pid/draft',
  PROJECT_PACKAGING_FF = '/project-packaging/:id/products/:pid/ff',
  PROJECT_PACKAGING_PF = '/project-packaging/:id/products/:pid/pf',

  PROJECT_PHOTO_NEW = '/project-photo/new',
  PROJECT_PHOTO_DETAILS = '/project-photo/:id',
  PROJECT_PHOTO_BRIEF = '/project-photo/:id/products/:pid',
}
export default Routes;
