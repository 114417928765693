import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNumberParams from './useNumberParams';
import { DesignStageStore } from 'interfaces/API/draft';
import useRequestState, { RequestState } from './useRequestState';
import { masterDraftById } from 'modules/masterDraft/selectors';
import { getMasterDraftByProjectId } from 'modules/masterDraft/actions';

type CurrentDraft = [RequestState, DesignStageStore | null, () => void];

const useCurrentMasterDraft = (): CurrentDraft => {
  const { projectId } = useNumberParams();
  const dispatch = useDispatch();
  const [status, setError, setLoading, setDefault] = useRequestState();
  const draft = useSelector(masterDraftById(projectId));

  const handleRetry = (): void => {
    setDefault();
  };

  useEffect(() => {
    if (!draft && status === RequestState.DEFAULT) {
      setLoading();
      (async (): Promise<void> => {
        const response = await getMasterDraftByProjectId(projectId, dispatch);
        if (!response) {
          setError();
          return;
        }
        setDefault();
      })();
    }
  }, [dispatch, draft, projectId, setDefault, setError, setLoading, status]);

  return [status, draft ? draft : null, handleRetry];
};
export default useCurrentMasterDraft;
