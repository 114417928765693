import { CirclePlus, InfoIcon, PackagingDesignIcon, ProductPhotoshootIcon } from 'assets';
import React, { useRef, useState } from 'react';
import './DropdownMenu.scss';
import useOnClickOutside from 'use-onclickoutside';
import i18n from 'i18n';
import Tooltip from 'atoms/Tooltip';

interface DropdownMenuProps {
  userRole: string | null;
  handlePackagingDesign: () => void;
  handleProductPhotoshoot: () => void;
}

export default function DropdownMenu({ userRole, handlePackagingDesign, handleProductPhotoshoot }: DropdownMenuProps) {
  const node = useRef<HTMLHeadingElement>(null);
  const [open, setOpen] = useState(false);

  useOnClickOutside(node, () => setOpen(false));

  const handleItemClick = (func: () => void) => {
    func();
    setOpen(!open);
  };

  return (
    <div className="dropdownmenu column is-narrow has-items-centered" ref={node}>
      <CirclePlus onClick={() => setOpen(!open)} className="scale-on-hover" />
      {open && (
        <ul className="dropdownmenu-items">
          <li
            className={`row dropdownmenu-items__item bolder ${
              userRole === 'client' && 'dropdownmenu-items__item--disabled'
            }`}
            onClick={() => userRole === 'supplier' && handleItemClick(handlePackagingDesign)}
          >
            <PackagingDesignIcon /> {i18n.t('leftSideBar.createNewProject.packagingDesign')}{' '}
            <Tooltip text={i18n.t('leftSideBar.createNewProject.packagingDesign')}>
              <InfoIcon />
            </Tooltip>
          </li>
          <li
            className={`row dropdownmenu-items__item bolder ${
              userRole === 'supplier' && 'dropdownmenu-items__item--disabled'
            }`}
            onClick={() => userRole === 'client' && handleItemClick(handleProductPhotoshoot)}
          >
            <ProductPhotoshootIcon /> {i18n.t('leftSideBar.createNewProject.productPhotoshoot')}{' '}
            <Tooltip text={i18n.t('leftSideBar.createNewProject.productPhotoshoot')}>
              <InfoIcon />
            </Tooltip>
          </li>
        </ul>
      )}
    </div>
  );
}
