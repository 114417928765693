import { DownloadIcon } from 'assets';
import { downloadByURL } from 'helpers/fileHelpers';
import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import briefApi from 'API/photoBrief';
import { PhotoBriefStore } from 'interfaces/API/products';
import { t } from 'i18n';

interface ProductBriefLabelDownloadProps {
  currentBrief: PhotoBriefStore | null;
}

export default function ProductBriefLabelDownload({ currentBrief }: ProductBriefLabelDownloadProps): React.ReactElement {
  const [labelLoading, setLabelLoading] = useState(false);

  const generateLabel = async (): Promise<void> => {
    setLabelLoading(true);
    const url = await briefApi.generateBriefLabel (
      currentBrief!.id,
      `BRIEF-${currentBrief?.modelNo}-${currentBrief?.name}.pdf`
    );
    setLabelLoading(false);
  };

  return (
    <div className="field-section">
      <div className="rows">
        <div className="row columns has-items-centered">
          <div className="column">
            <button
              type="submit"
              onClick={generateLabel}
              className="button is-orange-lighten with-icon with-icon--right"
              disabled={labelLoading}
            >
              {labelLoading ? <ClipLoader color="#fff" size={2} sizeUnit="rem" /> : t('productBrief.generateLabelButton')}
              <DownloadIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
