import React from 'react';
import { RetouchImageStage } from 'molecules/RetouchImageStage';
import { ClimbingLoader } from 'atoms/ClimbingLoader';
import { RequestState } from 'hooks/useRequestState';
import RetryButton from 'molecules/RetryButton';
import useCurrentPhotoRetouch from 'hooks/useCurrentPhotoRetouch';
import useCurrentStage from 'hooks/useCurrentStage';

const PhotoRetouch: React.FC = () => {
  const [status, currentDraft, handleRetry] = useCurrentPhotoRetouch();
  useCurrentStage(5);

  return status === RequestState.IS_LOADING ? (
    <ClimbingLoader />
  ) : status === RequestState.HAS_ERROR ? (
    <div className="retry-dashboard">
      <RetryButton onRetry={handleRetry} isLoading={false} />
    </div>
  ) : currentDraft ? (
    <RetouchImageStage
      editable={currentDraft.editable}
      acceptable={currentDraft.acceptable}
      publishable={currentDraft.publishable}
    >
      <RetouchImageStage.Header />
      <RetouchImageStage.List>
        {currentDraft &&
          currentDraft.files.map((file, idx) => {
            return <RetouchImageStage.ListItem key={file.id} file={file} first={idx === 0} />;
          })}
      </RetouchImageStage.List>
    </RetouchImageStage>
  ) : null;
};

export default PhotoRetouch;
