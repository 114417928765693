import React, { useState, useContext, useCallback } from 'react';
import { useHistory, match } from 'react-router';
import { Caret } from 'assets';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import productSelectors from 'modules/products/selectors';
import { AppState } from 'modules/rootReducer';
import { GroupStageStamp } from 'molecules/StageStamp';
import { ProductPhotoStage, ProductStage, ProjectType } from 'interfaces/API/projects';
import useLocationParams from 'hooks/useLocationParams';
import { redirectTo } from 'modules/brief/actions';
import projectSelectors from 'modules/projects/selectors';

type ProjectContextValues = {
  productsVisible: boolean;
  toggleProducts: () => void;
  handleProjectClick: () => void;
};

type ProjectProps = {
  id: number;
  defaultOpen?: boolean;
};

type ProductItemProps = {
  projectId: number;
  productId: number;
  displayName: string;
  isActive: boolean;
};

type CompoundComponents = {
  Slug: React.FC<ProjectSlugProps>;
  Name: React.FC;
  ProductList: React.FC;
  Product: React.FC<ProductItemProps>;
};

const ProjectContext = React.createContext<ProjectContextValues>({
  productsVisible: true,
  toggleProducts: () => {},
  handleProjectClick: () => {},
});

const useProjectContext = (): ProjectContextValues => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error(`Toggle compound components cannot be rendered outside the Toggle component`);
  }
  return context;
};
type RedirectUrlParameters = {
  currentStage: ProductStage;
  projectId: number;
  productId: number;
  projectType?: ProjectType;
};
const redirectUrl = ({ currentStage, projectId, productId, projectType }: RedirectUrlParameters): string => {
  if (projectType === ProjectType.Packaging) {
    if (
      currentStage &&
      [ProductStage.STAGE_1, ProductStage.STAGE_1A, ProductStage.STAGE_1B, ProductStage.STAGE_1C].includes(currentStage)
    ) {
      return `/projects/${projectId}/products/${productId}`;
    } else if (
      currentStage &&
      [ProductStage.STAGE_2A, ProductStage.STAGE_2B, ProductStage.STAGE_2C, ProductStage.STAGE_2D].includes(
        currentStage
      )
    ) {
      return `/projects/${projectId}/products/${productId}/master-draft`;
    } else if (
      currentStage &&
      [ProductStage.STAGE_3A, ProductStage.STAGE_3B, ProductStage.STAGE_3C, ProductStage.STAGE_3D].includes(
        currentStage
      )
    ) {
      return `/projects/${projectId}/products/${productId}/draft`;
    } else if (
      currentStage &&
      [ProductStage.STAGE_4A, ProductStage.STAGE_4B, ProductStage.STAGE_4C, ProductStage.STAGE_4D].includes(
        currentStage
      )
    ) {
      return `/projects/${projectId}/products/${productId}/ff`;
    } else if (
      currentStage &&
      [ProductStage.STAGE_5A, ProductStage.STAGE_5B, ProductStage.STAGE_5C, ProductStage.STAGE_5D].includes(
        currentStage
      )
    ) {
      return `/projects/${projectId}/products/${productId}/pf`;
    } else if (currentStage && [ProductStage.STAGE_6].includes(currentStage)) {
      return `/projects/${projectId}/products/${productId}/pf`;
    } else {
      return `/projects/${projectId}`
    }
  }

  if (projectType === ProjectType.Photo) {
    return `/project-photo/${projectId}/products/${productId}`;
  }
  return ""
};

const ProductsList: React.FC = ({ children }) => {
  const { productsVisible } = useProjectContext();

  return productsVisible ? <div className="row rows">{children}</div> : null;
};

const ProductItem: React.FC<ProductItemProps> = ({ projectId, productId, displayName, isActive }) => {
  const projectType :ProjectType | undefined = useSelector((state: AppState) => productSelectors.productType(productId)(state));
  const currentStage = useSelector((state: AppState) => productSelectors.productStage(productId)(state));
  const history = useHistory();
  const [params, location] = useLocationParams();
  const dispatch = useDispatch();
  const onProductClick = (currentStage: { label: string; group: number; name: ProductStage }) => {
    const regex = /\/projects\/[0-9]+\/products\/[0-9]+$/;
    const isProductBrief = location.pathname.match(regex);

    if (!isProductBrief) {
      history.push({
        pathname: redirectUrl({ currentStage: currentStage.name, projectId, productId, projectType }),
        search: params.toString(),
      });
    } else {
      dispatch(redirectTo(redirectUrl({ currentStage: currentStage.name, projectId, productId, projectType })));
    }
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };

  return currentStage ? (
    <div className="row columns product">
      <div className="column is-narrow">
        <GroupStageStamp group={currentStage.group} label={currentStage.label} />
      </div>
      <div className="column" style={{ flexWrap: 'wrap', display: 'flex' }}>
        <span
          className={cx('product__id', { 'product__id--active': isActive })}
          onClick={(): void => onProductClick(currentStage)}
          style={{ lineHeight: '1.5rem' }}
        >
          {displayName}
        </span>
      </div>
    </div>
  ) : null;
};

type ProjectSlugProps = {
  isActive: boolean;
  productCount: number;
};
const ProjectSlug: React.FC<ProjectSlugProps> = ({ productCount, isActive, children }) => {
  const { productsVisible, toggleProducts, handleProjectClick } = useProjectContext();
  return (
    <div className="row is-spaced columns is-marginless">
      <div className="column is-narrow">
        <span
          onClick={handleProjectClick}
          className={cx('project__slug', {
            'project__slug--active': isActive,
          })}
        >
          {children}
        </span>
      </div>
      <div className="column is-narrow is-dimmed has-items-centered">
        <span className="project__product-count">{productCount}</span>
        <Caret
          onClick={toggleProducts}
          className={cx(productsVisible ? 'caret--opened' : 'caret--closed', 'caret')}
          data-testid="leftSidebarCaret"
        />
      </div>
    </div>
  );
};

const ProjectName: React.FC = ({ children }) => (
  <div className="row">
    <span className="project__name">{children}</span>
  </div>
);

const Project: React.FC<ProjectProps> & CompoundComponents = ({ id, children, defaultOpen = true }) => {
  const history = useHistory();
  const [params, location] = useLocationParams();
  const [productsVisible, setProductsVisible] = useState(defaultOpen);
  const projectType = useSelector((state: AppState) => projectSelectors.projectType(id)(state));

  const handleProjectClick = useCallback(() => {
    history.push({
      pathname: projectType === 'packaging' ? `/projects/${id}` : `/project-photo/${id}`,
      search: params.toString(),
    });
  }, [history, id, params]);

  const toggleProducts = useCallback(() => {
    setProductsVisible((visible) => !visible);
  }, []);

  return (
    <ProjectContext.Provider value={{ productsVisible, toggleProducts, handleProjectClick }}>
      <div className="rows">{children}</div>
    </ProjectContext.Provider>
  );
};

Project.Slug = ProjectSlug;
Project.Name = ProjectName;
Project.ProductList = ProductsList;
Project.Product = ProductItem;

export default Project;
