import API, {PHOTO_PROJECTS_PREFIX} from './axiosConfig';
import { DesignStageResponse, DesignStageFileRequest, DesignStageFileResponse } from 'interfaces/API/draft';
import objectToFormDataConverter from 'helpers/objectToFormDataConverter';
import { AxiosResponse } from 'axios';

const getCallSheetByProjectId = async (projectId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.get<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/call-sheets/${projectId}/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const addCallSheetFile = async (
  projectId: number,
  data: DesignStageFileRequest
): Promise<DesignStageFileResponse | null> => {
  try {
    const formData = objectToFormDataConverter(data);
    const response = await API.post<DesignStageFileResponse>(`/${PHOTO_PROJECTS_PREFIX}/call-sheets/${projectId}/files/`, formData);
    return response.data;
  } catch (err) {
    return null;
  }
};

const acceptCallSheet = async (projectId: number): Promise<DesignStageResponse | null> => {
  try {
    const response = await API.patch<DesignStageResponse>(`/${PHOTO_PROJECTS_PREFIX}/call-sheets/${projectId}/accept/`, { accept: true });
    return response.data;
  } catch (err) {
    return null;
  }
};

const openCallSheet = async (projectId: number, productId: number, fileId: number) => {
  try {
    const response = await API.get<AxiosResponse>(`/${PHOTO_PROJECTS_PREFIX}/call-sheets/${projectId}/files/${fileId}/open-file/`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export default {
  getCallSheetByProjectId,
  addCallSheetFile,
  acceptCallSheet,
  openCallSheet,
};
