import * as yup from 'yup';

export const PhotoBriefSchema = yup.object().shape({
  name: yup.string(),
  description: yup.string(),
  isRequired: yup.bool(),
  photoType: yup.string(),
  photos: yup.array(),
});

export const PhotoBriefsSchema = yup.array().of(PhotoBriefSchema);
