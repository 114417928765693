
export function recursivelyDisplayObject(obj) {
    const results = []

    for (let [key, value] of Object.entries(obj)) {
        if (key === 'name') {
            key = 'Product name'
        } else if (key === 'modelNo') {
            key = 'Model number'
        } else if (key === 'batchNo') {
            key = 'LOT number'
        } else if (key === 'packagingType') {
            key = 'Packaging type'
        } else if (key === 'photos') {
            key = 'Product photo'
        } else if (key === 'blankings') {
            key = 'Die-cut'
        } else if (key === 'mainDescription') {
            key = 'Main description'
        } else if (key === 'extraDescription') {
                key = 'Additional description'
        } else if (key === 'sizes') {
                key = 'Sizes'
        } else if (key === 'certificate') {
                key = 'Certificates'
        } else if (key === 'additionalMarkings') {
                key = 'Additional markings'
        } else if (key === 'description') {
                key = 'Photo description'
        } else if (key === 'photoType') {
                key = 'Photo type'}

        if (typeof value === 'object') {
            const subresult = recursivelyDisplayObject(value)
         results.push(<>{key} {subresult}</>)
        }
        else {
            
        results.push(<span>{key}</span>)
    }
    }
    return results.map((result) => <li>{result}</li>)
}

